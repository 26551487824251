import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Layout from './../../wrapper'
import { ShowCoupon } from '../../Components/Coupon/'
import { logInUserInfo } from '../../Actions/Users'
import Notifications from 'react-notification-system-redux'
import { checkPrivileges, isOwnerOrDealer } from '../../Helpers'

class SystemLogs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: {},
      userFetch: false
    }
  }

  render () {
      if (this.props.logInUser ) {
        return (
          <Layout
            {...this.props}
            noSidebar
          >
            <ShowCoupon {...this.props} />
          </Layout>
        )
      } else {
        fetch('/api/session', {
          headers: { Accept: 'application/json; odata=verbose' }
        }).then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
                 this.props.history.push('/logout')
              }
            })
          } else {
            this.props.history.push('/logout')
              throw response
            }
          })
          .catch(e => {
            this.props.dispatch(Notifications.error({
              message: 'somethingWentWrong',
              autoDismiss: 5
            }))
          })
        return null
      }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withLocalize(SystemLogs))
