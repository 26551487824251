export const unitsList = [
  {
    device: 'TK103-2B',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'TK104',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'GPS-103',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'GPS-103-A',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'TW-MD1101',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'GPS102B',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'GPS104',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: '306A',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: '303F',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'TK-102B',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'GPS105B',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'A306',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'GPS303-G',
    protocol: 'gps103',
    port: 62300
  },
  {
    device: 'EC-546',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'TT0024',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T1024',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T1080',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T2024',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T2124',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T12',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T4400',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T8800',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T15400',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'TK05',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'TK10',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'TK15',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'TK20',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T18',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T18H',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'T16',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'P168',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'P169',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'TK206',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'TK207',
    protocol: 'tk103',
    port: 62301
  },
  {
    device: 'GL100',
    protocol: 'gl100',
    port: 62302
  },
  {
    device: 'GL100M',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GL200',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV300N',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GT200',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GT300',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GT500',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GL300',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GL3000W Series',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GL300VC',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GL300W Series',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GL500',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GL505',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GL520',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GT301',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GB100',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GMT100',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GMT200',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GS100',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV200',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV300',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV300VC',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV300W',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV500',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV500VC',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV50LTA',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV55',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV55 Lite',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV55VC',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV600W',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV65',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV65 Plus',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV75',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'GV75W',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'Prime AT Plus',
    protocol: 'gl200',
    port: 62303
  },
  {
    device: 'Gelix',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'Gelix-2',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'GPS-911(M)',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'AVL-900',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'AVL-900(R)',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'AVL-900(M)',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'AVL-901(B)',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'AVL-901(C)',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'AVL-901(D)',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'AVL-921',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'CradlePoint IBR600',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'Cradlepoint IBR1100',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'Aspicore',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'MultiConnect rCell',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'M2M IP Modem F7114',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'Datamax',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'MA100-1010',
    protocol: 't55',
    port: 62304
  },
  {
    device: 'TK101',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'TK102-2',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'TK103-2',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'XT009',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'XT011',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'TK201',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'TK201-2',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'TK202',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'TK203',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'XT107',
    protocol: 'xexun',
    port: 62305
  },
  {
    device: 'TZ-AVL02',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'TZ-AVL03',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'TZ-AVL05',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'TZ-AVL08',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'TZ-AVL09',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'TZ-AVL10',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'TZ-GT08',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'TZ-GT09',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'TZ-VN06',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'AT03',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'AT06',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'AT06+',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'AT07',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'AT09',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'TZ-AVL201',
    protocol: 'totem',
    port: 62306
  },
  {
    device: 'Mini MT',
    protocol: 'enfora',
    port: 62307
  },
  {
    device: 'Enfora GSM2448',
    protocol: 'enfora',
    port: 62307
  },
  {
    device: 'Enfora MT_4000',
    protocol: 'enfora',
    port: 62307
  },
  {
    device: 'GT30i',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GT60',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'VT300',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'VT310',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'VT400',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GT30',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GT30X',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'PST-AVL01',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'PT03',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'PT60',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'PT300X',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'PT30',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GT-110P',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GT-110K',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GT-110M',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GT-110ES',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GT-110ZS',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'AVL-011',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'VT900',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'P008',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GT 30',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'CT01',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'CT03',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'CT04',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'CT04-R',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'CT04-X',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'OCT600',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'MT01',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'MT02',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'PT01',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'PT03',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'VT1000',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'GSY007',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'T200',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'iStartek',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'VT310N',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'TK228',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'OST800',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'OCT900',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'OCT900-R',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'OCT800',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'OCT800-D',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'OCT600-CAM',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'OCT600-SG3',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'TZ-GT01',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'VT600',
    protocol: 'meiligao',
    port: 62308
  },
  {
    device: 'PG88',
    protocol: 'trv',
    port: 62309
  },
  {
    device: 'S88',
    protocol: 'trv',
    port: 62309
  },
  {
    device: 'PG22',
    protocol: 'trv',
    port: 62309
  },
  {
    device: 'S22',
    protocol: 'trv',
    port: 62309
  },
  {
    device: 'iStartek',
    protocol: 'trv',
    port: 62309
  },
  {
    device: 'VT206',
    protocol: 'trv',
    port: 62309
  },
  {
    device: 'PT33',
    protocol: 'trv',
    port: 62309
  },
  {
    device: 'ST200',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST210',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST215',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST215I',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST215E',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST240',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST230',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST900',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST910',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST-910',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'ST-940',
    protocol: 'suntech',
    port: 62310
  },
  {
    device: 'Progress 7s',
    protocol: 'progress',
    port: 62311
  },
  {
    device: 'H02',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'H-02A',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'H-02B',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'TX-2',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'H-06',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'H08',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'GTLT3',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'NT201',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'NT202',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'S31',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK109',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK106',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK208',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK206',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK310',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK206A',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK206B',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'MI-G6',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'CC830',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'CCTR',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'CCTR-630',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'AT-18',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'GRTQ',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK210',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'PT301',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'VT900',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'G91S',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK209C',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'G-T005',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'Incutex TK105',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'RF-V8S',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'CCRT809',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'AT-1',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK660',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'MT-1',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'CCTR-622G',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'Amparos S4',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK910',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK700',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'LK710',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'RF-16V',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'Cantrack-G05',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'Secumore-G05',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'Sinotrack ST-901',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'GTRACK4G',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'XE710',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'XE800',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'TK909',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'XE210',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'XE103',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'XE209A',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'XE209B',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'XE209C',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'XE109',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'XE208',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'GTR-100',
    protocol: 'h02',
    port: 62312
  },
  {
    device: 'JT600',
    protocol: 'jt600',
    port: 62313
  },
  {
    device: 'GP4000',
    protocol: 'jt600',
    port: 62313
  },
  {
    device: 'GP5000',
    protocol: 'jt600',
    port: 62313
  },
  {
    device: 'GP6000',
    protocol: 'jt600',
    port: 62313
  },
  {
    device: 'JT700A',
    protocol: 'jt600',
    port: 62313
  },
  {
    device: 'JT700B',
    protocol: 'jt600',
    port: 62313
  },
  {
    device: 'JT700C',
    protocol: 'jt600',
    port: 62313
  },
  {
    device: 'JT700',
    protocol: 'jt600',
    port: 62313
  },
  { device: 'JT701', protocol: 'jt600', port: 62313 },
  { device: 'HB-A5', protocol: 'huabao', port: 62314 },
  { device: 'G1C', protocol: 'huabao', port: 62314 },
  { device: 'JT705', protocol: 'huabao', port: 62314 },
  { device: 'ST-902W', protocol: 'huabao', port: 62314 },
  { device: 'CCTR 830-4G', protocol: 'huabao', port: 62314 },
  { device: 'CCTR-800G', protocol: 'huabao', port: 62314 },
  { device: 'ST-901 A+', protocol: 'huabao', port: 62314 },
  { device: 'G-420 Padlock', protocol: 'huabao', port: 62314 },
  { device: 'OBD2 V20', protocol: 'huabao', port: 62314 },
  { device: 'TA09', protocol: 'huabao', port: 62314 },
  { device: 'G-300', protocol: 'huabao', port: 62314 },
  { device: 'MicTrack B21', protocol: 'huabao', port: 62314 },
  { device: 'TK319', protocol: 'huabao', port: 62314 },
  { device: 'LT-162', protocol: 'huabao', port: 62314 },
  { device: 'DT-700', protocol: 'huabao', port: 62314 },
  {
    device: 'V680',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'P10',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'HC207',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'VT810',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'KS168M',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'HC06A',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'TL201',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'TK118',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'PT200',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'PT350',
    protocol: 'v680',
    port: 62315
  },
  {
    device: 'PT80',
    protocol: 'pt502',
    port: 62316
  },
  {
    device: 'PT100',
    protocol: 'pt502',
    port: 62316
  },
  {
    device: 'PT201',
    protocol: 'pt502',
    port: 62316
  },
  {
    device: 'PT502',
    protocol: 'pt502',
    port: 62316
  },
  {
    device: 'PT600',
    protocol: 'pt502',
    port: 62316
  },
  {
    device: 'PT510',
    protocol: 'pt502',
    port: 62316
  },
  {
    device: 'AnioSmart_A510',
    protocol: 'pt502',
    port: 62316
  },
  {
    device: 'TR-20',
    protocol: 'tr20',
    port: 62317
  },
  {
    device: 'Signal S-2115',
    protocol: 'navis',
    port: 62318
  },
  {
    device: 'Signal S-2117',
    protocol: 'navis',
    port: 62318
  },
  {
    device: 'CH-4713',
    protocol: 'navis',
    port: 62318
  },
  {
    device: 'CH-5703',
    protocol: 'navis',
    port: 62318
  },
  {
    device: 'MT80',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'MT88',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'MT80i',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'MT90',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'KT90',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'MVT100',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'MVT340',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'MVT380',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'MVT600',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'T1',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'MVT800',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'T3',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'TC68',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'TC68S',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'T322',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'T622',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'P99G',
    protocol: 'meitrack',
    port: 62319
  },
  {
    device: 'TT8750',
    protocol: 'skypatrol',
    port: 62320
  },
  {
    device: 'TT8750+',
    protocol: 'skypatrol',
    port: 62320
  },
  {
    device: 'TT9500',
    protocol: 'skypatrol',
    port: 62320
  },
  {
    device: 'TT9200',
    protocol: 'skypatrol',
    port: 62320
  },
  {
    device: 'TT8850',
    protocol: 'skypatrol',
    port: 62320
  },
  {
    device: 'GSM5108',
    protocol: 'skypatrol',
    port: 62320
  },
  {
    device: 'GT02',
    protocol: 'gt02',
    port: 62321
  },
  {
    device: 'TR02',
    protocol: 'gt02',
    port: 62321
  },
  {
    device: 'GT06',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GT06N',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GT09',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'Heacent 908',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GT03A',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GT03B',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GS503',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'ET100',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GT100',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GT06D',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GK301',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'JM01',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'JM08',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GT02D',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'IB-GT102',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'CRX1',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'JV200',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'TP06A',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'BW08',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'TR06',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'JI09',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'Concox GT300',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'WeTrack 2',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'GT230',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'OBD189',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'ZX612',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'S112U',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'Seeworld',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'Concox AT1',
    protocol: 'gt06',
    port: 62322
  },
  {
    device: 'Wanwaytech  G30',
    protocol: 'gt06',
    port: 62322
  },
  { device: 'G19', protocol: 'gt06', port: 62322 },
  { device: 'TW02B', protocol: 'gt06', port: 62322 },
  { device: 'EV02', protocol: 'gt06', port: 62322 },
  { device: 'G19H', protocol: 'gt06', port: 62322 },
  { device: 'G18', protocol: 'gt06', port: 62322 },
  { device: 'G19S', protocol: 'gt06', port: 62322 },
  { device: 'G17O', protocol: 'gt06', port: 62322 },
  { device: 'S20', protocol: 'gt06', port: 62322 },
  { device: 'G30', protocol: 'gt06', port: 62322 },
  { device: 'JM-VG01U', protocol: 'gt06', port: 62322 },
  { device: 'JM-VG02U', protocol: 'gt06', port: 62322 },
  {
    device: 'MT-90',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'MT-100',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GPT-69',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GT-89',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GT-99',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'XT-007',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GMT-368',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GVT-369',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GVT-390',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GVT-500',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GVT-510',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GMT-368SQ',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'XT7',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'GMT368s',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'MT60-X',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'MT-90x',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'MT-60X',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'MT300',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'MT110',
    protocol: 'megastek',
    port: 62323
  },
  {
    device: 'TD230',
    protocol: 'navigil',
    port: 62324
  },
  {
    device: 'uTrace03e',
    protocol: 'navigil',
    port: 62324
  },
  {
    device: 'FM1100',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM1010',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM1120',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM1202',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM2100',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM2200',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM3001',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM3200',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM3612',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM36M1',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM4100',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM4200',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM5300',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM6320',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'GH3000',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM3300',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'CT2000E',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM1204',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM3612',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM3622',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB900',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB920',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMT100',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB001',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB002',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB010',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB020',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB110',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB120',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB122',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB125',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMA110',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMA120',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB202',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB204',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMA202',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMA204',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB630',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB640',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB962',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB964',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'TMT250',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMC001',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMM001',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMC640',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMM640',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FM6300',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMU125',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMU126',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMU130',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB130',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB140',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'MSP500',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB003',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'MTB100',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMB208',
    protocol: 'teltonika',
    port: 7027
  },

  {
    device: 'Gruz',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'FMM125',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'FMM130',
    protocol: 'teltonika',
    port: 7027
  },
  {
    device: 'Personal',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'ZoomBox',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'MPU-01',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'MPU-01 GLONASS',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'MTA-02',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'MTA-02-GLONASS',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'MTA-02-CAM',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'MTA-03',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'MTA-12',
    protocol: 'mta6',
    port: 62327
  },
  {
    device: 'TZ-AVL19',
    protocol: 'tzone',
    port: 62328
  },
  {
    device: 'TLT-2F',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'V520',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-2H',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-1C',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'V690',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'VSUN3338',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-3A',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'V580',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-1B',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-2K',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-2N',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-1F',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-8A',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-8B',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-3A',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-1D',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-6C',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'TLT-7B',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'AT-12A',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MP80',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MT510',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MT500',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MT600',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MT510-G',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MT530',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MT550',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MP10',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MP90',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MT510G',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MC300',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'MP80',
    protocol: 'tlt2h',
    port: 62329
  },
  {
    device: 'Syrus GPS',
    protocol: 'taip',
    port: 62330
  },
  {
    device: 'E-Track',
    protocol: 'taip',
    port: 62330
  },
  {
    device: 'Sierra',
    protocol: 'taip',
    port: 62330
  },
  {
    device: 'Lantrix',
    protocol: 'taip',
    port: 62330
  },
  {
    device: 'Syrus SY2210',
    protocol: 'taip',
    port: 62330
  },
  {
    device: 'Lantrix T1700',
    protocol: 'taip',
    port: 62330
  },
  {
    device: 'WondeX VT300',
    protocol: 'wondex',
    port: 62331
  },
  {
    device: 'WondeX SPT-10',
    protocol: 'wondex',
    port: 62331
  },
  {
    device: 'TK5000',
    protocol: 'wondex',
    port: 62331
  },
  {
    device: 'Navixy M7',
    protocol: 'wondex',
    port: 62331
  },
  {
    device: 'TK5000XL',
    protocol: 'wondex',
    port: 62331
  },
  {
    device: 'CelloTrack 6M (IP65)',
    protocol: 'cellocator',
    port: 62332
  },
  {
    device: 'CelloTrack IP67',
    protocol: 'cellocator',
    port: 62332
  },
  {
    device: 'CelloTrack XT',
    protocol: 'cellocator',
    port: 62332
  },
  {
    device: 'GalileoSky',
    protocol: 'galileo',
    port: 7034
  },
  {
    device: 'V-MT001',
    protocol: 'ywt',
    port: 62334
  },
  {
    device: 'V208',
    protocol: 'ywt',
    port: 62334
  },
  {
    device: 'IntelliTrac X1 Plus',
    protocol: 'intellitrac',
    port: 62336
  },
  {
    device: 'IntelliTrac X8',
    protocol: 'intellitrac',
    port: 62336
  },
  {
    device: 'IntelliTrac P1',
    protocol: 'intellitrac',
    port: 62336
  },
  {
    device: 'GPSMTA',
    protocol: 'gpsmta',
    port: 62337
  },
  {
    device: 'Wialon IPS',
    protocol: 'wialon',
    port: 62338
  },
  {
    device: 'MasterKit',
    protocol: 'wialon',
    port: 62338
  },
  {
    device: 'MasterKit BM8009',
    protocol: 'wialon',
    port: 62338
  },
  {
    device: 'NeoTech TR_1000',
    protocol: 'wialon',
    port: 62338
  },
  {
    device: 'Eskort TD-Online',
    protocol: 'wialon',
    port: 62338
  },
  {
    device: 'CCTR-620',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'CCTR-622',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'CCTR-700',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'CCTR-800',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'CCTR-801',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'CCTR-802',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'CCTR-803',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'CCTR-808',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'CCTR-810',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'CCTR-620+',
    protocol: 'carscop',
    port: 62339
  },
  {
    device: 'T-104',
    protocol: 'apel',
    port: 62340
  },
  {
    device: 'T-104PRO',
    protocol: 'apel',
    port: 62340
  },
  {
    device: 'T-104 GLONASS',
    protocol: 'apel',
    port: 62340
  },
  {
    device: 'MP2030A',
    protocol: 'manpower',
    port: 62341
  },
  {
    device: 'MP2030B',
    protocol: 'manpower',
    port: 62341
  },
  {
    device: 'MP2031A',
    protocol: 'manpower',
    port: 62341
  },
  {
    device: 'MP2031B',
    protocol: 'manpower',
    port: 62341
  },
  {
    device: 'MP2031C',
    protocol: 'manpower',
    port: 62341
  },
  {
    device: 'TR-600',
    protocol: 'globalsat',
    port: 62342
  },
  {
    device: 'TR-600G',
    protocol: 'globalsat',
    port: 62342
  },
  {
    device: 'TR-606B',
    protocol: 'globalsat',
    port: 62342
  },
  {
    device: 'GTR-128/129',
    protocol: 'globalsat',
    port: 62342
  },
  {
    device: 'TR-206',
    protocol: 'globalsat',
    port: 62342
  },
  {
    device: 'TR-203',
    protocol: 'globalsat',
    port: 62342
  },
  {
    device: 'TR-151',
    protocol: 'globalsat',
    port: 62342
  },
  {
    device: 'TR-151SP',
    protocol: 'globalsat',
    port: 62342
  },
  {
    device: 'AT1',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AT1Pro',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AT5i',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AU5i',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AX5',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AY5i',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AT3',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AT5',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AU5',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AY5',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AT1',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'AX5C',
    protocol: 'atrack',
    port: 62343
  },
  {
    device: 'PT3000',
    protocol: 'pt3000',
    port: 62344
  },
  {
    device: 'FM-Pro3-R',
    protocol: 'ruptela',
    port: 62345
  },
  {
    device: 'FM-Tco3',
    protocol: 'ruptela',
    port: 62345
  },
  {
    device: 'FM-Pro3',
    protocol: 'ruptela',
    port: 62345
  },
  {
    device: 'FM-Eco3',
    protocol: 'ruptela',
    port: 62345
  },
  {
    device: 'Trailer Tracker',
    protocol: 'ruptela',
    port: 62345
  },
  {
    device: 'FM-ECO 4',
    protocol: 'ruptela',
    port: 62345
  },
  {
    device: 'T8803',
    protocol: 'topflytech',
    port: 62346
  },
  {
    device: 'T8801',
    protocol: 'topflytech',
    port: 62346
  },
  {
    device: 'T8901',
    protocol: 'topflytech',
    port: 62346
  },
  {
    device: 'StarFinder AIRE',
    protocol: 'laipac',
    port: 62347
  },
  {
    device: 'StarFinder Lite',
    protocol: 'laipac',
    port: 62347
  },
  {
    device: 'StarFinder Bus',
    protocol: 'laipac',
    port: 62347
  },
  {
    device: 'S911 Lola',
    protocol: 'laipac',
    port: 62347
  },
  {
    device: 'S911 Bracelet Locator',
    protocol: 'laipac',
    port: 62347
  },
  {
    device: 'S911 Bracelet Locator HC',
    protocol: 'laipac',
    port: 62347
  },
  {
    device: 'S911 Bracelet Locator ST',
    protocol: 'laipac',
    port: 62347
  },
  {
    device: 'S911 Personal Locator',
    protocol: 'laipac',
    port: 62347
  },
  {
    device: 'A9',
    protocol: 'aplicom',
    port: 62348
  },
  {
    device: 'A1 Max',
    protocol: 'aplicom',
    port: 62348
  },
  {
    device: 'A1 Trax',
    protocol: 'aplicom',
    port: 62348
  },
  {
    device: 'A1 M2M',
    protocol: 'aplicom',
    port: 62348
  },
  {
    device: 'A5 GLX',
    protocol: 'aplicom',
    port: 62348
  },
  {
    device: 'Aplicom C-series',
    protocol: 'aplicom',
    port: 62348
  },
  {
    device: 'Aplicom Q-series',
    protocol: 'aplicom',
    port: 62348
  },
  {
    device: 'Omega T600',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'TL007',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'TL201',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'TL206',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'TL218',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'VT108',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'VT1081',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'TP-20',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'EQT-20',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'G-TL-020',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'GP106M',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'Toplovo',
    protocol: 'gotop',
    port: 62349
  },
  {
    device: 'GC-101',
    protocol: 'sanav',
    port: 62350
  },
  {
    device: 'CT-24',
    protocol: 'sanav',
    port: 62350
  },
  {
    device: 'CT-58',
    protocol: 'sanav',
    port: 62350
  },
  {
    device: 'CT-58A',
    protocol: 'sanav',
    port: 62350
  },
  {
    device: 'GX-101',
    protocol: 'sanav',
    port: 62350
  },
  {
    device: 'GS-818',
    protocol: 'sanav',
    port: 62350
  },
  {
    device: 'MT-101',
    protocol: 'sanav',
    port: 62350
  },
  {
    device: 'MU-201',
    protocol: 'sanav',
    port: 62350
  },
  {
    device: 'QG-201',
    protocol: 'sanav',
    port: 62350
  },
  {
    device: 'M588S',
    protocol: 'gator',
    port: 62351
  },
  {
    device: 'M528',
    protocol: 'gator',
    port: 62351
  },
  {
    device: 'M508',
    protocol: 'gator',
    port: 62351
  },
  {
    device: 'M518',
    protocol: 'gator',
    port: 62351
  },
  {
    device: 'M588N',
    protocol: 'gator',
    port: 62351
  },
  {
    device: 'S208',
    protocol: 'gator',
    port: 62351
  },
  {
    device: 'S228',
    protocol: 'gator',
    port: 62351
  },
  {
    device: 'M518S',
    protocol: 'gator',
    port: 62351
  },
  {
    device: 'NR002',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'NR006',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'NR008',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'NR016',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'NR024',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'NR028',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'NR032',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'UT01',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'UM02',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'UT04',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'UT03',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'UT05',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'UT06',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'UP102',
    protocol: 'noran',
    port: 62352
  },
  {
    device: 'M2M-Mini',
    protocol: 'm2m',
    port: 62353
  },
  {
    device: 'OsmAnd',
    protocol: 'osmand',
    port: 7055
  },
  {
    device: 'SendLocation',
    protocol: 'osmand',
    port: 7055
  },
  {
    device: 'Locus Pro Android',
    protocol: 'osmand',
    port: 7055
  },
  {
    device: 'Custodium',
    protocol: 'osmand',
    port: 7055
  },
  {
    device: 'FX Tracker',
    protocol: 'osmand',
    port: 7055
  },
  {
    device: 'ET-01',
    protocol: 'easytrack',
    port: 62355
  },
  {
    device: 'ET-06',
    protocol: 'easytrack',
    port: 62355
  },
  {
    device: 'GPS Marker M130',
    protocol: 'gpsmarker',
    port: 62356
  },
  {
    device: 'GPS Marker M80',
    protocol: 'gpsmarker',
    port: 62356
  },
  {
    device: 'GPS Marker M70',
    protocol: 'gpsmarker',
    port: 62356
  },
  {
    device: 'GPS Marker M100',
    protocol: 'gpsmarker',
    port: 62356
  },
  {
    device: 'GPS Marker M60',
    protocol: 'gpsmarker',
    port: 62356
  },
  {
    device: 'KG100',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'KG200',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'KG300',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'KC200',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'T360-101A',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'T360-101P',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'T360-101E',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'T360-103',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'T360-106',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'T360-108',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'T360-269',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'T360-269B',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'T360-269JT',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'VT600',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'VT600X',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'VT800',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'AL900',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'VT900X',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'AL-900E',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'STG T100',
    protocol: 'khd',
    port: 62357
  },
  {
    device: 'Piligrim PL250',
    protocol: 'piligrim',
    port: 62358
  },
  {
    device: 'Piligrim 6000N',
    protocol: 'piligrim',
    port: 62358
  },
  {
    device: 'Piligrim Patrol',
    protocol: 'piligrim',
    port: 62358
  },
  {
    device: 'Piligrim Stealth',
    protocol: 'piligrim',
    port: 62358
  },
  {
    device: 'Piligrim Tracker-6000',
    protocol: 'piligrim',
    port: 62358
  },
  {
    device: 'STL060',
    protocol: 'stl060',
    port: 62359
  },
  {
    device: 'iTrackPro',
    protocol: 'cartrack',
    port: 62360
  },
  {
    device: 'MiniFinder Pico',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'EV_07',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'EV_07P',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'MiniFinder Atto',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'EV-601',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'EV-602',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'EV-603',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'EV-606',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'EV_07P',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'GPS668',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'SaR-mini',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'TE-207',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'TE200',
    protocol: 'minifinder',
    port: 62361
  },
  {
    device: 'HI-605X',
    protocol: 'haicom',
    port: 62362
  },
  {
    device: 'HI-604X',
    protocol: 'haicom',
    port: 62362
  },
  {
    device: 'HI-603X',
    protocol: 'haicom',
    port: 62362
  },
  {
    device: 'HI-602X',
    protocol: 'haicom',
    port: 62362
  },
  {
    device: 'HI-602',
    protocol: 'haicom',
    port: 62362
  },
  {
    device: 'HI-603',
    protocol: 'haicom',
    port: 62362
  },
  {
    device: 'HI-604',
    protocol: 'haicom',
    port: 62362
  },
  {
    device: 'TK115',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'TK116',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'GOT08',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'GOT10',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'GPT06',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'K6',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'K9',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'TK119',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'TK121',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'TK119-3G',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'TK119-T',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'GPT09',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'GPT12',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'GPT26',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'GPT06-3G',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'GPT18',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'K20',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'K30',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'GM06NW',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'WTR1-T',
    protocol: 'eelink',
    port: 62363
  },
  {
    device: 'Goome',
    protocol: 'eelink',
    port: 62363
  },
  { device: 'TK319-L', protocol: 'eelink', port: 62363 },
  { device: 'TK419-4G', protocol: 'eelink', port: 62363 },
  { device: 'GPT15', protocol: 'eelink', port: 62363 },
  { device: 'ET301', protocol: 'eelink', port: 62363 },
  {
    device: 'BOXoptions+',
    protocol: 'box',
    port: 62364
  },
  {
    device: 'BOXtracker',
    protocol: 'box',
    port: 62364
  },
  {
    device: 'BOXsolo',
    protocol: 'box',
    port: 62364
  },
  {
    device: 'BOX iSpot',
    protocol: 'box',
    port: 62364
  },
  {
    device: 'Freedom PT-9',
    protocol: 'freedom',
    port: 62365
  },
  {
    device: 'Freedom PT-10',
    protocol: 'freedom',
    port: 62365
  },
  {
    device: 'Telic SBC-AVL',
    protocol: 'telic',
    port: 62366
  },
  {
    device: 'Telic SBC3',
    protocol: 'telic',
    port: 62366
  },
  {
    device: 'SBC3',
    protocol: 'telic',
    port: 62366
  },
  {
    device: 'Picotrack',
    protocol: 'telic',
    port: 62366
  },
  {
    device: 'Picotrack IP69 K',
    protocol: 'telic',
    port: 62366
  },
  {
    device: 'Picotrack Endurance Primary',
    protocol: 'telic',
    port: 62366
  },
  {
    device: 'Picotrack Endurance Rechargeable',
    protocol: 'telic',
    port: 62366
  },
  {
    device: 'Trackbox',
    protocol: 'trackbox',
    port: 62367
  },
  {
    device: '84 VT',
    protocol: 'visiontek',
    port: 62368
  },
  {
    device: '86 VT',
    protocol: 'visiontek',
    port: 62368
  },
  {
    device: 'VT87',
    protocol: 'visiontek',
    port: 62368
  },
  {
    device: 'Orion ET-100',
    protocol: 'orion',
    port: 62369
  },
  {
    device: 'Orion OBDtrac',
    protocol: 'orion',
    port: 62369
  },
  {
    device: 'BD-2012',
    protocol: 'orion',
    port: 62369
  },
  {
    device: 'BD-3112',
    protocol: 'orion',
    port: 62369
  },
  {
    device: 'SLS-00886',
    protocol: 'riti',
    port: 62370
  },
  {
    device: 'SLS-012SF',
    protocol: 'riti',
    port: 62370
  },
  {
    device: 'TYN_886',
    protocol: 'riti',
    port: 62370
  },
  {
    device: 'T370',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T360',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T303',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T301',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T376',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T373B',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T373A',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T371',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T366',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T363B',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T363A',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T361',
    protocol: 'ulbotech',
    port: 62371
  },
  {
    device: 'T23',
    protocol: 'tramigo',
    port: 7073
  },
  {
    device: 'TR-900',
    protocol: 'tr900',
    port: 62373
  },
  {
    device: 'NEO1',
    protocol: 'tr900',
    port: 62373
  },
  {
    device: 'NEO2',
    protocol: 'tr900',
    port: 62373
  },
  {
    device: 'Ardi 01',
    protocol: 'ardi01',
    port: 62374
  },
  {
    device: 'XT013',
    protocol: 'xt013',
    port: 62375
  },
  {
    device: 'AutoFon D',
    protocol: 'autofon',
    port: 62376
  },
  {
    device: 'AutoFon SE',
    protocol: 'autofon',
    port: 62376
  },
  {
    device: 'AutoFon SE+',
    protocol: 'autofon',
    port: 62376
  },
  {
    device: 'StarLine M10',
    protocol: 'autofon',
    port: 62376
  },
  {
    device: 'StarLine M11',
    protocol: 'autofon',
    port: 62376
  },
  {
    device: 'StarLine M16',
    protocol: 'autofon',
    port: 62376
  },
  {
    device: 'StarLine M17',
    protocol: 'autofon',
    port: 62376
  },
  {
    device: 'G3A',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G3S',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G6S',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G1S',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G737',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G2P',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G717',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G777',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G91I',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G79',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G797',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'G797W',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'GS16',
    protocol: 'gosafe',
    port: 62377
  },
  {
    device: 'BCE FM Light',
    protocol: 'bce',
    port: 7080
  },
  {
    device: 'BCE FM Light+',
    protocol: 'bce',
    port: 7080
  },
  {
    device: 'BCE FM Blue',
    protocol: 'bce',
    port: 7080
  },
  {
    device: 'BCE FM Blue+',
    protocol: 'bce',
    port: 7080
  },
  {
    device: 'FM 500Q',
    protocol: 'bce',
    port: 7080
  },
  {
    device: 'FMS500 STCAN',
    protocol: 'bce',
    port: 7080
  },
  {
    device: 'XT-2000G',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-2060G',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-2050C',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-2150',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-2150G',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-2160G',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-2150C',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-3200',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-4500G',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-4560G',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-4550C',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-4700',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-4760',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-4750C',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-4860G',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-4850C',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-5000',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-5060',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-5050C',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-6200',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'XT-6260',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'ST7200',
    protocol: 'xirgo',
    port: 62380
  },
  {
    device: 'ATU-620',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-1100 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'TTU-700 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'TTU-1200 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'TTU-2820 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-200 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-300 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-328',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-400 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-700 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-800 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-900 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-1100 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-1200 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-2000 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-2100 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-2600 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-2620',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-2700 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-2720',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-3030',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-4200 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-4520 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'LMU-5000 Series',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'MDT-7',
    protocol: 'calamp',
    port: 62381
  },
  {
    device: 'MTX-Tunnel GPS',
    protocol: 'mtx',
    port: 62382
  },
  {
    device: 'DS520',
    protocol: 'tytan',
    port: 62383
  },
  {
    device: 'DS530',
    protocol: 'tytan',
    port: 62383
  },
  {
    device: 'DS540',
    protocol: 'tytan',
    port: 62383
  },
  {
    device: 'TZ-AVL301',
    protocol: 'avl301',
    port: 62384
  },
  {
    device: 'IDD-213G',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'IDD-212GL',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'IDD-212B',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'IDD-213T',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'IDD-213N/E',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'IDD-218G',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'HT-196R',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'HT-192',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'MPIP-618',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'MPIP-619',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'MPIP-620',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'PT-718',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'PT-690',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'SAT-802',
    protocol: 'castel',
    port: 62385
  },
  {
    device: 'MXT-142',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'MTC-700',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'MTC-780',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'MXT-140',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'MXT-141',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'IDP-780',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'MXT-100',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'MXT-101',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'MX-100',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'TD-50',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'WT-110',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'TD-60',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'G-100',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'i-MXT',
    protocol: 'mxt',
    port: 62386
  },
  {
    device: 'Cityeasy 520',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 009',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 006',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 007',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 202',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 306',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 100',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 200',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 302',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 008',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'Cityeasy 201',
    protocol: 'cityeasy',
    port: 62387
  },
  {
    device: 'S101+',
    protocol: 'aquila',
    port: 62388
  },
  {
    device: 'S101',
    protocol: 'aquila',
    port: 62388
  },
  {
    device: 'U101V1+',
    protocol: 'aquila',
    port: 62388
  },
  {
    device: 'U101',
    protocol: 'aquila',
    port: 62388
  },
  {
    device: 'U101v1',
    protocol: 'aquila',
    port: 62388
  },
  {
    device: 'MeSafe',
    protocol: 'aquila',
    port: 62388
  },
  {
    device: 'Stay Safe',
    protocol: 'aquila',
    port: 62388
  },
  {
    device: 'Lommy Pro',
    protocol: 'flextrack',
    port: 62389
  },
  {
    device: 'Lommy Personal',
    protocol: 'flextrack',
    port: 62389
  },
  {
    device: 'Lommy Eye',
    protocol: 'flextrack',
    port: 62389
  },
  {
    device: 'BlackKite Fleet',
    protocol: 'blackkite',
    port: 62390
  },
  {
    device: 'BK-FLT-P1',
    protocol: 'blackkite',
    port: 62390
  },
  {
    device: 'ADM100',
    protocol: 'adm',
    port: 62391
  },
  {
    device: 'ADM101',
    protocol: 'adm',
    port: 62391
  },
  {
    device: 'ADM300',
    protocol: 'adm',
    port: 62391
  },
  {
    device: 'ADM700',
    protocol: 'adm',
    port: 62391
  },
  {
    device: 'ADM700 3G',
    protocol: 'adm',
    port: 62391
  },
  {
    device: 'ADM703 3G',
    protocol: 'adm',
    port: 62391
  },
  {
    device: 'ADM710',
    protocol: 'adm',
    port: 62391
  },
  {
    device: 'ADM007',
    protocol: 'adm',
    port: 62391
  },
  {
    device: 'ADM333',
    protocol: 'adm',
    port: 62391
  },
  {
    device: 'JM09',
    protocol: 'watch',
    port: 62392
  },
  {
    device: 'TK STAR STICK',
    protocol: 'watch',
    port: 62392
  },
  {
    device: 'A9 GPS tracker',
    protocol: 'watch',
    port: 62392
  },
  {
    device: 'T8S',
    protocol: 'watch',
    port: 62392
  },
  {
    device: 'Astro 120',
    protocol: 'watch',
    port: 62392
  },
  {
    device: 'RomboGPS',
    protocol: 'watch',
    port: 62392
  },
  {
    device: 'TK-905',
    protocol: 'watch',
    port: 62392
  },
  {
    device: 'TK915',
    protocol: 'watch',
    port: 62392
  },
  {
    device: 'T8808',
    protocol: 't800x',
    port: 62393
  },
  {
    device: 'T8803 PRO',
    protocol: 't800x',
    port: 62393
  },
  {
    device: 'T8806',
    protocol: 't800x',
    port: 62393
  },
  {
    device: 'T8805',
    protocol: 't800x',
    port: 62393
  },
  {
    device: 'T8603',
    protocol: 't800x',
    port: 62393
  },
  {
    device: 'T800X',
    protocol: 't800x',
    port: 62393
  },
  {
    device: 'T880X',
    protocol: 't800x',
    port: 62393
  },
  {
    device: 'U-Pro mini',
    protocol: 'upro',
    port: 62394
  },
  {
    device: 'AURO Comfort 1060',
    protocol: 'auro',
    port: 62395
  },
  {
    device: 'Disha 9310',
    protocol: 'disha',
    port: 62396
  },
  {
    device: 'Disha 9320',
    protocol: 'disha',
    port: 62396
  },
  {
    device: 'Disha 9330',
    protocol: 'disha',
    port: 62396
  },
  {
    device: 'TD 300',
    protocol: 'disha',
    port: 62396
  },
  {
    device: 'VT100',
    protocol: 'thinkrace',
    port: 62397
  },
  {
    device: 'PathAway',
    protocol: 'pathaway',
    port: 62398
  },
  {
    device: 'ARNAVI',
    protocol: 'arnavi',
    port: 62399
  },
  {
    device: 'СН-5707',
    protocol: 'nvs',
    port: 62400
  },
  {
    device: 'NVS-RTK-M',
    protocol: 'nvs',
    port: 62400
  },
  {
    device: 'Kenji KJ-8501',
    protocol: 'kenji',
    port: 62401
  },
  {
    device: 'AT200',
    protocol: 'astra',
    port: 62402
  },
  {
    device: 'Homtecs H20',
    protocol: 'homtecs',
    port: 62403
  },
  {
    device: 'Fox Easy AVL',
    protocol: 'fox',
    port: 62404
  },
  {
    device: 'Fox Lite AVL',
    protocol: 'fox',
    port: 62404
  },
  {
    device: 'Fox Advanced AVL',
    protocol: 'fox',
    port: 62404
  },
  {
    device: 'GNX-2',
    protocol: 'gnx',
    port: 62405
  },
  {
    device: 'GNX-3',
    protocol: 'gnx',
    port: 62405
  },
  {
    device: 'RX-9',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'RX-8W',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'RV-8',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'R-9PRO',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'R-9W',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'IR-7',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'CT-X8',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'AT-5000',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'AT-04',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'AT-9000',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'DX-3',
    protocol: 'arknav',
    port: 62406
  },
  {
    device: 'Supermate D-series',
    protocol: 'supermate',
    port: 62407
  },
  {
    device: 'D11',
    protocol: 'supermate',
    port: 62407
  },
  {
    device: 'Appello 4P',
    protocol: 'appello',
    port: 62408
  },
  {
    device: 'IDPL',
    protocol: 'idpl',
    port: 62409
  },
  {
    device: 'HS-500P',
    protocol: 'huasheng',
    port: 62410
  },
  {
    device: 'HS-300V',
    protocol: 'huasheng',
    port: 62410
  },
  {
    device: 'HS-400',
    protocol: 'huasheng',
    port: 62410
  },
  {
    device: 'HS-1200',
    protocol: 'huasheng',
    port: 62410
  },
  {
    device: 'HS-3000G',
    protocol: 'huasheng',
    port: 62410
  },
  {
    device: 'HS-800W',
    protocol: 'huasheng',
    port: 62410
  },
  {
    device: 'HS-2000C',
    protocol: 'huasheng',
    port: 62410
  },
  {
    device: 'HS-2000G',
    protocol: 'huasheng',
    port: 62410
  },
  {
    device: 'L100',
    protocol: 'granit',
    port: 62412
  },
  {
    device: 'Granit Navigator 4',
    protocol: 'granit',
    port: 62412
  },
  {
    device: 'Granit Navigator 5',
    protocol: 'granit',
    port: 62412
  },
  {
    device: 'CR2000',
    protocol: 'carcell',
    port: 62413
  },
  {
    device: 'CR250',
    protocol: 'carcell',
    port: 62413
  },
  {
    device: 'RV-M7',
    protocol: 'raveon',
    port: 62416
  },
  {
    device: 'CradlePoint',
    protocol: 'cradlepoint',
    port: 62417
  },
  {
    device: 'Arknav CT-X8',
    protocol: 'arknavx8',
    port: 62418
  },
  {
    device: 'AutoGrade',
    protocol: 'autograde',
    port: 62419
  },
  {
    device: 'AR-2GM',
    protocol: 'oigo',
    port: 62420
  },
  {
    device: 'AR-3HU',
    protocol: 'oigo',
    port: 62420
  },
  {
    device: 'AR-2CX',
    protocol: 'oigo',
    port: 62420
  },
  {
    device: 'JP-KORJAR',
    protocol: 'jpkorjar',
    port: 62421
  },
  {
    device: 'cGuard Personal',
    protocol: 'cguard',
    port: 62422
  },
  {
    device: 'cGuard Atom',
    protocol: 'cguard',
    port: 62422
  },
  {
    device: 'cGuard OBD',
    protocol: 'cguard',
    port: 62422
  },
  {
    device: 'cGuard Litom',
    protocol: 'cguard',
    port: 62422
  },
  {
    device: 'cGuard Beacon',
    protocol: 'cguard',
    port: 62422
  },
  {
    device: 'Fifotrack',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'A100',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'A200',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'A300',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'Q1',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'S20',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'S30',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'S50',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'S60',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'A600',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'A700',
    protocol: 'fifotrack',
    port: 62423
  },
  {
    device: 'ET800E',
    protocol: 'extremtrac',
    port: 62425
  },
  {
    device: 'TrakMate',
    protocol: 'trakmate',
    port: 62426
  },
  {
    device: 'AT2000',
    protocol: 'at2000',
    port: 62427
  },
  {
    device: 'Maestro MT-01',
    protocol: 'maestro',
    port: 62428
  },
  {
    device: 'AIVDM/AIVDO AIS',
    protocol: 'ais',
    port: 62429
  },
  {
    device: 'GT-30',
    protocol: 'gt30',
    port: 62430
  },
  {
    device: 'TrackMiGaddi',
    protocol: 'tmg',
    port: 62431
  },
  {
    device: 'TMG188',
    protocol: 'tmg',
    port: 62431
  },
  {
    device: 'TC85D',
    protocol: 'pretrace',
    port: 62432
  },
  {
    device: 'TC56',
    protocol: 'pretrace',
    port: 62432
  },
  {
    device: 'TC55',
    protocol: 'pretrace',
    port: 62432
  },
  {
    device: 'TC80',
    protocol: 'pretrace',
    port: 62432
  },
  {
    device: 'TC85',
    protocol: 'pretrace',
    port: 62432
  },
  {
    device: 'Pricol',
    protocol: 'pricol',
    port: 62433
  },
  {
    device: 'SIWI',
    protocol: 'siwi',
    port: 62434
  },
  {
    device: 'StarLink Asset',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink Trailer',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'Starlink TrackerBT',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink Tracker',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink TrackerSF',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink TrackerCAN',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink SVR',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink ToGo',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink Voice',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink eConnect',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink eBike',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'StarLink RoadBuddy',
    protocol: 'starlink',
    port: 62435
  },
  {
    device: 'G100',
    protocol: 'dmt',
    port: 62436
  },
  {
    device: 'G60',
    protocol: 'dmt',
    port: 62436
  },
  {
    device: 'G52S',
    protocol: 'dmt',
    port: 62436
  },
  {
    device: 'DART',
    protocol: 'dmt',
    port: 62436
  },
  {
    device: 'OYSTER',
    protocol: 'dmt',
    port: 62436
  },
  {
    device: 'Sting',
    protocol: 'dmt',
    port: 62436
  },
  {
    device: 'Xirgo XT2400 Series',
    protocol: 'xt2400',
    port: 62437
  },
  {
    device: 'Xirgo XT6300 Series',
    protocol: 'xt2400',
    port: 62437
  },
  {
    device: 'REMORA',
    protocol: 'dmthttp',
    port: 62438
  },
  {
    device: 'AE1',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'AE1-W',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'AM1-S',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'AM1-W',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'AM1-S(V)',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'AM1-W(V)',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'AM3-S',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'AM3-W',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'AM3-S(V)',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'AM3-W(V)',
    protocol: 'alematics',
    port: 62439
  },
  {
    device: 'GPS056',
    protocol: 'gps056',
    port: 62440
  },
  {
    device: 'FT-3150',
    protocol: 'flexcomm',
    port: 62441
  },
  {
    device: 'VT200',
    protocol: 'vt200',
    port: 62442
  },
  {
    device: 'VT400',
    protocol: 'vt200',
    port: 62442
  },
  {
    device: 'OwnTracks',
    protocol: 'owntracks',
    port: 62443
  },
  {
    device: 'VTFMS',
    protocol: 'vtfms',
    port: 62444
  },
  {
    device: 'TLV',
    protocol: 'tlv',
    port: 62445
  },
  {
    device: 'ES610',
    protocol: 'esky',
    port: 62446
  },
  {
    device: 'GNX-20',
    protocol: 'genx',
    port: 62447
  },
  {
    device: 'GNX-6',
    protocol: 'genx',
    port: 62447
  },
  {
    device: 'GNX-5P',
    protocol: 'genx',
    port: 62447
  },
  {
    device: 'GNX-3',
    protocol: 'genx',
    port: 62447
  },
  {
    device: 'GNX-10L',
    protocol: 'genx',
    port: 62447
  },
  {
    device: 'GNX-10',
    protocol: 'genx',
    port: 62447
  },
  {
    device: 'Flespi',
    protocol: 'flespi',
    port: 62448
  },
  {
    device: 'D-WAY',
    protocol: 'dway',
    port: 62449
  },
  {
    device: 'Recoda M610',
    protocol: 'recoda',
    port: 62450
  },
  {
    device: 'OKO-S2',
    protocol: 'oko',
    port: 62451
  },
  {
    device: 'OKO-E',
    protocol: 'oko',
    port: 62451
  },
  {
    device: 'OKO-NAVI',
    protocol: 'oko',
    port: 62451
  },
  {
    device: 'IVT401',
    protocol: 'ivt401',
    port: 62452
  },
  {
    device: 'TL500',
    protocol: 'ivt401',
    port: 62452
  },
  {
    device: 'TL1000',
    protocol: 'ivt401',
    port: 62452
  },
  {
    device: 'AUTOCOP',
    protocol: 'ivt401',
    port: 62452
  },
  {
    device: 'TL3000',
    protocol: 'ivt401',
    port: 62452
  },
  {
    device: 'TL500',
    protocol: 'ivt401',
    port: 62452
  },
  {
    device: 'Oyster Sigfox',
    protocol: 'sigfox',
    port: 62453
  },
  {
    device: 'T57',
    protocol: 't57',
    port: 62454
  },
  {
    device: 'SPOT',
    protocol: 'spot',
    port: 62455
  },
  {
    device: 'SPOT GEN3',
    protocol: 'spot',
    port: 62455
  },
  {
    device: 'M2C2020',
    protocol: 'm2c',
    port: 62456
  },
  {
    device: 'OpenGTS',
    protocol: 'opengts',
    port: 62458
  },
  {
    device: 'GPSLogger',
    protocol: 'opengts',
    port: 62458
  },
  {
    device: 'Cautela',
    protocol: 'cautela',
    port: 62459
  },
  {
    device: 'Motorola HF',
    protocol: 'cautela',
    port: 62459
  },
  {
    device: 'Continental Vehicle Tracker',
    protocol: 'continental',
    port: 62460
  },
  {
    device: 'EGTS (Era-Glonass)',
    protocol: 'egts',
    port: 62461
  },
  {
    device: 'ROBOT-UPS',
    protocol: 'robotrack',
    port: 62462
  },
  {
    device: 'ROBOT-GPS',
    protocol: 'robotrack',
    port: 62462
  },
  {
    device: 'ROBOTRACK',
    protocol: 'robotrack',
    port: 62462
  },
  {
    device: 'PT60',
    protocol: 'pt60',
    port: 62463
  },
  {
    device: 'Telemax',
    protocol: 'telemax',
    port: 62464
  },
  {
    device: 'Micro Genie',
    protocol: 'sabertek',
    port: 62465
  },
  {
    device: 'Wialon Retranslator',
    protocol: 'retranslator',
    port: 62466
  },
  {
    device: 'Vias700',
    protocol: 'svias',
    port: 62467
  },
  {
    device: 'eSeal',
    protocol: 'eseal',
    port: 62468
  },
  {
    device: 'eSeal',
    protocol: 'eseal',
    port: 62468
  },
  {
    device: 'Freematics',
    protocol: 'freematics',
    port: 62469
  },
  {
    device: 'AT35',
    protocol: 'avema',
    port: 62470
  },
  {
    device: 'AT35 3G',
    protocol: 'avema',
    port: 62470
  },
  {
    device: 'AT35 LTE',
    protocol: 'avema',
    port: 62470
  },
  {
    device: 'AT35 4G/LTE MiFi',
    protocol: 'avema',
    port: 62470
  },
  {
    device: 'MT99',
    protocol: 'avema',
    port: 62470
  },
  {
    device: 'MT99 4G LTE',
    protocol: 'avema',
    port: 62470
  },
  {
    device: 'AutoTrack XL',
    protocol: 'autotrack',
    port: 62471
  },
  {
    device: 'AutoTrack еLite',
    protocol: 'autotrack',
    port: 62471
  },
  {
    device: 'TEK-586',
    protocol: 'tek',
    port: 62472
  },
  {
    device: 'TEK-733',
    protocol: 'tek',
    port: 62472
  },
  {
    device: 'Wrist Band',
    protocol: 'wristband',
    port: 62473
  },
  {
    device: 'GB101',
    protocol: 'milesmate',
    port: 62475
  },
  {
    device: 'MM101A',
    protocol: 'milesmate',
    port: 62475
  },
  {
    device: 'VT1611',
    protocol: 'anytrek',
    port: 62476
  },
  {
    device: 'SmartSole',
    protocol: 'smartsole',
    port: 62477
  },
  {
    device: 'ITS',
    protocol: 'its',
    port: 62478
  },
  {
    device: 'AIS140',
    protocol: 'its',
    port: 62478
  },
  {
    device: 'XRB 28',
    protocol: 'xrb28',
    port: 62479
  },
  {
    device: 'C2STEK',
    protocol: 'c2stek',
    port: 62480
  },
  {
    device: 'MT-20',
    protocol: 'c2stek',
    port: 62480
  },
  {
    device: 'NT-183W',
    protocol: 'nyitech',
    port: 62481
  },
  {
    device: 'NT-183G',
    protocol: 'nyitech',
    port: 62481
  },
  {
    device: 'GEO5',
    protocol: 'neos',
    port: 62482
  },
  {
    device: 'NEO5',
    protocol: 'neos',
    port: 62482
  },
  {
    device: 'NEO7',
    protocol: 'neos',
    port: 62482
  },
  {
    device: 'GEO1',
    protocol: 'neos',
    port: 62482
  },
  {
    device: 'NEO1',
    protocol: 'neos',
    port: 62482
  },
  {
    device: 'NEO2',
    protocol: 'neos',
    port: 62482
  },
  {
    device: 'SAT-LITE 4',
    protocol: 'satsol',
    port: 62483
  },
  {
    device: 'SAT-LITE 3',
    protocol: 'satsol',
    port: 62483
  },
  {
    device: 'Globalstar',
    protocol: 'globalstar',
    port: 62484
  },
  {
    device: 'SANUL',
    protocol: 'sanul',
    port: 62485
  },
  {
    device: 'MiniFinder Atto Pro',
    protocol: 'minifinder2',
    port: 62486
  },
  {
    device: 'PEBBELL',
    protocol: 'minifinder2',
    port: 62486
  },
  {
    device: 'Radar Duo',
    protocol: 'radar',
    port: 62487
  },
  {
    device: 'Radar Trio',
    protocol: 'radar',
    port: 62487
  },
  {
    device: 'GPSRX12',
    protocol: 'techtlt',
    port: 62488
  },
  {
    device: 'GPSRX14',
    protocol: 'techtlt',
    port: 62488
  },
  {
    device: 'GPSRX16',
    protocol: 'techtlt',
    port: 62488
  },
  {
    device: 'Helios',
    protocol: 'starcom',
    port: 62489
  },
  {
    device: 'Helios TT',
    protocol: 'starcom',
    port: 62489
  },
  {
    device: 'Helios Advanced',
    protocol: 'starcom',
    port: 62489
  },
  {
    device: 'Helios Hybrid',
    protocol: 'starcom',
    port: 62489
  },
  {
    device: 'RST MINI LOW COST',
    protocol: 'rst',
    port: 62490
  },
  { device: 'RST MINI', protocol: 'rst', port: 62490 },
  {
    device: 'RST LOW COST',
    protocol: 'rst',
    port: 62490
  },
  {
    device: 'RST VERSAO TELEMETRIA',
    protocol: 'rst',
    port: 62490
  },
  {
    device: 'RST MINI 4G',
    protocol: 'rst',
    port: 62490
  },
  {
    device: 'RST HIBRIDO',
    protocol: 'rst',
    port: 62490
  },
  {
    device: 'RST OBD2',
    protocol: 'rst',
    port: 62490
  },
  {
    device: 'RST MOVEL',
    protocol: 'rst',
    port: 62490
  }
]
