export const reports = (state = [], action) => {
  switch (action.type) {

  case 'GET_STATISTICS':
  let repData= action.statistics
  action.statistics.map((item, index) => {
    repData[index].captureTime= item.captureTime ? item.captureTime.substring(0, 10): ''
  })
		return repData

    default:
      return state
  }
}