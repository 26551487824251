import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import Loader from './Loader'
import { isOwnerOrDealer, setCookie } from '../Helpers'
import CommonSection from '../Helpers/commonSection'
import NotificationSystem from './NotificationSystem'
import { checkPrivileges } from '../Helpers'
import Notifications from 'react-notification-system-redux'

import './index.scss'

export function IsAuthenticated (props) {
  const info = JSON.parse(localStorage.getItem('userInfo'))
  if (info && info.id) {
    return true
  } else {
    if (props && props.history) {
      props.history.push('/logout')
    }
  }
  return false
}

class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      collapse_sidebar: ''
    }

    this.collapseSidebar = this.collapseSidebar.bind(this)
  }
  collapseSidebar (value) {
    this.setState({ collapse_sidebar: value || '' })
  }

  handleBackToAdmin = () => {
    //
    fetch('/api/session', {
      method: 'DELETE'
    }).then(response => {
      response.json().then(res => {
        if(res.status ==='success'){  
          localStorage.setItem('token', '')
          setCookie('JSESSIONID', 0)
          localStorage.setItem('userInfo', '')
          localStorage.setItem('domainToken', '')
          this.props.history.push('/login')
        }
        else if(res?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(res.statusCode){
          var err = res?.message.split(':')
          err[1] =err[1].replace(')', "")
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate(err[1]),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        }
      })
    })
    .catch(e => {
      this.props.dispatch(Notifications.error({
        message: 'somethingWentWrong',
        autoDismiss: 5
      }))
    })
  }

  render () {
    if (this.props.logInUser.id && IsAuthenticated(this.props)) {
      return (
        <div className={this.state.collapse_sidebar + (this.props.sidebarToggle ? ' side-menu-open ' : ' ')}>
          
          <CommonSection {...this.props} />
          <NotificationSystem />
          <Header {...this.props} userInfo={this.props.logInUser} handleDrawer={this.props.handleDrawer}/>
          
          {this.props.logInUser &&
            checkPrivileges('rptmpl') &&
            (this.props.location.pathname.indexOf('/roleManagement') !== -1 ||
              this.props.location.pathname.indexOf('/templateGenerator') !==-1||
              this.props.location.pathname.indexOf('/billings') !==-1
              ) 
              
              ?  <Sidebar {...this.props} sidebarClose={this.collapseSidebar} /> : null}

          <div
            className={
              ' main-view no-sidebar ' +
              (this.props.sidebarToggle ? ' side-menu-open ' : '') +
              this.props.classFromChildren
            }
          >
            {(localStorage.getItem('adminToken') || localStorage.getItem('domainToken')) ? 
            (<>
              <div className='demo-notification-bar notification-info'>
                <span>
                  {this.props.translate('youAreTemporarilyLoggedInAsAnotherUser')}
                </span>
                <a style={{cursor:'pointer'}}href={null} onClick={this.handleBackToAdmin}>
                  {this.props.translate('switch')}
                </a>
                <span>{this.props.translate('backToYourAccount')}</span>
              </div>
              </>) 
              : ''
            }
            <div className="has-padding">
              {this.props.children}
            </div>
          </div>
            
          
          <Footer {...this.props} />
        </div>
      )
    } else {
      return <Loader />
    }
  }
}

const mapState = state => ({
  logInUser: state.logInUsers,
  deviceLimit: state.deviceLimit
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(Layout)
