import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import isEqual from 'react-fast-compare'
import AddAccountModal from './addAccount'
import { addAccount, removeAccount, getAccounts } from '../../Actions/Accounts'
import { parentUsersLimit } from '../../Actions/Users'
import { parentUnitsLimit } from '../../Actions/Devices'
import { validEmailPattern } from '../../Helpers'
import { checkPrivileges, PaginationConfig } from '../../Helpers'
import { checkUnitsWithStandardType } from '../Users/userDataTypes'
import Table from '../common/tableWithBackEndPagination'
import ConfirmDialoag from '../common/ConfirmDialoag'
import TextField from '../common/TextField'
import { ViewStatistics } from './../Users/statistics'
import { ViewAccountDetail } from './accountDetail/viewAccountDetail'
import Notifications from 'react-notification-system-redux'
import parseHtml from 'react-html-parser'
import ExportFile from './../common/ExportFile'
import Button from './../common/Button'
import { Grid, Tooltip } from '@material-ui/core'

const userDataFormat = data => {
  if(data.roleId === 'defaultRole'){
    data.roleId=''
  }
  return {
    administrator: true,
    attributes: data.attributes || {},
    coordinateFormat: data.coordinateFormat ? data.coordinateFormat : '',
    deviceLimit: data.deviceLimit,
    deviceReadonly: data.deviceReadonly || false,
    disabled: data.disabled || false,
    email: data.email.trim(),
    expirationTime: data.expirationTime ? data.expirationTime : null,
    id: data.id || 0,
    latitude: data.latitude ? data.latitude : 0,
    limitCommands: data.limitCommands || false,
    login: data.login,
    longitude: data.longitude ? data.longitude : 0,
    map: data.map,
    name: data.name.trim(),
    password: data.password,
    phone: data.phone,
    poiLayer: data.poiLayer,
    readonly: data.readonly || false,
    token: data.token,
    twelveHourFormat: data.twelveHourFormat || false,
    userLimit: data.userLimit,
    zoom: data.zoom ? data.zoom : 0,
    roleId: data.roleId ? data.roleId :'',
    userType: data.userType,
    parentId: data.parentId || 0
  }
}
const attributesDefaultValue = {
  distanceUnit: 'km',
  speedUnit: 'kmh',
  volumeUnit: 'ltr',
  measurementStandard: 'metric'
}
const formDefaultState = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  map: '',
  latitude: '',
  longitude: '',
  zoom: '',
  twelveHourFormat: '',
  coordinateFormat: '',
  poiLayer: '',
  disabled: '',
  admin: '',
  readonly: '',
  deviceReadonly: '',
  limitCommands: '',
  deviceLimit: 0,
  userLimit: 0,
  token: '',
  expirationTime: null,
  roleId: '',
  userType: '',
  administrator: false,
  attributes: {},
  parentId: 0
}
let source
class accountModal extends Component {
  constructor () {
    super()
    this.state = {
      ...PaginationConfig,
      // all fields of form
      isVisableBtn: false,
      isAttChanged: false,
      itemAttributes: {},
      accountDefaultList: true,
      addAccount: false,
      selectedAccount: '',
      onDeleteConfirmation: false,
      accountDetailOption: false,
      emailPattern: false,
      spEmailPattern: false,
      loader: false,
      topRow:true,
      form: {
        name: '',
        email: '',
        contact: '',
        description: '',
        attributes: {},
      },
      spForm: {
        ...formDefaultState
      },
      accountType: [
        { id: '1', label: 'Acc Type1' },
        { id: '2', label: 'Acc Type2' },
        { id: '3', label: 'Acc Type3' },
        { id: '4', label: 'Acc Type4' },
        { id: '5', label: 'Acc Type5' }
      ],
      accountStatus: [
        { id: '1', label: 'Onlline' },
        { id: '2', label: 'offline' },
        { id: '3', label: 'blocked' },
        { id: '4', label: 'dormant' }
      ],
      accountTime: [
        { id: '1', label: 'Created time' },
        { id: '2', label: 'Deleted time' },
        { id: '3', label: 'Active time' }
      ],
    }

    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.changeAttribute = this.changeAttribute.bind(this)
    this.checkForAttrubutesChanged = this.checkForAttrubutesChanged.bind(this)
    this.generateToken = this.generateToken.bind(this)
    this.timeDateOnchange = this.timeDateOnchange.bind(this)
    this.submit = this.submit.bind(this)
    this.addNewAccount = this.addNewAccount.bind(this)
  }
  componentWillMount () {
    this.getMoreAccounts()
  }
  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
    this.props.dispatch(Notifications.removeAll())
  }
  getMoreAccounts = () => {
    let {
      page,
      pageSize,
      itemSearch,
      accountTypes,
      accountStatuses,
      accountTimes
    } = this.state
    let apiUrl = `/api/accounts/get?userId=${this.props.logInUser.id}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`
    if (this.props.logInUser.userType === -1) {
      apiUrl = `/api/accounts/get?userId=${
        this.props.logInUser.id
      }&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}${accountTypes ||
        ''}${accountStatuses || ''}${accountTimes || ''}`
    }
    source = axios.CancelToken.source()
    // fetch(apiUrl, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    axios
      .get(apiUrl, {
        cancelToken: source.token
      })
      .then(response => {
        if (response.status === 200) {
          this.props.dispatch(getAccounts(response.data.data))
        }
      })
      .catch(e => {
        // if (axios.isCancel(e)) {
        //   console.log('Request canceled = ', e);
        // }
      })
  }

  generateToken () {
    let symbols =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i,
      newToken = ''
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }
    this.setState(
      {
        spForm: {
          ...this.state.spForm,
          token: newToken
        }
      },
      () => this.checkRequiredFields()
    )
  }
  timeDateOnchange (date) {
    this.setState(preState => ({
      ...preState,
      spForm: {
        ...preState.spForm,
        timeDate: date
      }
    }))
  }
  setNoToken = e => {
    e.stopPropagation()
    this.setState(
      {
        spForm: {
          ...this.state.spForm,
          token: null
        }
      },
      () => this.checkRequiredFields()
    )
  }
  setExpirationTime = e => {
    e.stopPropagation()
    if (this.state.spForm.expirationTime) {
      this.setState(
        {
          spForm: {
            ...this.state.spForm,
            expirationTime: null
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  changeAttribute (name, value, selectedOption, updateAtt) {
    if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
      this.setState(
        {
          g_attriubtes: { ...updateAtt }
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      )
    } else {
      this.setState(
        {
          g_attriubtes: {
            ...this.state.selectedAccount.attributes,
            [name]: value
          }
        },
        () => this.checkForAttrubutesChanged()
      )
    }
  }
  checkForAttrubutesChanged (selectedOption) {
    let changed = false
    if (this.state.selectedAccount) {
      changed = !isEqual(
        this.state.g_attriubtes,
        this.state.selectedAccount.attributes
      )
    } else {
      changed = !this.isEmpty(this.state.g_attriubtes)
    }
    let { name, email, contact } = this.state.form
    let selectedAccount = {}
    if ((name && email && contact) || (name && email && contact && changed)) {
      selectedAccount = this.state.g_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState({
          isVisableBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedAccount }
        })
      } else {
        this.setState({
          isVisableBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedAccount }
        })
      }
    } else {
      selectedAccount = this.state.g_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState(
          {
            isVisableBtn: false,
            isAttChanged: false,
            itemAttributes: { ...selectedAccount }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            isVisableBtn: false,
            isAttChanged: false,
            itemAttributes: { ...this.state.itemAttributes, ...selectedAccount }
          },
          () => this.checkRequiredFields()
        )
      }
    }
    if (changed) {
      this.setState({
        attributeChangesMessage: this.props.translate(
          'changeInAttributesIsPending'
        )
      })
    } else {
      this.setState(
        {
          attributeChangesMessage: ''
        },
        () => this.checkRequiredFields()
      )
    }
  }
  isEmpty = obj => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (!value) {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.target.type === 'number' ? parseInt(value) : value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  handleChange = name => event => {
    const { target } = event
    if (name === 'email') {
      if (!validEmailPattern.test(target.value)) {
        this.setState({
          emailPattern: true
        })
      } else {
        this.setState({
          emailPattern: false
        })
      }
    }

    this.setState(
      preState => ({
        ...preState,
        form: {
          ...preState.form,
          [name]: target.value
        }
      }),
      () => this.checkRequiredFields()
    )
  }
  handleChangeForSP = name => event => {
    const { target } = event
    if (target && target.type === 'checkbox') {
      this.setState(
        {
          spForm: {
            ...this.state.spForm,
            [name]: target.checked
          }
        },
        () => this.checkRequiredFields()
      )
    } else if (name === 'expirationTime') {
      this.setState(
        {
          spForm: {
            ...this.state.spForm,
            [name]: event.toISOString()
          }
        },
        () => this.checkRequiredFields()
      )
    } else if (name === 'email') {
      if (!validEmailPattern.test(target.value)) {
        this.setState(
          {
            spEmailPattern: true
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            spEmailPattern: false
          },
          () => this.checkRequiredFields()
        )
      }

      this.setState(
        {
          spForm: {
            ...this.state.spForm,
            [name]: target.value
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      let value = target.value
      if (value) {
        if (target.type === 'number') {
          value = parseFloat(value)
        }
        if (name === 'deviceLimit') {
          if (
            parseInt(value) === -1 &&
            parseInt(this.props.parentUnitLimits.remaningLimit) === -1
          ) {
            this.setState(
              {
                deviceLimitError: false,
                unitsLimitsMeaasge: ''
              },
              () => this.checkRequiredFields()
            )
          } else {
            if (parseInt(value) > -1) {
              if (parseInt(this.props.parentUnitLimits.remaningLimit) === -1) {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: ''
                  },
                  () => this.checkRequiredFields()
                )
              } else if (
                parseInt(value) >
                parseInt(this.props.parentUnitLimits.remaningLimit)
              ) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: 'deviceLimitErrorParent'
                  },
                  () => this.checkRequiredFields()
                )
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: ''
                  },
                  () => this.checkRequiredFields()
                )
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: 'deviceLimitErrorParent'
                  },
                  () => this.checkRequiredFields()
                )
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: ''
                  },
                  () => this.checkRequiredFields()
                )
              }
            }
          }
        }
        if (name === 'userLimit') {
          if (
            parseInt(value) === -1 &&
            parseInt(this.props.parentUserLimits.remaningLimit) === -1
          ) {
            this.setState(
              {
                userLimitError: false,
                usersLimitsMeaasge: ''
              },
              () => this.checkRequiredFields()
            )
          } else {
            if (parseInt(value) > -1) {
              if (parseInt(this.props.parentUserLimits.remaningLimit) === -1) {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: ''
                  },
                  () => this.checkRequiredFields()
                )
              } else if (
                parseInt(value) >
                parseInt(this.props.parentUserLimits.remaningLimit - 1)
              ) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: 'userLimitErrorParent'
                  },
                  () => this.checkRequiredFields()
                )
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: ''
                  },
                  () => this.checkRequiredFields()
                )
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: 'userLimitErrorParent'
                  },
                  () => this.checkRequiredFields()
                )
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: ''
                  },
                  () => this.checkRequiredFields()
                )
              }
            }
          }
        }

        this.setState(
          {
            spForm: {
              ...this.state.spForm,
              [name]: value
            }
          },
          () => this.checkRequiredFields()
        )
      } else if (this.state.spForm[name]) {
        if (target.type === 'number') {
          value = parseFloat(value)
        }
        this.setState(
          {
            spForm: {
              ...this.state.spForm,
              [name]: value
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }
  }
  changeAttribute2ForSP = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (!value) {
      if (this.state.spForm.attributes[name]) {
        delete this.state.spForm.attributes[name]
      }
      this.setState(
        {
          spForm: {
            ...this.state.spForm,
            attributes: {
              ...this.state.spForm.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          spForm: {
            ...this.state.spForm,
            attributes: {
              ...this.state.spForm.attributes,
              [name]: event.target.type === 'number' ? parseInt(value) : value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  checkRequiredFields () {
    let { form, spForm } = this.state
    let { name, email, password, confirmPassword,userType } = spForm
    let valid = true
    if (!validEmailPattern.test(email)) {
      valid = false
    } else {
      this.setState({
        duplicateEntry: false
      })
    }
    let valid2 = true
    if (!validEmailPattern.test(form.email)) {
      valid2 = false
    }
    // check both user and device limit error
    let limitError = true
    if (!this.state.deviceLimitError && !this.state.userLimitError) {
      limitError = false
    }
    if (
      form.name &&
      form.email &&
      name &&
      password &&
      confirmPassword &&
      password === confirmPassword && 
      userType
    ) {
      this.setState({
        isVisableBtn: valid && valid2 && !limitError
      })
    } else {
      this.setState({
        isVisableBtn: this.state.isAttChanged
      })
    }
  }

  submit () {
    let { form, spForm } = this.state
    let { name, password } = spForm
    if (
      form.name.trim().length > 0 &&
      name.trim().length > 0 &&
      password.trim().length > 0
    ) {
      const obj1 = userDataFormat(this.state.spForm)
      let obj = {
        account: {
          name: form.name,
          description: '',
          email: form.email,
          contact: '',
          attributes: { ...form.attributes }
        },
        user: {
          ...obj1
        }
      }
      // if (this.props.logInUser.userType === 5) {
      //   obj.user.userType = 4
      // }
      if (form.contact) {
        obj.account.contact = form.contact
      }
      if (form.description) {
        obj.account.description = form.description
      }
      fetch(`/api/accounts`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
            response.json().then(res => {
              let user = res.data
              if(res.status === 'success'){
                this.getMoreAccounts()
                this.getUsersLimits()
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('accountCreated'),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
                this.setState({
                  spform: {},
                  form: {},
                  addAccount: false,
                  accountDefaultList: true,
                  topRow:true
                })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                var err2 =err[1].split('&#')
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err2[0]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
        })
        .catch(e => {
          this.props.dispatch(
            Notifications.error({
              message: 'some thing went wrong',
              autoDismiss: 5,
              location: this.props.location
            })
          )
        //  if(e && e.text){
        //   e.text().then(err => {
        //     if (err.includes('Duplicate entry')) {
        //       this.setState({
        //         duplicateEntry: true,
        //         isVisableBtn: false
        //       })
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('userEmailIsAllreadyInUsed'),
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //     } else if (err.includes('Manager user limit reached')) {
        //       this.setState({
        //         isVisableBtn: false
        //       })
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('userLimitReached'),
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //     } 
        //     // else {
        //     //   this.props.dispatch(
        //     //     Notifications.error({
        //     //       message: 'some thing went wrong',
        //     //       autoDismiss: 5,
        //     //       location: this.props.location
        //     //     })
        //     //   )
        //     // }
        //   })
        //  }
        })
    }
     else {
      this.setState(
        {
          isVisableBtn: false,
          isAttChanged: false
        },
        () => {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('emptyField'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        }
      )
    }
  }
  getUsersLimits = () => {
    if (this.props.logInUser.id) {
      //units limits
      if (checkPrivileges('device')) {
        fetch(`/api/devices/count?userId=${this.props.logInUser.id}`).then(
          response => {
              response.json().then(res => {
                if(res.status ==='success'){ 
                  let unitsLimits = res.data
                  this.props.dispatch(parentUnitsLimit(unitsLimits))
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                }
                else {
                  throw response
                }
              })       
          }).catch(e => {
            this.props.dispatch(
              Notifications.error({
                message: 'some thing went wrong',
                autoDismiss: 5,
                location: this.props.location
              })
            )
          })
      }
      //users limits
      if (checkPrivileges('user')) {
        fetch(`/api/users/count?userId=${this.props.logInUser.id}`).then(
          response => {
              response.json().then(res => {
                if(res.status ==='success'){ 
                  let usersLimits=res.data
                  this.props.dispatch(parentUsersLimit(usersLimits))
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                }
                else {
                  throw response
                }
              })
        }).catch(e => { 
          this.props.dispatch(
              Notifications.error({
                message: this.props.translate('somethingWentWrong'),
                autoDismiss: 5,
                location: this.props.location
              })
            )})
      }
    }
  }
  deleteAccount = () => {
    let { selectedAccount } = this.state
    if (selectedAccount.id) {
      fetch(`/api/accounts/${selectedAccount.id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...selectedAccount
        })
      })
        .then(response => {
          response.json().then(res=>{
            if(res.status=='success'){
              this.getMoreAccounts()
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('accountDeleted'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
              else {
                throw response
              }
            })
          })
        .catch(e => {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('somethingWentWrong'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
          // if(e && e.text){
          //   e.text().then(err => {
          //     var array = err.split("-")
          //     if(array.length > 1){
          //           this.props.dispatch(
          //             Notifications.error({
          //               message: this.props.translate(array[0]),
          //               autoDismiss: 5,
          //               location: this.props.location
          //             })
          //           )
                                  
          //     }
          //     else {
          //         this.props.dispatch(
          //           Notifications.error({
          //             message: this.props.translate('somthingWentWrongMessage'),
          //             autoDismiss: 5,
          //             location: this.props.location
          //           })
          //         )
          //     }
          
          // })
          // }
        })
      this.setState({
        selectedAccount: '',
        attributeChangesMessage: '',
        onDeleteConfirmation: false
      })
    }
  }

  addNewAccount () {
    let limitsOptionData = formDefaultState
    // limitsOptionData.deviceLimit =
    //   this.props.parentUnitLimits.remaningLimit === -1
    //     ? 0
    //     : this.props.parentUnitLimits.remaningLimit
    // limitsOptionData.userLimit =
    //   this.props.parentUserLimits.remaining === -1
    //     ? 0
    //     : this.props.parentUserLimits.remaining
    // if (limitsOptionData.userLimit > 0) {
    //   limitsOptionData.userLimit = limitsOptionData.userLimit - 1
    // }
    let defaulfFeildsSetting = this.checkAttributes(this.props.logInUser)
    this.setState({
      addAccount: true,
      topRow:false,
      accountDefaultList: false,
      form: { ...this.state.form, attributes: { ...attributesDefaultValue } },
      spForm: { ...limitsOptionData, ...defaulfFeildsSetting }
    })
  }
  checkAttributes = data => {
    let form = {
      coordinateFormat: data.coordinateFormat,
      poiLayer: data.poiLayer,
      latitude: data.latitude,
      longitude: data.longitude,
      zoom: data.zoom,
      map: data.map,
      attributes: {
        ['web.liveRouteLength']:
          data.attributes && data.attributes['web.liveRouteLength'],
        ['web.maxZoom']: data.attributes && data.attributes['web.maxZoom'],
        ['web.selectZoom']:
          data.attributes && data.attributes['web.selectZoom'],
        ['timezone']: data.attributes && data.attributes['timezone'],
        ['distanceUnit']: data.attributes && data.attributes['distanceUnit'],
        ['speedUnit']: data.attributes && data.attributes['speedUnit'],
        ['volumeUnit']: data.attributes && data.attributes['volumeUnit'],
        ['measurementStandard']:
          data.attributes && data.attributes['measurementStandard']
      }
    }
    return form
  }
  modalControle = () => {
    this.setState({
      addAccount: false,
      accountDefaultList: true,
      duplicateEntry: false,
      topRow:true,
    })
    
  }
  accountSelection = item => {
    const keys = Object.keys(item)
    const final = {}
    keys.map(key => {
      if (typeof item[key] === 'string') {
        final[key] = parseHtml(item[key])[0] || ''
      } else final[key] = item[key]
    })

    this.setState({
      accountDefaultList: false,
      selectedAccount: final,
      accountDetailOption: true,
      topRow: false,
    })
  }
  removeAccount = item => {
    this.setState({
      selectedAccount: item[0],
      onDeleteConfirmation: true
    })
  }
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }
  onCloseC_Modal = () => {
    this.setState({
      accountDetailOption: false,
      showStatisticsModel: false,
      loader: false,
      selectedAccount: '',
      topRow:true,
      accountDefaultList: true,
    })
  }
  showStatistics = data => {
    if (data[0]) {
      let minId = []
      let apiCall = `/api/users/get?accountId=${data[0].id}&all=true&limit=-1&search=`
      this.setState({
        loader: true,
        accountDefaultList:false,
        topRow:false
      })
      axios.get(apiCall).then(res => {
        let response = res.data
        response &&
          response.data &&
          response.data.data &&
          response.data.data.map(userId => {
            userId && userId.entity && minId.push(userId.entity.id)
          })
        minId.sort()
        response &&
          response.data &&
          response.data.data &&
          response.data.data.map(user => {
            if (minId && minId[0] === user.entity.id && user && user.entity) {
              minId = []
              minId.push(user.entity)
            }
          })
        if (minId && minId[0]) {
          this.setState({
            accountDefaultList: false,
            selectedAccount: minId[0],
            loader: false,
            topRow:false,
            showStatisticsModel: true,
          })
        } else {
          this.setState(
            {
              selectedAccount: '',
              loader: false,
              showStatisticsModel: false,
              topRow:true,
              accountDefaultList: true,

            },
            () => {
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate('statisticsNotFound'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          )
        }
      })
    }
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        //tableData: [],
        pageSize: value
        //defaultUserList: false
      },
      () => this.getMoreAccounts()
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        //tableData: [],
        page: value
        //defaultUserList: false
      },
      () => this.getMoreAccounts()
    )
  }
  SearchItem = searchValue => {
    source.cancel()
    this.setState(
      {
        page: 1,
        itemSearch: searchValue
      },
      () => this.getMoreAccounts()
    )
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  setPagination = () => {
    if (
      this.props.accounts &&
      this.props.accounts.data &&
      this.props.accounts.data.length
    ) {
      let nAcc = { ...this.props.accounts }
      let lastPage = nAcc.total / this.state.pageSize
      let IsFloate = this.checkFloteNumber(lastPage)
      delete nAcc.data
      nAcc.pageSize = this.state.pageSize
      nAcc.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nAcc
    }
    return {}
  }

  uploadImage = (name, e) => {
    let files = e.target.files
    let imgData = ''
    let reader = new FileReader()
    if (files && files[0]) {
      reader.readAsDataURL(files[0])
      reader.onload = e => {
        imgData = e.target.result
        if (imgData.split(':')[1].split('/')[0] === 'image') {
          let link = this.state.form.logo
          if (name === 'favIcon') {
            link = this.state.form.favIcon
          }
          if (name === 'logInPageImage') {
            link = this.state.form.logInPageImage
          }
          let obj = {
            name: `${this.props.logInUser.name.replace(/ +/g, '') +
              '_' +
              new Date().getTime()}`,
            contentType: `${files[0].type}`,
            oldUrl: `${name === link}`,
            data: `${imgData.split('base64')[1].substring(1)}`
          }
          this.setState(
            {
              form: {
                ...this.state.form,
                attributes: {
                  ...this.state.form.attributes,
                  [name]: obj
                }
              }
            },
            () => this.checkRequiredFields()
          )
        } else {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('onlyAcceptImage'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        }
      }
    } else {
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('youSelecteEmpty'),
          autoDismiss: 5,
          location: this.props.location
        })
      )
    }
  }
  handleAccountsChange = (name, e) => {
    if (name === 'type') {
      let accountsType = ''
      e.map(i => {
        accountsType += '&accountType=' + i.value
      })
      this.setState(
        {
          page: 1,
          accountTypes: accountsType
        },
        () => this.getMoreAccounts()
      )
    } else if (name === 'status') {
      let accountsStatus = ''
      e.map(i => {
        accountsStatus += '&accountStatus=' + i.value
      })
      this.setState(
        {
          page: 1,
          accountStatuses: accountsStatus
        },
        () => this.getMoreAccounts()
      )
    } else if (name === 'time') {
      let accountsTime = ''
      e.map(i => {
        accountsTime += '&accountTime=' + i.value
      })
      this.setState(
        {
          page: 1,
          accountTimes: accountsTime
        },
        () => this.getMoreAccounts()
      )
    }
  }
  render () {
    return (
      <Fragment>
        {this.state.addAccount && (
          <AddAccountModal
            {...this.state}
            roleTypes={this.props.roles && this.props.roles.filter(item=>item.id>7) }
            formSubmit={this.submit}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            handleChangeForSP={this.handleChangeForSP}
            modalControle={this.modalControle}
            userType={this.props.logInUser.userType}
            logInUser={this.props.logInUser}
            timeDateOnchange={this.timeDateOnchange}
            generateToken={this.generateToken}
            setNoToken={this.setNoToken}
            setExpirationTime={this.setExpirationTime}
            changeAttribute2={this.changeAttribute2}
            changeAttribute2ForSP={this.changeAttribute2ForSP}
            uploadImage={this.uploadImage}
            buttonText={this.props.translate('sharedCreate')}
            hasAccessOfUpdate={checkPrivileges('accountUpdate')}
            hasAccessOfDelete={checkPrivileges('accountDelete')}
            hasAccessOfCreate={checkPrivileges('accountCreate')}
          />
        )}
        

          {this.state.topRow && (<>
          <Grid container className="breadcrumb-row">
            <Grid item xs={12} sm={4}>
              <h3 style={{margin: 0}}>{this.props.translate('accounts')}</h3>
            </Grid>
            <Grid item xs={12} sm={8} style={{justifyContent: 'flex-end'}}>
              <div style={{width: "25%", marginRight: 20}}>
                <TextField
                  fullWidth
                  label={this.props.translate('search')}
                  value={this.state.itemSearch}
                  onChange={e => this.SearchItem(e.target.value)}
                  />
              </div>
                  <ExportFile
                    title={this.props.translate('reportExport')}
                    downloadType='accounts'
                    translate={this.props.translate}
                    data={reduceData(this.props.accounts && this.props.accounts.data)}
                    dataLength = {this.props.accounts && this.props.accounts.data && this.props.accounts.data.length ? true : false}
                    closedModel={this.onCloseC_Modal}
                    dispatch={this.props.dispatch}
                  />
              
              <Button
                disabled={!(checkPrivileges('accountCreate') &&
                checkPrivileges('userCreate') &&
                (this.props.parentUserLimits.remaningLimit > 0 ||
                  this.props.parentUserLimits.remaningLimit === -1)
                  ? true
                  : false)}
                  aria-label='Create'
                  size='small'
                  color='inherit'
                  onClick={this.addNewAccount}
                  id='createButton'
                  >{this.props.translate('create')}</Button>
            </Grid>
          </Grid>
          </>)}

          {this.state.accountDefaultList && (<>
          <Table
            rows={(this.props.accounts && this.props.accounts.data) || []}
            pagination={this.setPagination()}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleChangePage={this.handleChangePage}
            SearchItem={this.SearchItem}
            onClick={this.accountSelection}
            onDelete={this.removeAccount}
            // accountDataArray1={this.state.accountType}
            // accountDataArray2={this.state.accountStatus}
            // accountDataArray3={this.state.accountTime}
            accountDataArrayChange1={e => this.handleAccountsChange('type', e)}
            accountDataArrayChange2={e =>
              this.handleAccountsChange('status', e)
            }
            accountDataArrayChange3={e => this.handleAccountsChange('time', e)}
            showStatistics={this.showStatistics}
            isRowIcons
            btnName={this.props.translate('create')}
            onCreate={() => this.addNewAccount()}
            btnName1={'accounts'}
            rowsPerPage={15}
            isEditable={true}
            themecolors={this.props.themecolors}
            // hasAccessOfUpdate={checkPrivileges('userUpdate')}
            hasAccessOfDelete={checkPrivileges('accountDelete')}
            // title={this.props.translate('accounts')}
            filterDropdown
            setSerialNo
            isCursoPointer
            rowDefinition={[
              {
                id: 'name',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('sharedName')
              },
              {
                id: 'email',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('accountEmail')
              },
              {
                id: 'contact',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('contact')
              },
              // {
              //   id: 'parent',
              //   numeric: false,
              //   disablePadding: false,
              //   label: this.props.translate('parent')
              // },
              {
                id: 'description',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('sharedDescription')
              }
            ]}
          /></>
        )}
        {(this.state.selectedAccount && this.state.showStatisticsModel) ||
        this.state.loader ? (
          
            <ViewStatistics selectItem={this.state.selectedAccount} onClose={this.onCloseC_Modal} title="Accounts" />
          
        ) : null}

        {this.state.accountDetailOption && this.state.selectedAccount && (
          
            <ViewAccountDetail
              selectItemName={this.state.selectedAccount.name}
              selectItemId={this.state.selectedAccount.id}
              selectItem={this.state.selectedAccount}
              getMoreAccounts={this.getMoreAccounts}
              onClose={this.onCloseC_Modal}
            />
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteAccount}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.selectedAccount.name}
          />
        )}

      </Fragment>
    )
  }
}

const mapState = state => {
  return {
    accounts: state.accounts,
    users: state.users,
    logInUser: state.logInUsers,
    roles: state.roles,
    themecolors: state.themecolors,
    parentUserLimits: state.parentUserLimits,
    parentUnitLimits: state.parentUnitLimits
  }
}
const mapStateToProps = connect(mapState)
export const AccountModal = mapStateToProps(withLocalize(accountModal))
const reduceData = d => {
  let data = []
  if (d && d.length) {
    d.map(item => {
      data.push({
        name: item.name,
        email: item.email,
        description: item.description,
        contact: item.contact,
      })
    })
  }
  return data
}
