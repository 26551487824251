import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware  } from 'redux'
//import Reducers from './Reducers';
import { SnackbarProvider } from 'notistack';
import CR from './Reducers/index2'
import App from './App';
import { LocalizeProvider } from "react-localize-redux"
import registerServiceWorker from './registerServiceWorker';



function logger({ getState }) {
  return next => action => {
    //console.log(action.type, action)

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)

   // console.log(action.type+' -->  after :', getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}

//const store = createStore(Reducers, applyMiddleware(logger));
const store2 = createStore(CR,applyMiddleware(logger));

//console.log(store2.getState())

ReactDOM.render((

  <Provider store={store2}>
  <LocalizeProvider>
    <SnackbarProvider maxSnack={6} preventDuplicate={false} anchorOrigin={{vertical: 'top', horizontal: 'right', }}>
      <BrowserRouter>
      <div>
          <App />
        </div>
      </BrowserRouter>
    </SnackbarProvider>
    </LocalizeProvider>
  </Provider>
), document.getElementById('root'));

registerServiceWorker();
