export const updateGroups = groups => ({
  type: 'UPDATE_GROUPS',
  groups
});
export const updateGroup = group => ({
  type: 'UPDATE_GROUP',
  group
});
export const addGroup = group => ({
  type: 'ADD_GROUP',
  group
});

export const removeGroup = group => ({
  type: 'REMOVE_GROUP',
  group
});
