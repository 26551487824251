export function userLogInTypes (serverSetting, extraInfo, type) {
  let userLogInTypes = []
  if (extraInfo && extraInfo.hostUrl) {
    if (type !== 1 && type !==2) {
      userLogInTypes.push({
        id: 'panel',
        name: (serverSetting && serverSetting.adminUrl) || 'panel.flotilla.me'
      })
      if(
        serverSetting &&
        extraInfo &&
        serverSetting.adminUrl !== extraInfo.adminUrl){
        userLogInTypes.push({
          id: 'adminPanel',
          name: extraInfo && extraInfo.adminUrl
        })
      }
       
    }
    if (type !== 6 ) {
      userLogInTypes.push({
        id: 'front',
        name: (serverSetting && serverSetting.hostUrl) || 'flotilla.me'
      })
    }
    if (
      serverSetting &&
      extraInfo &&
      serverSetting.hostUrl !== extraInfo.hostUrl &&
      type !== 6
    ) {
      userLogInTypes.push({
        id: 'whiteLeabling',
        name: extraInfo && extraInfo.hostUrl
      })
    }
  } else {
    if (type !== 1) {
      userLogInTypes.push({
        id: 'panel',
        name: (serverSetting && serverSetting.adminUrl) || 'panel.flotilla.me'
      })
    }
    if (type !== 6) {
      userLogInTypes.push({
        id: 'front',
        name: (serverSetting && serverSetting.hostUrl) || 'flotilla.me'
      })
    }
  }

  return userLogInTypes
}
