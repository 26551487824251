import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import Notifications from 'react-notification-system-redux'
import MultiselectTwoSides from 'react-multiselect-two-sides'
import Loader from '../../../../Layout/Loader'
import 'react-multiselect-two-sides/style.css'
import './style.scss'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class Role extends Component {
  constructor () {
    super()
    this.state = {
      isRoleSet: false,
      assignRolesisRecived: false,
      selectedUseRoleList: '',
      loaderOption: true,
      multiselect: {
        options: [],
        value: []
      }
    }
    this.onDevicesAssignToUserSubmit = this.onDevicesAssignToUserSubmit.bind(
      this
    )
    this.unitAssignSubmit = this.unitAssignSubmit.bind(this)
  }
  componentWillMount () {
    fetch(`/api/roles?userId=${this.props.selectItemId.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(role => {
          if(role.status ==='success'){   
            this.setState(
              {
                selectedUseRoleList: role.data,
                assignRolesisRecived: true
              },
              () => this.setUnits()
            )
          }
          else if(role?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(role.statusCode){
            var err = role?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
        })
      } else {
        throw response
      }
    }).catch(e => {
       console.log('e =',e)
      // this.props.dispatch(Notifications.error({
      //   message: 'somethingWentWrong',
      //   autoDismiss: 5
      // }))
    })
  }
  setUnits = () => {
    let { roles } = this.props
    let { selectedUseRoleList } = this.state
    let options = [],
      value = []
    roles.map(item => {
      options.push({ name: item.name, value: item.id })
      if (selectedUseRoleList.length) {
        let data = selectedUseRoleList.filter(dec => dec.id === item.id)
        if (data.length) {
          value.push(item.id)
        }
      }
    })
    this.setState({
      loaderOption: false,
      isRoleSet: true,
      multiselect: {
        ...this.state.multiselect,
        options,
        value
      }
    })
  }
  symmetricDifference = (a1, a2) => {
    var a = [],
      diff = []

    for (let i = 0; i < a1.length; i++) {
      a[a1[i]] = true
    }

    for (let i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]]
      } else {
        a[a2[i]] = true
      }
    }

    for (let k in a) {
      diff.push(k)
    }

    return diff
  }

  handleChange2 = value => {
    console.log('user =', value)
    this.setState(
      {
        loaderOption: true
      },
      () => {
        let prvValue = this.state.multiselect.value
        let pormissionData = ''
        let method = 'DELETE'
        if (value.length) {
          if (value.length < prvValue.length) {
            method = 'DELETE'
            pormissionData = this.symmetricDifference(prvValue, value)
          } else {
            method = 'POST'
            pormissionData = this.symmetricDifference(prvValue, value)
          }
        } else {
          pormissionData = prvValue
        }
        if (value.length < prvValue.length) {
        }
        this.setState(
          {
            multiselect: {
              ...this.state.multiselect,
              value
            }
          },
          () => {
            this.onDevicesAssignToUserSubmit(pormissionData, method)
          }
        )
      }
    )
  }
  onDevicesAssignToUserSubmit (item, option) {
    console.log('item =', item )
    console.log('option =', option )
    if (item.length === 1) {
      let obj = {
        userId: this.props.selectItemId.id,
        roleId: item[0]
      }
      this.unitAssignSubmit(true, option, obj)
    } else {
      item.map(
        elm => {
          let obj = {
            userId: this.props.selectItemId.id,
            roleId: elm
          }
          this.unitAssignSubmit(false, option, obj)
        },
        () => {
          this.setState({
            loaderOption: false
          })
        }
      )
    }
  }

  unitAssignSubmit = (single, option, obj) => {
    if (single) {
      fetch(`/api/permissions`, {
        method: `${option}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          response.json().then(res=>{
          if (res.status === "success") {
            this.setState({
              loaderOption: false
            })
            if (option === 'POST') {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('roleIsAssignedSuccessfully'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            } else {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('roleIsUnAssignedSuccessfully'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
              this.setState({
                loaderOption: false
              }) 
          }
           else {
            throw response
          }
        })
      })
        .catch(e => {
          this.setUnits()
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('somethingWentWrong'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
          this.setState({
            loaderOption: false
          }) 
        })
    } else {
      fetch(`/api/permissions`, {
        method: `${option}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
      .then(response => {
        response.json().then(res => {
          if(res.status ==='success'){ 
            if (option === 'POST') {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate(
                    'multiRoleIsAssignedSuccessfully'
                  ),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            } else {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate(
                    'multiRoleIsUnAssignedSuccessfully'
                  ),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
            this.setState({
              loaderOption: false
            }) 
          }
        })
          
        })
        .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
    }
  }

  render () {
    const { classes } = this.props
    return (
      <Fragment>
        {this.state.loaderOption ? (
          <div style={{ textAlign: 'center' }}>
            <Loader defaultStyle />
          </div>
        ) : (
          <>
            {this.state.isRoleSet ? (
              <MultiselectTwoSides
                {...this.state.multiselect}
                className='msts_theme_example'
                onChange={this.handleChange2}
                availableHeader={this.props.translate('available')}
                selectedHeader={this.props.translate('assign')}
                labelKey='name'
                showControls
                searchable
                availableFooter={
                  this.props.translate('available') +
                  ' : ' +
                  (this.state.multiselect.options.length -
                    this.state.multiselect.value.length)
                }
                selectedFooter={
                  this.props.translate('assign') +
                  ' : ' +
                  this.state.multiselect.value.length
                }
              />
            ) : (
              <div style={{ textAlign: 'center' }}>
                <h4> {this.props.translate('noRoleFound')}</h4>
              </div>
            )}
          </>
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(Role)
