import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "./Checkbox";
import Style from "style-it";
import SearchField from "../common/SearchField";
import { withLocalize } from "react-localize-redux";
import Scrollbar from "react-scrollbars-custom";
import instance from "../../axios";
import Loader from "../../Layout/Loader";

class AccountSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      vehicles: [],
      isChecked:false,
      list:{},
      usersId: {},
      loader: true,
    };
  }

  componentWillUnmount() {
    this.setState({
      list: {},
      usersId: {},
      search: "",
      isChecked: false,
    });
  }
  componentWillMount() {
    let list = {};
    let usersId = {};

    if (this.props.value && this.props.value.length) {
      this.props.value.map((d) => (list[d] = true));
      this.setState({ list, loader: false });
    }
    if (this.props.value2 && this.props.value2.length) {
      this.props.value.map((d) => (usersId[d] = true));
      this.setState({ usersId, loader: false });
    }
      this.getAccounts();
    if (this.props.sendAll) this.sendToParent();
  }

  componentWillReceiveProps(nextprops) {
    let list = {};
    let usersId = {};
    if (nextprops.value && nextprops.value.length) {
      nextprops.value.map((d) => ((list[d] = true), (usersId[d] = true)));
    }
    this.setState({ list });
  }

  sendToParent() {
    if (this.props.onChange) {
      this.props.onChange(
        Object.keys(this.state.list),
        Object.keys(this.state.usersId)
      );
    }
  }

  onChange = (e, v) => {
    console.log("e====", e.target.checked,v);
    let list = { ...this.state.list };
    let usersId = { ...this.state.usersId };
    if (e.target.checked) {
      list[v.id] = true;
      usersId[v.userId] = true;
    } else {
      delete list[v.id];
      delete usersId[v.userId];
    }
    this.setState(
      {
        usersId,
        list,
        isChecked: this.props.vehicles?.length === Object.keys(list).length,
      },
      () => {
        this.sendToParent();
      }
    );
  };
  searchItem = (text) => {
    this.setState({ search: text.target.value }, () => {
      this.getAccounts();
    });
  };

  toggleItems = (event) => {
    if (event.target.checked) {
      let list = {};
      let usersId = {};
      this.state.vehicles.map(
        (d) => ((list[d.id] = true), (usersId[d.userId] = true))
      );
      this.setState({ list, usersId, isChecked: true }, () => {
        this.sendToParent();
      });
    } else {
      this.setState({ list: {}, usersId: {}, isChecked: false }, () => {
        this.sendToParent();
      });
    }
  };

  getAccounts = () => {
    let apiUrl;
  
    apiUrl = `api/accounts/get?userId=${this.props.logInUser.id}&all=true&search=${this.state.search}&limit=${-1}`
    
    if (this.props.logInUser && this.props.logInUser.id) {
      instance({
        method: "GET",
        url:apiUrl ,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({ vehicles: res.data, loader: false });
          if (this.props.type === "reports") {
            let list = {};
            let usersId = {};
            this.state.vehicles.map(
              (d) => ((list[d.id] = true), (usersId[d.userId] = true))
            );
      
            this.setState({ list, usersId, isChecked: true }, () => {
              this.sendToParent();
            });
          }
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  };

  render() {
  
    return (
      <>
        {this.state?.loader === false ? (
          <Style>
            {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
         
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
        }
        .selector-list {
            padding: 0;
            margin: 0;
            list-style: none
        }
        .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-check {
            height: 26px;
            display: inline-flex;
            align-items: center;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px
        }`}

            <div>
              <div
                className="section-head clearfix section-head-filter"
                style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
              >
                <ul className="filter-row pull-left">
                  <li>
                    <SearchField
                      label={this.props.translate("searchAccounts")}
                      type="search"
                      placeholder="exp: ID, Name"
                      value={this.state.search}
                      onChange={this.searchItem}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </li>
                </ul>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.toggleItems}
                  label={this.props.translate("selectAll")}
                />
              </div>
              <div className="selector-count">
                {Object.keys(this.state.list).length}{" "}
                {this.props.translate("AccountsSelected")}
              </div>
              <Scrollbar
                disableTracksWidthCompensation={true}
                style={{
                  height:
                    (this.props.minHeight
                      ? parseInt(this.props.minHeight)
                      : 35) * (this.props.rows || 5),
                }}
                scrollerProps={{
                  renderer: (props) => {
                    const { elementRef, ...restProps } = props;
                    return (
                      <div {...restProps} ref={elementRef} id="scrollableDiv" />
                    );
                  },
                }}
              >
              {this.state.vehicles ? 
                <ul className="selector-list">
                  {console.log("veh====", this.state.vehicles)}

                  {this.state.vehicles &&
                    this.state.vehicles.map((row) => (
                      <li key={row.id}>
                        <label
                          className={
                            this.state.list[row.id]
                              ? "active"
                              : null
                          }
                        >
                  {console.log("[row.id]====", this.state.list[row.id])}

                          <span className="selector-check">
                            <Checkbox
                              checked={this.state.list[row.id]}
                              // checked
                              value={row}
                              // onChange={this.onChange}
                              onChange={(e)=>this.onChange(e,row)}
                              // onClick={() => this.props.selecteItem(this.props.data)}
                            />
                          </span>
                          <span className="selector-label">
                            {row.name || row.accountName || row.label}
                          </span>
                        </label>
                      </li>
                    ))}
                </ul>:null}
              </Scrollbar>
            </div>
          </Style>
        ) : (
          <Loader component="filter" />
        )} 
      </>
    );
  }
}

const mapState = (state) => ({
  vehicles: state.vehicles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(withLocalize(AccountSelector));
