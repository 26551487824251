export const getAccounts = Accounts => ({
  type: 'GET_ACCOUNTS',
  Accounts
});
export const updateAccount = Account => ({
  type: 'UPDATE_ACCOUNT',
  Account
});
export const addAccount = Account => ({
  type: 'ADD_ACCOUNT',
  Account
});

export const removeAccount = Account => ({
  type: 'REMOVE_ACCOUNT',
  Account
});
