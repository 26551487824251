import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import Style from 'style-it'

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themecolors
  }
}

const styles = theme => ({})

class CustomTextField extends React.Component {
  render () {
    const { classes, dispatch, ...others } = this.props

    return (
      <Style>
        {`
					.theme-label {
						margin-top: -4px;
						font-size: 12px;
					}
					
					.theme-label-shrink {
						margin-top: 0
					}
					.theme-label-focused {
						color: ${this.props.themecolors['500']};
						margin-top: 0;
					}
					.theme-textarea {
						font-size: 14px;
						padding: 0
					}
					.theme-label-error {
						color: ${this.props.themecolors['error']}
					}
					.theme-notchedOutline {
						border-radius: 4px;
					}
					.theme-input-error .theme-notchedOutline {
						border-color:  ${this.props.themecolors['error']} !important;
					}
					.theme-cssFocused .theme-notchedOutline {
						border-color:  ${this.props.themecolors[500]} !important;
					}
					.theme-input-select {
						color: #999;
						padding: 0;
						display: flex
					}
					.theme-input {
						color: #999;
						font-size: 12px;
						padding: 5px 14px;
						min-height: 18px;
						height: auto
					}
					.menu-list-icon {
						color: #999;
					}
					.custom-paper {
						background-color: ${this.props.themecolors[500]};
					}
				`}
        <div>
          <TextField
            {...others}
            variant='outlined'
            classes={classes}
            InputProps={{
              ...this.props.InputProps,
              classes: {
                root: 'theme-cssOutlinedInput',
                error: 'theme-input-error',
                input: this.props.multiline ? 'theme-textarea' : 'theme-input',
                focused: 'theme-cssFocused',
                notchedOutline: 'theme-notchedOutline'
              }
            }}
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: 'custom-menu-paper'
                }
              },
              classes: {
                icon: 'menu-list-icon'
              }
            }}
            margin='dense'
            InputLabelProps={{
              ...this.props.InputLabelProps,
              classes: {
                root: 'theme-label',
                focused: 'theme-label-focused',
                shrink: 'theme-label-shrink',
                error: 'theme-label-error'
              }
            }}
          />
        </div>
      </Style>
    )
  }
}

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(styles)(CustomTextField))
