import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import Notifications from 'react-notification-system-redux'
import MultiselectTwoSides from 'react-multiselect-two-sides'
import Loader from '../../../../Layout/Loader'
import Table from '../../../common/TableMultiCheckbox'
import 'react-multiselect-two-sides/style.css'
import './style.scss'
import { Grid, Tooltip } from '@material-ui/core'
import { PaginationConfig } from '../../../../Helpers'
import TextField from '../../../common/TextField'
import axios from 'axios'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class Widgets extends Component {
  constructor () {
    super()
    this.state = {
      isGroupSet: false,
      assignGroupisRecived: false,
      selectedUseGroupsList: '',
      loaderOption: true,
      isChecked:false,
      multiselect: {
        options: [],
        value: []
      },
      loginUserGroupList: '',
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5, page: 1 },
      selectedUserIds:[],
      loginUserids:[]
    }
  }
  componentWillMount () {
    this.getWidgets()
  }

  getWidgets = () => {
    let { pagination } = this.state
    const { widget } = this.props
    let { page, pageSize, itemSearch } = pagination
    let sellectedIds = []
    let loginUserids = []
    let check2 = false

    axios
    .get(
      `/api/dashboards/gadgets?userId=${this.props.logInUser.id}&all=true&&page=${page}&limit=${pageSize}&search=${itemSearch}`,
    )
  
    .then(res => {
      let loginUserWidgets = res.data
      if (loginUserWidgets.status === 'success') {        
        let lastPage = loginUserWidgets.data.total / pageSize
        let IsFloate = this.checkFloteNumber(lastPage)
       
       
      fetch(`/api/dashboards/gadgets?userId=${this.props.selectItemId.id}&all=true&limit=-1`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
          }).then(response => {
            if (response.ok) {
              response.json().then(res2 => {
                if(res2.status ==='success'){ 
                  let selectedResources= res2.data 
                  loginUserWidgets.data.data.map(allResources => {
                    const linkedResources =selectedResources.data.find(userId => allResources.id === userId.id) 
                    if(linkedResources) {
                        allResources.check = true
                      }else{
                        allResources.check = false
                      }
                  })
                  this.setState(
                    {
                      selectedUseGroupsList: loginUserWidgets?.data?.data,
                      assignGroupisRecived: true,
                      loaderOption:false,
                      isGroupSet:true,
                      pagination: {
                        ...this.state.pagination,
                        lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
                        hasNext: loginUserWidgets?.data?.hasNext,
                        total: loginUserWidgets?.data?.total
                      },
                    })
                    selectedResources.data.map(userId => {
                      sellectedIds.push(userId.id)
                    })
                    loginUserWidgets.data.data.map(groupIds => {
                      loginUserids.push(groupIds.id)
                    })
                    this.setState({
                      selectedUserIds: sellectedIds,
                      loginUserids:loginUserids,

                    },()=>{
                      if (this.state.selectedUserIds.length > this.state.loginUserids.length) {
                        check2 = this.state.loginUserids.every(item => this.state.selectedUserIds.includes(item))
                        this.setState({isChecked: check2})
                      }
                       else {
                        if (this.state.selectedUserIds.length === this.state.loginUserids.length) {
                          let res = this.checker(this.state.selectedUserIds,this.state.loginUserids)
                          this.setState({isChecked: res})
                          
                        } else {this.setState({isChecked: false})}
                      }
                    })
                }
                else if(res2?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res2.statusCode){
                  var err = res2?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                }
              })
            } else {
              throw response
            }
          }).catch(e => {
              console.log('e =',e)
            // this.props.dispatch(Notifications.error({
            //   message: 'somethingWentWrong',
            //   autoDismiss: 5
            // }))
          })
  
      }
      else if(loginUserWidgets?.statusCode === '440'){
        window.location.replace('/login')
      }
      else if(loginUserWidgets.statusCode){
        var err = loginUserWidgets?.message.split(':')
        err[1] =err[1].replace(')', "")
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate(err[1]),
            autoDismiss: 5,
            location: this.props.location
          })
        )
      }

    })
  }

  checker =(array1, array2) =>{
  return array1.every(v => array2.includes(v));
 } 


  allUsersId = event => {
      let array1 = []
      if(event.target.checked){
        array1 =this.state &&
        this.state.loginUserids.filter(
          val => !this.state.selectedUserIds.includes(val)
        )
      }
      else {
        array1 = this.state &&
        this.state.loginUserids.filter(
          val => this.state.selectedUserIds.includes(val)
        )
      }
      
      let check = event.target.checked
      this.selectAllUser(array1,check)
    }

  selectAllUser =(array1, event)=>{
     
    // totalUserId.deviceId = this.props.selectItemId
    let obj
    let option = 'DELETE'
    if (event) {
      option = 'POST'
      obj = { userId: this.props.selectItemId.id, gadgetId: array1 }
    } else {
      obj = {
        userId: this.props.selectItemId.id,
        gadgetId: array1
      }
    }
    fetch(`api/permissions/multiproperty`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
        response.json().then(res => {
          if(res.status ==='success'){ 
            if (option === 'POST') {
              this.setState({
                isChecked: true
              })
  
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('allWidgetAssignedSuccessfully'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            } else {
              this.setState({
                isChecked: false
              })
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('allwidgetsUnAssignedSuccessfully'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
            this.getWidgets()
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
        })
       
      })
      .catch(e => {
        this.props.dispatch(Notifications.error({
          message: 'somethingWentWrong',
          autoDismiss: 5
        }))
      })
        // totalUserId = {
        //   userId: [],
        //   deviceId: ''
        // }
    }

  ResourceToUserpermission =(event, item)=> {
      let option = 'DELETE'
      if (event) {
        option = 'POST'
      }
      let obj = {
        userId: this.props.selectItemId.id,
        gadgetId: item.id
      }
      fetch(`/api/permissions`, {
        method: `${option}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          response.json().then(res =>{
          if (res.status === 'success') {
            this.getWidgets()
            if (option === 'POST') {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('widgetIsAssignedSuccessfully'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            } else {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('widgetIsUnAssignedSuccessfully'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          } 
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
            
          })
        
        })
        .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
    }

 

  handleChangeRowsPerPage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value,
          page:1
        }
      },
      () => {
        this.getWidgets()
      }
    )
  }

  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }

  SearchItem = searchValue => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue
        }
      },
      () => this.getWidgets()
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getWidgets()
    )
  }

  render () {
    const { classes } = this.props
    return (
      <Fragment>
        {this.state.loaderOption ? (
          <div style={{ textAlign: 'center' }}>
            <Loader defaultStyle />
          </div>
        ) : (
          <>
            {this.state.selectedUseGroupsList.length ? (
                <Grid container className="breadcrumb-row"> 
                <Grid item xs={12} sm={3}>
                    <h3 style={{margin: 0}}>{this.props.translate('Widgets')}</h3>
                </Grid>
                    <Grid item xs={12} sm={9} style={{justifyContent: 'flex-end'}}>
                      <div style={{ marginRight: '20px', width: "25%" }}>
                        <TextField
                          fullWidth
                          label={this.props.translate('search')}
                        onChange={e => this.SearchItem(e.target.value)}
                      />
                      </div>
                  </Grid>
                </Grid>
              )
                : ''
              }


            {this.state.isGroupSet && this.state.selectedUseGroupsList.length ? (
               <Table
                    rows={this.state.selectedUseGroupsList}
                    allUsersId= {this.allUsersId}
                    selectItemParentId={this.props.selectItemId.id}
                    pagination={this.state.pagination}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    loginAsUser={this.loginAsUser}
                    isChecked={this.state.isChecked}
                    isIndeterminate={this.state.isIndeterminate}
                    rowsPerPage={10}
                    isEditable={false}
                    showCheckbox={true}
                    ServerSetting={this.props.ServerSetting}
                    themecolors={this.props.themecolors}
                    checkHandleChange={this.ResourceToUserpermission}
                    canAssign
                    canRemove
                    rowDefinition={[
                      {
                        id: 'name',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('widgets')
                      }
                    ]}
                  />
            ) : (
              <div style={{ textAlign: 'center' }}>
                <h4> {this.props.translate('noWidgetsFound')}</h4>
              </div>
            )}
          </>
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(Widgets)
