import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import EditIcon from '@material-ui/icons/Edit'
import { lighten } from '@material-ui/core/styles/colorManipulator'
//import isEqual from 'react-fast-compare';
import MenuItem from './MenuItem'
import styled from 'styled-components'
import TextField from './TextField'
import Avatar from '@material-ui/core/Avatar'
import { userLogInTypes } from './userConstant'
import { withLocalize } from 'react-localize-redux'

function desc (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort (array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting (order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

/*const rows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
];*/

function searchFilter (array, value, colums) {
  var dev = array.filter(item => {
    let exits = false
    colums.map(c => {
      if (
        item[c.id] &&
        typeof item[c.id] !== 'boolean' &&
        typeof item[c.id] !== 'object' &&
        typeof item[c.id] !== 'number' &&
        item[c.id].toLowerCase().indexOf(value) !== -1
      ) {
        exits = true
      }
    })
    return exits
  })
  return dev
}
class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render () {
    const { order, orderBy, classes, isEditable, isRowIcons } = this.props
    return (
      <TableHead>
        <TableRow className={classes.tableRow}>
          {this.props.rowDefinition.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  padding: '0 10px',
                  whiteSpace: 'nowrap',
                  color: 'inherit'
                }}
              >
                <Tooltip
                  title='Sort'
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
          {isRowIcons && (
            <TableCell
              key={'Statistics'}
              style={{
                padding: '0 10px',
                whiteSpace: 'nowrap',
                color: 'inherit'
              }}
            >
              {' '}
              Statistics{' '}
            </TableCell>
          )}
          {isEditable && (
            <TableCell
              key={'editable'}
              style={{
                padding: '0 10px',
                whiteSpace: 'nowrap',
                color: 'inherit'
              }}
            />
          )}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const toolbarStyles = theme => ({
  root: {
    //paddingRight: theme.spacing(1),
    minHeight: '44px',
    //paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(1)
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 auto'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    fontFamily: 'inherit',
    flex: '0 0 auto',
    fontSize: 18,
    fontWeight: 500
  }
})

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    className,
    themecolors,
    column,
    selected,
    actionInHead,
    style
  } = props

  const ThemeIconButton = styled(IconButton)`
    background-color: ${props => themecolors['500']};
    border-radius: 0;
    color: ${props => themecolors['themeInverse']};
    &:hover {
      cursor: pointer;
      background-color: ${props => themecolors['600']};
    }
  `

  return (
    <Toolbar
      className={
        classNames(classes.root, {
          [classes.highlight]: numSelected > 0
        }) +
        ' ' +
        className
      }
      style={style}
    >
      <div>
        {
          <Typography className={classes.title} id='tableTitle' color='inherit'>
            {props.title}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      {props.btnName && (
        <Tooltip title='Create'>
          <Button
            disabled={!props.createButton}
            aria-label='Create'
            size='small'
            variant='outlined'
            color='inherit'
            onClick={props.onCreate}
          >
            {props.btnName}
          </Button>
        </Tooltip>
      )}
      <div className={classes.actions}>
        {actionInHead && numSelected > 0 ? (
          <Tooltip title='Delete'>
            <ThemeIconButton
              aria-label='Delete'
              color='inherit'
              onClick={props.onDelete}
            >
              <DeleteIcon fontSize='small' />
            </ThemeIconButton>
          </Tooltip>
        ) : null}
        {actionInHead && numSelected === 1 ? (
          <Tooltip title='Edit'>
            <ThemeIconButton
              aria-label='Edit'
              onClick={props.onEdit}
              color='inherit'
            >
              <EditIcon fontSize='small' />
            </ThemeIconButton>
          </Tooltip>
        ) : null}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = theme => ({
  root: {
    width: '100%',
    paddingTop: 44,
    boxShadow: 'none',
    position: 'relative',
    border: `1px solid rgba(0,0,0,0.12)`
  },
  table: {
    minWidth: '100%'
  },
  tableWrapper: {
    overflow: 'auto'
  },
  tableRow: {
    height: 36,
    '&:hover .actionButton': {
      display: 'inline-block'
    },
    '& .actionButton': {
      cursor: 'pointer',
      marginLeft: 5,
      display: 'none'
    }
  },
  toolbar: {
    root: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '44px',
      minHeight: 'inherit'
    },
    toolbar: {
      height: '44px',
      minHeight: 'inherit'
    }
  }
})

const TablePaginationStyles = theme => ({
  root: {
    height: '44px',
    minHeight: 'inherit',
    overflow: 'hidden',
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  toolbar: {
    height: '44px',
    minHeight: 'inherit',
    paddingRight: theme.spacing(1)
  }
})

export const TablePaginationStyle = withStyles(TablePaginationStyles)(
  TablePagination
)

class EnhancedTable extends Component {
  constructor (props) {
    super(props)
    this.onEdit = this.onEdit.bind(this)
    this.showStatistics = this.showStatistics.bind(this)
    this.loginAsUser = this.loginAsUser.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onCreate = this.onCreate.bind(this)
    this.drawValue = this.drawValue.bind(this)
    this.makeSearch = this.makeSearch.bind(this)
    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: this.props.rows,
      page: 0,
      rowsPerPage: this.props.rowsPerPage || 5,
      searchValue: ''
    }
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  // }

  makeSearch (event) {
    this.setState({ searchValue: event.target.value })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }))
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (event, item) => {
    if (item.hasAccess !== undefined) {
      if (item.hasAccess) {
        if (this.props.onClick) {
          let newSelected = []
          newSelected.push(item)
          this.props.onClick(item)
          this.setState({ selected: newSelected })
        }
      }
    } else {
      if (this.props.onClick) {
        let newSelected = []
        newSelected.push(item)
        this.props.onClick(item)
        this.setState({ selected: newSelected })
      }
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  isSelected = id =>
    (this.state.selected[0] && this.state.selected[0].id === id) || false

  onEdit (event, selected) {
    event.stopPropagation()
    this.props.onEdit(selected)
  }

  loginAsUser (event, selected) {
    event.stopPropagation()
    this.props.loginAsUser(selected)
  }
  handleChange = (event, name) => {
    const { target } = event
    if (target) {
      this.props.loginAsUserhandleChange(name, event.target.value)
    }
  }
  stopthandleClick = e => {
    e.stopPropagation()
  }
  showStatistics (event, selected) {
    event.stopPropagation()
    this.props.showStatistics(selected)
  }

  onDelete (event, selected) {
    event.stopPropagation()
    this.props.onDelete(selected)
    this.setState({ selected: [] })
  }

  onClose () {
    this.props.onClose()
  }

  onCreate () {
    this.props.onCreate()
  }

  drawValue (value, type, valueType) {
    if (type && type === 'any') {
      if (valueType && valueType === 'boolean') {
        if (value === true) {
          return 'Yes'
        }
        return 'No'
      } else {
        return value
      }
    } else if (type && type === 'boolean') {
      if (value === true) {
        return 'Yes'
      }
      return 'No'
    } else if (type && type === 'userType') {
      if (value === 1) {
        return 'User'
      } else if (value === -1) {
        return 'SuperAdmin'
      } else if (value === 2) {
        return 'Admin'
      } else if (value === 3) {
        return 'Dealer'
      }
      return ''
    } else if (type && type === 'symbol') {
      let ty = ''
      if (value === -1) {
        ty = 'O'
      } else if (value === 1) {
        ty = 'U'
      } else if (value === 2) {
        ty = 'A'
      } else if (value === 3) {
        ty = 'SP'
      } else if (value === 4) {
        ty = 'D'
      } else {
        ty = ''
      }
      return (
        <Avatar
          className={'avatar-' + ty}
          style={{
            width: 18,
            height: 18,
            fontSize: 12,
            display: 'inline-flex',
            marginLeft: '2px'
          }}
        >
          {ty}
        </Avatar>
      )
    } else {
      return value
    }
  }

  render () {
    const { classes } = this.props
    const { order, orderBy, selected, rowsPerPage, page } = this.state
    const { rows: data = [], isCursoPointer, translate } = this.props
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

    return (
      <Paper
        className={classes.root}
        style={{ paddingTop: this.props.title ? '44px' : 0 }}
      >
        {this.props.title && (
          <EnhancedTableToolbar
            actionInHead={this.props.actionInHead}
            column={this.props.showInHead}
            selected={this.state.selected}
            insideDialog={this.props.insideDialog}
            themecolors={this.props.themecolors}
            numSelected={selected.length}
            title={this.props.title}
            onEdit={this.onEdit}
            showStatistics={this.showStatistics}
            loginAsUser={this.loginAsUser}
            handleChange={this.handleChange}
            onDelete={this.onDelete}
            onClose={this.onClose}
            onCreate={this.onCreate}
            createButton={this.props.createButton}
            btnName={this.props.btnName}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              background: this.props.themecolors['500'],
              color: this.props.themecolors['themeInverse'],
              paddingRight: this.props.insideDialog ? '44px' : 'def',
              paddingLeft: this.props.insideDialog ? '44px' : 'def'
            }}
          />
        )}

        <div className={classes.tableWrapper}>
          {this.props.searchable && (
            <div style={{ padding: '10px 10px 0', textAlign: 'right' }}>
              <TextField
                fullWidth
                label={this.props.translate('search')}
                onChange={this.makeSearch}
              />
            </div>
          )}
          <Table className={classes.table} aria-labelledby='tableTitle'>
            <EnhancedTableHead
              translate={this.props.translate}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              isEditable={this.props.isEditable}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rowDefinition={
                this.props.rowDefinition || [
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: true,
                    label: 'Name'
                  }
                ]
              }
              classes={classes}
              isRowIcons={this.props.isRowIcons}
            />
            <TableBody>
              {searchFilter(
                stableSort(data, getSorting(order, orderBy)),
                this.state.searchValue,
                this.props.rowDefinition
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id)
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n)}
                      role='checkbox'
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id || n.name}
                      selected={isSelected}
                      className={
                        classes.tableRow + ' ' + (isCursoPointer && 'pointer')
                      }
                    >
                      {this.props.rowDefinition.map(r => {
                        if (r.id === 'loginAsUser') {
                          return (
                            <TableCell
                              key={r.id}
                              component='td'
                              scope='row'
                              style={{
                                fontSize: 8,
                                display: 'flex',
                                alignItems: 'center',
                                width: 182,
                                padding: '0 10px',
                                color: 'inherit',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              <TextField
                                key={n.id + n.name}
                                select
                                label={''}
                                value={n.logInAsUserState || ''}
                                onChange={e => this.handleChange(e, n.id)}
                                onClick={e => this.stopthandleClick(e)}
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu
                                  }
                                }}
                                style={{ fontWeight: 300 }}
                                margin='dense'
                                fullWidth
                              >
                                {userLogInTypes(
                                  this.props.ServerSetting.attributes
                                ).map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                              {localStorage.getItem('adminToken') ? null : (
                                <a
                                  href='javascript:void(0)'
                                  onClick={e => this.loginAsUser(e, [n])}
                                >
                                  <ExitToAppIcon
                                    className=''
                                    fontSize='small'
                                  />
                                </a>
                              )}
                            </TableCell>
                          )
                        } else {
                          return (
                            <TableCell
                              key={r.id}
                              align={r.numeric ? 'right' : 'left'}
                              padding={r.disablePadding ? 'none' : 'default'}
                              component='td'
                              scope='row'
                              style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: 'inherit'
                              }}
                            >
                              {this.drawValue(n[r.id], r.type, n.valueType)}
                            </TableCell>
                          )
                        }
                      })}
                      {this.props.isRowIcons && (
                        <TableCell
                          key={n.id + 'tableCel'}
                          align={'left'}
                          padding={'default'}
                          component='td'
                          scope='row'
                          style={{
                            padding: '0 10px',
                            whiteSpace: 'nowrap',
                            color: 'inherit'
                          }}
                        >
                          <a
                            href='javascript:void(0)'
                            onClick={e => this.showStatistics(e, [n])}
                          >
                            <EqualizerIcon fontSize='small' />
                          </a>
                        </TableCell>
                      )}
                      {this.props.isEditable && (
                        <TableCell
                          key={n.id + 'tableCell1'}
                          component='td'
                          scope='row'
                          style={{
                            padding: '0 10px',
                            whiteSpace: 'nowrap',
                            color: 'inherit'
                          }}
                        >
                          {this.props.hasAccessOfUpdate && (
                            <Tooltip title='Edit'>
                              <a
                                href='javascript:void(0)'
                                onClick={e => this.onEdit(e, [n])}
                              >
                                <EditIcon
                                  className='actionButton'
                                  fontSize='small'
                                />
                              </a>
                            </Tooltip>
                          )}
                          {this.props.hasAccessOfDelete && (
                            <Tooltip title='Delete'>
                              <a
                                href='javascript:void(0)'
                                onClick={e => this.onDelete(e, [n])}
                              >
                                <DeleteIcon
                                  className='actionButton'
                                  fontSize='small'
                                />
                              </a>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 26 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePaginationStyle
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100, 500]}
          component='div'
          count={
            searchFilter(data, this.state.searchValue, this.props.rowDefinition)
              .length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
            style: {
              padding: 6
            }
          }}
          height={44}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
            style: {
              padding: 6
            }
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    )
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withLocalize(withStyles(styles)(EnhancedTable))
