export const themecolors = (
  state = {
    '100': '#5CD7A9',
    '200': '#45D19D',
    '300': '#2ECC91',
    '400': '#17C685',
    '500': '#00C179',
    '600': '#00B06E',
    '700': '#009E64',
    '800': '#008D59',
    '900': '#007B4E',
    themeInverse: "#ffffff",
    themeDarkColor: "#045266",
    themeLightColor: "#00C179",
    themeLightInverse: "#ffffff",
    themeListingColor: "#0a83a1",
    themeListingInverse: "#ffffff",
    backgroundColor: "#ffffff",
    textColor: "#222222",
    lightGrey: '#f3f3f3',
    error: "#ff424c",
    success:'#4BB543',
    warning: '#f0ad4e',
    white: '#fff',
    inputFieldBg: '#f5f5f5'
  },
  action
) => {
  switch (action.type) {
    case 'fetchthemecolors':
      return { ...state }

    default:
      return state
  }
}
