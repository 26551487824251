import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import isEqual from 'react-fast-compare'
import { ViewResourceDetail } from './resourcesDetail/ViewResourceDetails'

import {
  addGroup,
  updateGroup,
  updateGroups,
  removeGroup
} from './../../Actions/Groups'
import { updateDevice } from '../../Actions/Devices'
import Table from './../../Components/common/tableWithBackEndPagination'
import {
  checkPrivileges,
  PaginationConfig
} from '../../Helpers'
import ConfirmDialoag from '../common/ConfirmDialoag'
import Button from './../common/Button'
import { Grid, Tooltip } from '@material-ui/core'
import TextField from '../common/TextField'
import AddResourceModal  from './addResouce'

const groupDataFormat = (data, extAttriubtes, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...extAttriubtes },
  name: data.name.trim(),
})
let source
class groupModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...PaginationConfig,
      selectedUsers: '',
      selectedGroup: '',
      addOption: false,
      editOption: false,
      defaultGroupList: true,
      g_attriubtes: '',
      directOption: false,
      isVisableGroupBtn: false,
      isAttChanged: false,
      selectedOption: false,
      usesId: '',
      groupsArray: [],
      itemAttributes: {},
      attributeChangesMessage: '',
      groupsProcess: false,
      onDeleteConfirmation: false,
      selectedGroupUnitList: '',
      unAssignUnitList: [],
      // all fields of form
      form: {
        name: '',
        groupId: ''
      }
    }
    this.editEnable = this.editEnable.bind(this)
    this.removeEnable = this.removeEnable.bind(this)
    this.ug_submit = this.ug_submit.bind(this)
    this.changeAttribute = this.changeAttribute.bind(this)
    this.groupSelection = this.groupSelection.bind(this)
    this.getGroups = this.getGroups.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.checkForAttrubutesChanged = this.checkForAttrubutesChanged.bind(this)
    // this.assignGroupToDevice = this.assignGroupToDevice.bind(this)
    this.onDevicesAssignToGroupSubmit = this.onDevicesAssignToGroupSubmit.bind(
      this
    )
  }
  componentWillMount () {
    this.getMoreGroups()
  }
  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
    this.props.dispatch(Notifications.removeAll())
  }
  getMoreGroups = () => {
    let { page, pageSize, itemSearch } = this.state
    source = axios.CancelToken.source()

    axios
      .get(
        `/api/groups/get?userId=${this.props.logInUser.id}&all=true&&page=${page}&limit=${pageSize}&search=${itemSearch}`,
        {
          cancelToken: source.token
        }
      )
      .then(response => {
        let res = response.data
        if (res.status === 'success') {
          this.props.dispatch(updateGroups(response.data.data))
        }
        else if(res?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(res.statusCode){
          var err = res?.message.split(':')
          err[1] =err[1].replace(')', "")
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate(err[1]),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        }
      })
      .catch(e => {
        //   this.props.dispatch(Notifications.error({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      })

  }
  editEnable (selected) {
    if (selected[0]) {
      this.setState(
        {
          itemAttributes: selected[0].attributes,
          directOption: true,
          editOption: true,
          addOption: false,
          selectedGroup: selected[0],
          selectedOption: true,
          defaultGroupList: false,
          isVisableGroupBtn: false,
          isAttChanged: false,
          form: { ...selected[0] }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          itemAttributes: this.state.g_attriubtes,
          editOption: true,
          defaultGroupList: false,
          selectedOption: false,
          directOption: false,
          isVisableGroupBtn: false,
          isAttChanged: false
        },
        () => this.checkRequiredFields()
      )
    }
  }
  removeEnable (selected) {
    if (selected[0]) {
      this.setState({
        directOption: true,
        selectedGroup: selected[0],
        attributeChangesMessage: '',
        onDeleteConfirmation: true,
        form: { ...selected[0] }
      })
    } else {
      this.setState({
        form: '',
        directOption: false,
        attributeChangesMessage: '',
        onDeleteConfirmation: true
      })
    }
  }
  ag_submit = () =>{
    let { name } = this.state.form
    if (name.toString().trim().length > 0) {
      const { itemAttributes, form } = this.state
      const obj = groupDataFormat(form, itemAttributes)
      fetch('/api/groups/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          if (response.ok) {
            response.json().then(group => {
              if(group.status ==='success'){
                this.setState({
                  addOption: false,
                  g_attriubtes: '',
                  defaultGroupList: true,
                  isVisableGroupBtn: false,
                  isAttChanged: false,
                  form: '',
                  attributeChangesMessage: ''
                })
                this.getMoreGroups()
                if (this.props.onCloseModal) {
                  this.props.onCloseModal()
                }
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('groupsAdded'),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
              else if(group?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(group?.statusCode){
                var err = group?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
          } else {
            throw response
          }
        })
        .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
    } else {
      this.setState({
        isVisableGroupBtn: false,
        isAttChanged: false
      })
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('emptyField'),
          autoDismiss: 5,
          location: this.props.location
        })
      )
    }
  }
  ug_submit () {
    let { name } = this.state.form
    if (name.trim().length > 0) {
      const { itemAttributes, selectedGroup, form } = this.state
      let itemId = selectedGroup.id
      const obj = groupDataFormat(form, itemAttributes, itemId)

      fetch(`/api/groups/${itemId}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('groupsUpdated'),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }

              this.getMoreGroups()
            })
          } else {
            throw response
          }
        })
        .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
        // .catch(e => {
        //  if(e && e.text){
        //   e.text().then(err => {
        //     if (err.includes('Cycle in group hierarchy')) {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('cycleInGroupHierarchy'),
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //     } else {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('somthingWentWrongMessage'),
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //     }
        //   })
        // }
        // })
      this.setState({
        selectedGroup: '',
        editOption: false,
        g_attriubtes: '',
        defaultGroupList: true,
        isVisableGroupBtn: false,
        isAttChanged: false,
        form: ''
      })
    } else {
      this.setState({
        isVisableGroupBtn: false,
        isAttChanged: false
      })
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('emptyField'),
          autoDismiss: 5,
          location: this.props.location
        })
      )
    }
  }
  deleteGroup = () => {
    let { selectedGroup } = this.state
    if (selectedGroup.id) {
      fetch(`/api/groups/${selectedGroup.id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...selectedGroup
        })
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){
                this.getMoreGroups()
                this.setState({
                  onDeleteConfirmation: false,
                  defaultGroupList: true,
                  editOption: false,
                  selectedOption: false
                })
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('groupsDeleted'),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
               }
               else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
               else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
          } else {
            throw response
          }
        })
        .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
    }
  }
  modalControle =() =>{
   this.setState({
      addOption: false,
      editOption: false,
      selectedGroup: '',
      defaultGroupList: true,
      isVisableGroupBtn: false,
      isAttChanged: false,
      attributeChangesMessage: '',
      form: ''
    })
    if (this.props.onCloseModal) {
      this.props.onCloseModal()
    }
  }
  addGroup () {
    this.setState({
      selectedGroup: '',
      editOption: false,
      addOption: true,
      defaultGroupList: false,
      form: '',
      attributeChangesMessage: ''
    })
  }
  updateGroup () {
    this.setState({
      defaultGroupList: false,
      editOption: true
    })
  }
  changeAttribute (name, value, selectedOption, updateAtt) {
    if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
      this.setState(
        {
          g_attriubtes: { ...updateAtt }
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      )
    } else {
      this.setState(
        {
          g_attriubtes: {
            ...this.state.selectedGroup.attributes,
            [name]: value
          }
        },
        () => this.checkForAttrubutesChanged()
      )
    }
    // console.log("option = ", selectedOption , updateAtt);
  }

  checkForAttrubutesChanged (selectedOption) {
    let changed = false
    if (this.state.selectedGroup) {
      changed = !isEqual(
        this.state.g_attriubtes,
        this.state.selectedGroup.attributes
      )
    } else {
      changed = !this.isEmpty(this.state.g_attriubtes)
    }
    let { name } = this.state.form
    let selectedGroup = {}
    if (name || (name && changed)) {
      selectedGroup = this.state.g_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState({
          isVisableGroupBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedGroup }
        })
      } else {
        this.setState({
          isVisableGroupBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedGroup }
        })
      }
    } else {
      selectedGroup = this.state.g_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState(
          {
            isVisableGroupBtn: false,
            isAttChanged: false,
            itemAttributes: { ...selectedGroup }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            isVisableGroupBtn: false,
            isAttChanged: false,
            itemAttributes: { ...this.state.itemAttributes, ...selectedGroup }
          },
          () => this.checkRequiredFields()
        )
      }
    }
    if (changed) {
      this.setState({
        attributeChangesMessage: this.props.translate(
          'changeInAttributesIsPending'
        )
      })
    } else {
      this.setState(
        {
          attributeChangesMessage: ''
        },
        () => this.checkRequiredFields()
      )
    }
  }
  isEmpty = obj => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }

  groupSelection (item) {
    if (item) {
      this.setState({
        selectedGroup: item,
        selectedOption: true,
        defaultGroupList: false,
        directOption: false,
        g_attriubtes: item.attributes,
        form: { ...item }
      })
    }
  }
  handleChange = name => event => {
    const { target } = event

    this.setState(
      preState => ({
        ...preState,
        form: {
          ...preState.form,
          [name]: target.value
        }
      }),
      () => this.checkRequiredFields()
    )
  }

  checkRequiredFields () {
    let { name } = this.state.form
    let changed = !isEqual(this.state.form, this.state.selectedGroup)
    if (name && changed) {
      this.setState({
        isVisableGroupBtn: true
      })
    } else {
      this.setState({
        isVisableGroupBtn: this.state.isAttChanged
      })
    }
  }
  getGroups (arr) {
    if (arr && arr.length) {
      return arr.map(g => {
        let parentRow = g
        let row = arr.filter(a => {
          return a.id === g.groupId
        })
        if (row[0] && row[0].name) {
          parentRow['parentgroupName'] = row[0].name
        } else {
          parentRow['parentgroupName'] = null
        }
        return parentRow
      })
    }
    return arr
  }
  onDevicesAssignToGroupSubmit (item, option) {
    let { selectedGroup } = this.state
    if (item.length) {
      let obj = {
        groupId: option === 'POST' ? selectedGroup.id : 0,
        deviceId: item[item.length - 1].value
      }
      fetch(`api/permissions`, {
        method: option,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      }).then(response => {
        response.json().then(res => {
          if(res.status ==='success'){
            if (option === 'POST') {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('unitIsAssignedSuccessfully'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            } else {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('unitIsUnAssignedSuccessfully'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
        })
      })
      .catch(e => {
        this.props.dispatch(Notifications.error({
          message: 'somethingWentWrong',
          autoDismiss: 5
        }))
      })
    }
  }
//   assignGroupToDevice = () => {
//     let { selectedGroup } = this.state
//     let unAssignDevice = [],
//       assignDevice = []
//     // list call of device
//     fetch(
//       `/api/devices/list?userId=${this.props.logInUser.id}&all=true&limit=-1`
//     ).then(response => {
//       if (response.ok) {
//         response.json().then(devices => {
//           if (devices.data.length) {
//             devices.data.map(item => {
//               if (!item.groupId) {
//                 unAssignDevice.push(item)
//               }
//               if (parseInt(item.groupId) === parseInt(selectedGroup.id)) {
//                 assignDevice.push(item)
//               }
//             })
//           }
//           this.setState({
//             selectedGroupUnitList: assignDevice,
//             unAssignUnitList: unAssignDevice,
//             isVisableUnitAssignModal: false,
//             totalList: devices.data
//           })
//         })
//       }
//       else{
//         throw response
//       }
//     }).catch(e => {})
//   }
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
      isVisableUnitAssignModal: false
    })
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        //tableData: [],
        pageSize: value
        //defaultUserList: false
      },
      () => this.getMoreGroups()
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        //tableData: [],
        page: value
        //defaultUserList: false
      },
      () => this.getMoreGroups()
    )
  }
  SearchItem = searchValue => {
    source.cancel()
    this.setState(
      {
        page: 1,
        itemSearch: searchValue
      },
      () => this.getMoreGroups()
    )
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  setPagination = items => {
    let nItem = { ...items }
    let lastPage = nItem.total / this.state.pageSize
    let IsFloate = this.checkFloteNumber(lastPage)
    delete nItem.data
    nItem.pageSize = this.state.pageSize
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
    return nItem
  }
  render () {
    const { logInUser } = this.props
    let { selectedGroupUnitList, unAssignUnitList } = this.state
    return (
      <div>
    {this.state.selectedGroup &&
        this.state.selectedOption &&
        !this.state.directOption ? (
        <ViewResourceDetail
            selectItemName={this.state.form.name}
            form={this.state.form}
            onClose={this.modalControle}
            formSubmit={this.ug_submit}
            handleChangeForm={this.handleChange}
            isVisableGroupBtn={this.state.isVisableGroupBtn}
            editEnable={this.editEnable}
            removeEnable={this.removeEnable}
            hasAccessOfUpdate={checkPrivileges('groupUpdate')}
            hasAccessOfDelete={checkPrivileges('groupDelete')}
            hasAccessDeviceLinkToGroup={checkPrivileges('deviceUpdate')}
            assignGroupToDevice={this.assignGroupToDevice}
        />
        ) : null} 


          {this.state.addOption ? (
            <AddResourceModal
                group={this.props.groups.data}
                selectItemName={this.state.form.name}
                form={this.state.form}
                onClose={this.modalControle}
                formSubmit={this.ag_submit}
                handleChange={this.handleChange}
                buttonText={this.props.translate('create')}
                changeAttribute={this.props.changeAttribute}
                itemAttributes={this.props.itemAttributes}
                isVisableGroupBtn={this.state.isVisableGroupBtn}
                hasAccessOfUpdate={checkPrivileges('groupUpdate')}
                hasAccessOfDelete={checkPrivileges('groupDelete')}
                hasAccessDeviceLinkToGroup={checkPrivileges('groupUpdate')}
                assignGroupToDevice={this.assignGroupToDevice}
            />
            ) : null}

        {this.state.defaultGroupList && (<>
          <Grid container className="breadcrumb-row">
            <Grid item xs={12} sm={4}>
              <h3 style={{margin: 0}}>{this.props.translate('resources')}</h3>
            </Grid>
            <Grid item xs={12} sm={8} style={{justifyContent: 'flex-end'}}>
              <div style={{width: "25%", marginRight: 20}}>
                <TextField
                  fullWidth
                  label={this.props.translate('search')}
                  onChange={e => this.SearchItem(e.target.value)}
                  />
              </div>
              <Button
                disabled={false}
                  aria-label='Create'
                  size='small'
                  color='inherit'
                  onClick={() => this.addGroup()}
                  id='createButton'
                  >{this.props.translate('create')}</Button>
            </Grid>
          </Grid>
          <Table
            insideDialog={true}
            rows={
              this.props.groups && this.props.groups.data
                ? this.getGroups(this.props.groups.data)
                : []
            }
            pagination={
              this.props.groups &&
              this.props.groups.data &&
              this.props.groups.data.length &&
              this.setPagination(this.props.groups)
            }
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleChangePage={this.handleChangePage}
            SearchItem={this.SearchItem}
            onClick={this.groupSelection}
            onDelete={this.removeEnable}
            isEditable={true}
            // searchable
            themecolors={this.props.themecolors}
            // hasAccessOfUpdate={checkPrivileges('groupUpdate')}
            hasAccessOfDelete={checkPrivileges('groupDelete')}
            // title={this.props.translate('groups')}
            createButton={checkPrivileges('groupCreate')}
            btnName={this.props.translate('create')}
            // onCreate={() => this.addGroup()}
            setSerialNo
            rowDefinition={[
              {
                id: 'name',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('sharedName')
              },
              {
                id: 'parentgroupName',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('parentName')
              }
            ]}
            /></>
        )}

        {/* {this.state.isVisableUnitAssignModal && (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable={true}
            title={this.props.translate('deviceTitle')}
            onClose={this.onCancel}
            isButtonVisable={true}
            draggable={true}
            fullWidth
            bodyPadding={20}
          >
            <div style={{ minHeight: 200 }}>
              <Select
                isMulti
                key={'devices'}
                array={unAssignUnitList}
                label={this.props.translate('deviceTitle')}
                multi={
                  selectedGroupUnitList
                    ? selectedGroupUnitList.map(item => {
                        return {
                          key: item.id,
                          value: item.id,
                          label: item.label
                        }
                      })
                    : []
                }
                returnSelected={this.onDevicesAssignToGroupSubmit}
                canAssign={checkPrivileges('deviceUpdate')}
                canRemove={checkPrivileges('deviceUpdate')}
              />
            </div>
          </CustomDialog>
        )} */}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteGroup}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.selectedGroup.name}
          />
        )}
      </div>
    )
  }
}

const mapState = state => ({
  groups: state.groups,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
  devices: state.devices
})
const mapStateToProps = connect(mapState)
export const ResourceList = mapStateToProps(withLocalize(groupModal))
