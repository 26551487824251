export const getRoles = roles => ({
  type: 'GET_ROLES',
  roles
})
export const addRole = role => ({
  type: 'ADD_ROLE',
  role
})
export const updateRole = role => ({
  type: 'UPDATE_ROLE',
  role
})
export const removeRole = role => ({
  type: 'REMOVE_ROLE',
  role
})
export const sortRoles = roles => ({
  type: 'SORT_ROLES',
  roles
});


export const searchRole = role => ({
  type: 'SEARCH_ROLE',
  payload: role.role
});

