import React, { Component } from 'react'
import AsyncSelect from '../common/asyncSelect'
import { withStyles } from '@material-ui/core/styles'
import Notifications from 'react-notification-system-redux'
import Grid from '@material-ui/core/Grid'
import Button from '../common/Button'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '../common/TextField'
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1) * 2
  },
  bigAvatar: {
    margin: 10,
    width: 120,
    height: 100,
    borderRadius: '2%'
  }
})
let DefaultValues = {}

class branding extends Component {
  constructor () {
    super()
    this.state = {
      isVisableAddBtn: false,
      resultMessage: '',
      defaultOptions: [],
      defaultOptionsAllList: [],
      value: [],
      copyMode: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  componentWillMount () {
    if (this.props.defaultOptions)
      this.setState({
        defaultOptions: this.props.defaultOptions,
        defaultOptionsAllList: this.props.defaultOptions
      })
  }

  formSubmit () {
    let { ServerSetting, form } = this.props
    let { copyMode, value } = this.state
    let obj = {}
    let id = []
    if (copyMode === 'selective') {
      if (value.length) {
        value.map(val => {
          id.push(val.id)
        })
      }
    }
    obj = {
      ids: id,
      attributes: { ...ServerSetting.attributes, labeling: false },
      updateRequest: false,
      copyMode: copyMode
    }
    let apiCall = '/api/accounts/labeling/'
    fetch(`${apiCall + ServerSetting.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
        if (response.ok) {
          response.json().then(server => {
            if (server.status === 'success') {
              if (this.props.getMoreAccounts) {
                this.props.getMoreAccounts()
              }
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('brandingInfoIsUpdated'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
              this.setState({
                isVisableAddBtn: false
              })
              if (this.props.onClose) {
                this.props.onClose()
              }
            } else if(server?.statusCode === '440'){
              window.location.replace('/login')
            } 
            else if(server.statusCode){
              var err = server?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          })
        } else {
          throw response
        }
      })
      .catch(e => {
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate('somthingWentWrongMessage'),
            autoDismiss: 5,
            location: this.props.location
          })
        )
      })
  }

  handleChange (name, event) {
    if (event) {
      const { target } = event
      this.setState(
        {
          ...this.state,
          [name]: target.value
        },
        () => this.checkRequiredFields()
      )
    }
  }

  checkRequiredFields () {
    let { value, copyMode } = this.state
    let listValue = true
    if (copyMode === 'selective') {
      if (value.length) {
        listValue = true
      } else {
        listValue = false
      }
    }
    if (copyMode && listValue) {
      this.setState({
        isVisableAddBtn: true
      })
    } else {
      this.setState({
        isVisableAddBtn: false
      })
    }
  }

  onChange = (name, value, option) => {
    let defaultOpt
    let v = value
    if (option.action === 'clear') {
      defaultOpt = this.state.defaultOptionsAllList
    }
    if (option.action === 'select-option') {
      if (
        this.state.defaultOptions &&
        this.state.defaultOptions.length &&
        value &&
        value.length
      ) {
        value.map(item => {
          defaultOpt = this.state.defaultOptions.filter(df => df.id !== item.id)
        })
      }
    }
    if (option.action === 'remove-value') {
      let { removedValue } = option
      if (removedValue) {
        let newValue = {
          value: removedValue.id || removedValue.value,
          label: removedValue.label
        }
        defaultOpt = this.state.defaultOptions
        defaultOpt.push(newValue)
      }
    }
    this.setState({ value: v, defaultOptions: defaultOpt }, () =>
      this.checkRequiredFields()
    )
  }
  searchList = async inputValue => {
    if (!inputValue) {
      if (this.state.value) {
        return []
      } else {
        if (
          this.state.defaultOptions &&
          this.state.defaultOptions.length &&
          this.state.value &&
          this.state.value.length
        ) {
          let options = [...this.state.defaultOptions]
          for (let item in this.state.value) {
            let defaultOpt = this.state.defaultOptions.filter(
              df => df.id === item.id
            )
            if (defaultOpt) {
              delete options[item]
            }
          }
          return options
        }
        return this.state.defaultOptionsAllList
      }
    }

    let response = await fetch(
      `/api/accounts/all?accountId=${this.props.ServerSetting.id}&all=true&search=${inputValue}&limit=100`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    let json = await response.json()
    let defaultOptions = this.state.value
    let result =
      (await json.data) &&
      json.data.map(item => ({
        ...item,
        label: item.label || item.name,
        value: item.id
      }))
    if (result.length === 0) {
      this.setState({
        resultMessage: 'No option found'
      })
    } else {
      this.setState({
        resultMessage: ''
      })
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: 'Search for more.'
      })
    }
    if (defaultOptions && defaultOptions.length) {
      defaultOptions.map(item => {
        result.map(campItem => {
          if (item.id === (campItem.value || campItem.id)) {
            result.splice(result.indexOf(campItem), 1)
          }
        })
      })
    }
    return result
  }
  render () {
    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} sm={6} style={{ marginLeft: 14 }}>
            <TextField
              id='copyMode'
              select
              label={this.props.translate('copyMode')}
              value={this.state.copyMode || ''}
              onChange={e => this.handleChange('copyMode', e)}
              margin='dense'
              fullWidth
              required
            >
              {copyModeTypes.length &&
                copyModeTypes.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {this.props.translate(option.id)}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          {this.state.copyMode && this.state.copyMode === 'selective' && (
            <Grid item xs={12} md={7} sm={6}>
              <AsyncSelect
                value={this.state.value}
                {...this.props}
                placeholder={this.props.placeholder || 'Search Account'}
                resultMessage={this.state.resultMessage}
                defaultOptions={this.state.defaultOptions}
                handleChange={this.onChange}
                loadOptions={this.searchList.bind()}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={2} sm={6} style={{ marginLeft: 14 }}>
            <Button
              variant='contained'
              disabled={!this.state.isVisableAddBtn}
              onClick={() => this.formSubmit()}
            >
              {' '}
              {this.props.translate('sharedSave')}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }
}
const ChildBranding = withStyles(styles)
export default ChildBranding(branding)

const copyModeTypes = [
  { id: 'all' },
  { id: 'withOutLabeling' },
  { id: 'selective' }
]
