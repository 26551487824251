import { combineReducers } from 'redux'
import { accounts } from './Accounts'
import { users, searchUser, unitBaseUser, parentUserLimits } from './Users'
import { devices, searchRecently, parentUnitLimits } from './Devices'
import { groups } from './Groups'
import { logInUsers } from './logInUser'
import { ServerSetting } from './ServerSetting'
import { reports } from './Reports'
import { roles, searchRole } from './Roles'
import { privileges } from './Privileges'
import { templates } from './Templates'
import { themecolors } from './Theme'
import { reducer as notificationSystem } from 'react-notification-system-redux'
import { trash } from './Trash'
import { coupon } from './Coupon'
import { sideBar } from './SideBar'

export default combineReducers({
  accounts,
  users,
  searchUser,
  unitBaseUser,
  devices,
  parentUnitLimits,
  parentUserLimits,
  searchRecently,
  groups,
  logInUsers,
  ServerSetting,
  reports,
  themecolors,
  roles,
  searchRole,
  privileges,
  templates,
  notificationSystem,
  trash,
  coupon,
  sideBar
})
