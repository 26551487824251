export const accounts = (state = [], action) => {
  switch (action.type) {
    case 'GET_ACCOUNTS':
      return action.Accounts

    case 'ADD_ACCOUNT':
      let prvState = state
      prvState.data.push(action.Account)
      return prvState

    case 'UPDATE_ACCOUNT':
      let oldState = state
      oldState.data = state.data.filter(gr => gr.id !== action.Account.id)
      oldState.data.push(action.Account)
      return oldState

    case 'REMOVE_ACCOUNT':
      const Accounts = state
      let v = Accounts.data.filter(gr => gr.id !== action.Account.id)
      Accounts.data = v
      return Accounts
    case 'LOGOUT_USER':
      return []

    default:
      return state
  }
}
