import React, { Component } from 'react';
import Layout from './../../Layout';

class ActivationCode extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      userFetch: false
    };
  }

  
  render() {
    return (
      <Layout {...this.props}>
     
      </Layout>
    );
  }
}

export default ActivationCode;