import React, { Component } from 'react';
import TextField from './TextField'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import 'bootstrap-daterangepicker/daterangepicker.css';

const ranges = {
   'Today': [moment().startOf('day'), moment().endOf('day')],
   'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
   'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
   'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
   'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
   'Last Month': [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month').endOf('day')]
}
class CustomDateRangePicker extends Component {
	render() {
		return (
			<DateRangePicker containerStyles={{}} {...this.props} ranges={ranges} timePicker timePicker24Hour >
	        <TextField fullWidth label={this.props.label || 'Select Date'} value={this.props.selectedDate} />
        </DateRangePicker>)
	}
}

export default CustomDateRangePicker;