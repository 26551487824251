/*// actions 

export const updateDevices = devices => ({
  type: 'UPDATE_DEVICES',
  payload: devices
});

export const updatePositions = positions => ({
  type: 'UPDATE_POSITIONS',
  payload: positions
});

export const getPositions = positions => ({
  type: 'GET_POSITIONS',
  payload: positions
});


export const updateDeviceVisible = obj => ({
  type: 'UPDATE_DEVICE_VISIBLE',
  payload: obj
});*/

export const getDevices = devices => ({
  type: 'GET_DEVICES',
  devices
})

export const updateDevices = devices => ({
  type: 'UPDATE_DEVICES',
  devices
})
export const updateDevice = device => ({
  type: 'UPDATE_DEVICE',
  device
})


export const addDevice = devices => ({
  type: 'ADD_DEVICE',
  devices
})
export const removeDevice = device => ({
  type: 'REMOVE_DEVICE',
  device
})
export const parentUnitsLimit = limit => ({
  type: 'DEVICE_LIMIT',
  limit
})
export const addCalendars = calendars => ({
  type: 'ADD_CALENDARS',
  calendars
})
export const updateEvents = events => ({
  type: 'ADD_EVENTS',
  events
})

export const updateEventsByPing = events => ({
  type: 'ADD_EVENTS_BY_NODE',
  events
})

export const updatePositions = positions => ({
  type: 'UPDATE_POSITIONS',
  positions
})

export const showOverSpeed = obj => ({
  type: 'SHOW_OVER_SPEED',
  payload: obj.payload
})

export const addPositions = positions => ({
  type: 'ADD_POSITIONS',
  positions
})

export const setBoundApply = apply => ({
  type: 'APPLY_BOUNDED',
  payload: apply
})


export const updateDeviceVisible = obj => ({
  type: 'UPDATE_DEVICE_VISIBLE',
  payload: obj
});

export const geoFenceVisible = obj => ({
  type: 'GEO_FENSE_VISIBLE',
  payload: obj
});

export const toggleAllDevices = obj => ({
  type: 'TOGGLE_ALL_DEVICES',
  payload: obj
});

export const toggleGroupDevices = obj => ({
  type: 'TOGGLE_GROUP_DEVICES',
  payload: obj
});

export const setDeviceId = obj => ({
  type: 'ADD_DEVICE_ID',
  payload: obj
});

export const setBounds = obj => ({
  type: 'SET_BOUNDS',
  payload: obj
});

export const resetBounds = obj => ({
  type: 'RESET_BOUNDS',
  payload: obj
});

export const sortDevices = obj => ({
  type: 'SORT_DEVICES',
  payload: obj
});


export const searchDevices = obj => ({
  type: 'SEARCH_DEVICES',
  payload: obj
});

export const markerEnable = obj => ({
  type: 'MARKER_ENABLE',
  payload: obj.enable
});



export const enqueueSnackbar = notification => ({
    type: 'ENQUEUE_SNACKBAR',
    notification: {
        key: new Date().getTime() + Math.random(),
        ...notification,
    }
});

export const addNotifications = notifications => ({
    type: 'ADD_NOTIFICATIONS',
    notifications
});

export const removeSnackbar = key => ({
    type: 'REMOVE_SNACKBAR',
    key,
});

// Actions for GeoFence 
export const updateGeoFence = geofence => ({
  type: 'UPDATE_GEOFANEC',
  geofence
})

// Actions for GeoFence 
export const addGeoFence = geofence => ({
  type: 'ADD_GEOFANEC',
  geofence
})

export const deleteGeofence = geofence => ({
  type: 'DELETE_GEOFENCE',
  geofence
})

export const updateGeofenceVector = geofence => ({
  type: 'UPDATE_GEOFENSE_VECTOR',
  payload: geofence
})

export const updateGeofenceAttributes = geofence => ({
  type: 'UPDATE_GEOFENSE_ATTRIBUTES',
  payload: geofence
})

export const setTrackId = trackId => ({
  type: 'SET_TRACK_ID',
  payload: trackId
})

export const addOverspeed = events => ({
  type: 'ADD_OVERSPEED',
  events
})
export const fetchthemecolors = events => ({
  type: 'fetchthemecolors',
  events
})
