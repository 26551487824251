import React, { Component } from 'react'
import Notifications from 'react-notification-system-redux'
import TextField from '../../../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../../common/Button'
import Grid from '@material-ui/core/Grid'
import { FormHelperText } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class changedPasword extends Component {
  constructor () {
    super()
    this.state = {
      nPawsErrorMgs: false,
      form: {
        newPassword: '',
        reapetNewPassword: ''
      },
      isValidPassword:false,
      isVisableUserBtn:false,
      lengthCheck:false,
      upperCaseCheck:false,
      lowerCaseCheck:false,
      characterCheck:false,
      numberCheck:false,
      confirmPasswordCheck:false
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  formReset () {
    this.setState({
      nPawsErrorMgs: false,
      form: {
        newPassword: '',
        reapetNewPassword: ''
      }
    })
  }
  formSubmit () {
    const { selectItemId } = this.props
    let { newPassword, reapetNewPassword } = this.state.form
    if (newPassword.trim().length > 0 && reapetNewPassword.trim().length > 0) {
      if (newPassword === reapetNewPassword) {
        this.setState({
          nPawsErrorMgs: false
        })
      } else {
        this.setState({
          nPawsErrorMgs: true
        })
      }
    } else {
      this.setState({
        nPawsErrorMgs: true
      })
    }
    if (
      newPassword.trim().length > 0 &&
      reapetNewPassword.trim().length > 0 &&
      newPassword === reapetNewPassword
    ) {
      fetch(`api/users/${this.props.logInUser.id}/resetpassword`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': ' application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(
          `entityId=${selectItemId.id}&npass=${this.state.form.newPassword}`
        )
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
            if(res.status ==='success'){  
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('passwordIsChanged'),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
                this.setState({
                  form: {
                    newPassword: '',
                    reapetNewPassword: ''
                  },
                  nPawsErrorMgs: false
                })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
          } 
          else {
            throw response
          }
        })
        .catch(e => {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('somethingWentWrong'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        })
    } else {
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('emptyField'),
          autoDismiss: 5,
          location: this.props.location
        })
      )
    }
  }

  handleChange = name => event => {
    const { target } = event
    if(name === 'newPassword' || name === "reapetNewPassword"){
      let value = target.value
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.verifyPassword()
      )

    }else{
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.value
          }
        },
        () => this.checkRequiredFields()
      )
    }
    
  }
  verifyPassword = () => {
    // Define regular expressions for password criteria
    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    // Check each criteria
    const isLengthValid = this.state.form.newPassword.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(this.state.form.newPassword);
    const hasUpperCaseValid = hasUpperCase.test(this.state.form.newPassword);
    const hasNumberValid = hasNumber.test(this.state.form.newPassword);
    const hasSpecialCharValid = hasSpecialChar.test(this.state.form.newPassword);

    if(isLengthValid){
      this.setState({lengthCheck :  true})
    }else{
      this.setState({lengthCheck :false})
    }
    if(hasLowerCaseValid){
      this.setState({lowerCaseCheck:  true})
 
    }else{
      this.setState({lowerCaseCheck:false })
    }
    if(hasUpperCaseValid){
      this.setState({upperCaseCheck :  true})
    }else{
      this.setState({upperCaseCheck :false })
    }
    if(hasNumberValid){
      this.setState({ numberCheck : true})
    }else{
      this.setState({numberCheck :false })
    }
    if(hasSpecialCharValid){
      this.setState({ characterCheck : true})
    }else{
      this.setState({ characterCheck : false})
    }
    if(this.state.form.newPassword === this.state.form.reapetNewPassword){
      this.setState({ confirmPasswordCheck : true})
    }else{
      this.setState({ confirmPasswordCheck : false})
    }
    


    const isPasswordValid =
      isLengthValid && hasLowerCaseValid && hasUpperCaseValid && hasNumberValid && hasSpecialCharValid;

    this.setState({ 
      isValidPassword: isPasswordValid },
      () => this.checkRequiredFields());
  };
  checkRequiredFields () {
    let { newPassword, reapetNewPassword } = this.state.form
    let { isValidPassword } = this.state
    if (newPassword && reapetNewPassword && reapetNewPassword === newPassword && isValidPassword) {
      this.setState({
        isVisableUserBtn: true
            })
    }
         else {
          this.setState({
            isVisableUserBtn: false
          })
        }
  }

  render () {
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} sm={6}>
            <TextField
              id='newPassword'
              label={this.props.translate('newPassword')}
              type='password'
              placeholder=''
              error={this.state.nPawsErrorMgs}
              value={this.state.form.newPassword || ''}
              onChange={this.handleChange('newPassword')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
                <FormHelperText >
               <div style={{display:"flex", alignItems:"center"}}>
                {this.state.lengthCheck?
                  <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                  <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                  <span style={{marginLeft:"5px"}}>Length must be atleast 8 characters</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.numberCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>Must contain atleast one Number</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.upperCaseCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>Must contain atleast one Uppercase letter</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.lowerCaseCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>Must contain atleast one Lowercase letter</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.characterCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>Must contain atleast one Special character (!@#$%^&*(),.?":{}|<></>)</span>
                </div> 
          </FormHelperText>
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <TextField
              id='reapetNewPassword'
              error={this.state.nPawsErrorMgs}
              label={this.props.translate('reNewPassword')}
              type='password'
              placeholder=''
              value={this.state.form.reapetNewPassword || ''}
              onChange={this.handleChange('reapetNewPassword')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
             <FormHelperText >
               <div style={{display:"flex", alignItems:"center"}}>
                {this.state.confirmPasswordCheck?
                  <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                  <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                  <span style={{marginLeft:"5px"}}>Both Passwords must be Equal</span>
                </div> 
                </FormHelperText>
          </Grid>
        </Grid>

        <br />
        <Button variant='contained' onClick={() => this.formReset()}>
          {' '}
          {this.props.translate('resetButton')}
        </Button>
        <Button
          variant='contained'
          style={{ marginLeft: 8 }}
          disabled={!this.state.isVisableUserBtn}
          onClick={() => this.formSubmit()}
        >
          {this.props.translate('changePassword')}
        </Button>
      </div>
    )
  }
}
const ChangedPasword = withStyles(styles)
export default ChangedPasword(changedPasword)
