import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'

let themecolors
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors
  return { themecolors: state.themecolors }
}

const styles = theme => ({
  root: {
    backgroundColor: themecolors['500'],
    color: themecolors.themeInverse,
    '&:hover': {
      backgroundColor: themecolors['600']
    },
    '&:disabled': {
      backgroundColor: 'white'
    },
    '&.alter': {
      backgroundColor: themecolors.warning
    }
  }
})

class CustomButton extends Component {
  render () {
    const { dispatch, ...others } = this.props
    let button = ''
    if (this.props.normal) {
      button = <Button {...others} classes={null} />
    } else {
      button = <Button {...others} />
    }
    return button
  }
}

CustomButton.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(styles)(CustomButton))
