import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import Layout from './../../wrapper'
import { logInUserInfo } from '../../Actions/Users'
import { AccountModal } from '../../Components/Accounts'
import { checkPrivileges } from '../../Helpers'

class Account extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sessionCallTrack: true
    }
  }
  componentWillMount () {
    this.props.dispatch(Notifications.removeAll())
    // if (
    //   localStorage.getItem('adminToken')
    // ) {
    //   window.location.replace('/login')
    // }
  }
  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }) .then(response => {
        response.json().then(res => {
          if(res.status ==='success'){   
            this.props.history.push('/logout')
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
        })
      })
         
        
      .catch(e => {
        this.props.dispatch(Notifications.error({
          message: 'somethingWentWrong',
          autoDismiss: 5
        }))
      })
    }
  }

  render () {
    // console.log('this.props =', this.props.logInUser.userType)
    if (checkPrivileges('account')) {
      if (this.props.logInUser) {
        if (
          this.props.logInUser.userType !== 3   
        ) {
          return (
            <Layout {...this.props}>
              <AccountModal />
            </Layout>
          )
        } else {
          this.props.history.push('/users')
          return null
        }
      } else {
        if (this.state.sessionCallTrack) {
          this.setState(
            {
              sessionCallTrack: false
            },
            () => {
              fetch('/api/session', {
                headers: { Accept: 'application/json; odata=verbose' }
              }).then(response => {
                if (response.ok) {
                  response.json().then(res => {
                    if(res.status ==='success'){   
                      let userInfo = res.data
                      this.props.setActiveLanguage(
                        userInfo?.attributes?.lang || 'en'
                      )
                      //API privilige call
                      if(userInfo?.roleId){
                        fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                          method: 'Get'
                        })
                          .then(response => {
                            if (response.ok) {
                              response.json().then(res3 => {
                                if(res3.status ==='success'){
                                  let privileges = res3.data
                                  let privilegeKeys = []
                                  privileges.map(item => {
                                    privilegeKeys.push(item.key)
                                  })
                                  localStorage.setItem(
                                    'privileges',
                                    JSON.stringify({ ...privilegeKeys })
                                  )
                                  if (privileges.length) {
                                    localStorage.setItem('userToken', '')
                                    this.props.history.push('/users')
                                  } else {
                                    this.setState({
                                      loading: false,
                                      loginErrorMessage: 'lackOfPrivileges'
                                    })
                                  }
                                }
                                else if(res3?.statusCode === '440'){
                                  window.location.replace('/login')
                                }
                                else if(res3.statusCode){
                                  var err = res3?.message.split(':')
                                  err[1] =err[1].replace(')', "")
                                  this.props.dispatch(
                                    Notifications.error({
                                      message: this.props.translate(err[1]),
                                      autoDismiss: 5,
                                      location: this.props.location
                                    })
                                  )
                                }
                              })
                            } else {
                              throw response
                            }
                          })
                          .catch(e => {
                            console.log('e =',e)
                            // this.props.dispatch(Notifications.error({
                            //   message: 'somethingWentWrong',
                            //   autoDismiss: 5
                            // }))
                          })
                      }
                      //Privilige call ends
                      this.props.dispatch(logInUserInfo(userInfo))
                    }
                    else if(res?.statusCode === '440'){
                      window.location.replace('/login')
                    }
                    else if(res.statusCode){
                      var err = res?.message.split(':')
                      err[1] =err[1].replace(')', "")
                      this.props.dispatch(
                        Notifications.error({
                          message: this.props.translate(err[1]),
                          autoDismiss: 5,
                          location: this.props.location
                        })
                      )
                      this.props.history.push('/logout')
                    }
                  })
                } else {
                  this.props.history.push('/logout')
                    throw response
                  }
                })
                .catch(e => {
                  this.props.dispatch(Notifications.error({
                    message: 'somethingWentWrong',
                    autoDismiss: 5
                  }))
                })
            }
          )
        }
        return null
      }
    } else if (
      !checkPrivileges('user') &&
      !checkPrivileges('device') &&
      !checkPrivileges('group')
    ) {
      this.controleSession()
      return null
    } else {
      return defaultPages.map(page => {
        if (checkPrivileges(page)) {
          if (page === 'device') {
            this.props.history.push(`/units`)
          } else {
            this.props.history.push(`/${page}s`)
          }
        }
        return null
      })
    }
  }
}
const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withLocalize(Account))
const defaultPages = ['user', 'group', 'device']
