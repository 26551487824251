import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import Style from 'style-it'
const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themecolors
  }
}

const styles = theme => ({})

class CustomTextField extends React.Component {
  render () {
    const { classes } = this.props

    let p = { ...this.props }

    delete p.dispatch

    return (
      <Style>
        {`
				.theme-label {
					margin-top: -6px;
					font-size: 14px;
					color: #333
				}
				.theme-label-shrink {
					margin-top: 0
				}
				.theme-label-focused {
					color: #333;
					margin-top: 0;
				}
				.theme-cssOutlinedInput {
					background: ${this.props.themecolors['inputFieldBg']};
				}
				.theme-notchedOutline {
					border-color: transparent !important;					
					border-radius: 4px
				}
				.theme-cssFocused .theme-notchedOutline {
					border-color: ${this.props.themecolors[500]} !important;
				}
				.theme-textarea {
					font-size: 14px;
					padding: 0
				}
				.theme-input {
					font-size: 14px;
					padding: 10px 14px;
				}
			`}

        <div>
          <TextField
            margin='dense'
            {...p}
            variant='outlined'
            classes={classes}
            InputProps={{
              ...this.props.InputProps,
              classes: {
                root: 'theme-cssOutlinedInput',
                input: this.props.multiline ? 'theme-textarea' : 'theme-input',
                focused: 'theme-cssFocused',
                notchedOutline: 'theme-notchedOutline'
              }
            }}
            InputLabelProps={{
              ...this.props.InputLabelProps,
              classes: {
                root: 'theme-label',
                focused: 'theme-label-focused',
                shrink: 'theme-label-shrink'
              }
            }}
          />
        </div>
      </Style>
    )
  }
}

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(styles)(CustomTextField))
