import React, { Component } from 'react'
import Checkbox from '../../Components/common/Checkbox'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from './../../Components/common/TextField'
import { logInUserInfo, logout } from '../../Actions/Users'
import { ServerInfo } from '../../Actions/serverSetting'
import Button from './../../Components/common/Button'
import {
  displayErrorMessages,
  checkedDevelopmentType
} from '../../Helpers/index'
import Loader from './../../Layout/Loader'
import Typography from '@material-ui/core/Typography'
import { themecolors } from './../../Layout/theme'
import { withLocalize } from 'react-localize-redux'
import { setCookie, getCookie } from '../../Helpers'
import Notifications from 'react-notification-system-redux'
import { getPrivileges } from '../../Actions/Privileges'
import ReplayIcon from "@material-ui/icons/Replay";
import { Tooltip } from '@material-ui/core'
let themeColors

const styles = theme => {
  return {
    margin: {
      margin: theme.spacing(1)
    },
    cssRoot: {
      color: theme.palette.getContrastText(themecolors[400]),
      backgroundColor: themecolors[400],
      '&:hover': {
        backgroundColor: themecolors[600],
        color: theme.palette.getContrastText(themecolors[600])
      }
    }
  }
}

class LoginForm1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isError: false,
      isSession: false,
      loading: false,
      loginErrorMessage: '',
      captcha: this.generateCaptcha(),
      userInput: '',
      isCaptchaValid: false,
      isVisableUserBtn: false,

    }
    this.handleChange = this.handleChange.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  handleChange (event) {
    this.setState({
      [event.target.id]: event.target.value
    },()=>this.checkRequiredFields())
  }
  generateCaptcha() {
    let allCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
    'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd',
    'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
    't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    
    let generatedCaptcha = Array.from({ length: 6 }, () => {
      let randomCharacter = allCharacters[Math.floor(Math.random() * allCharacters.length)];
      return randomCharacter;
    }).join(''); // concatenate characters without spaces
  
    return generatedCaptcha;
  }
  generateRandomAlpha(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  handleRegenerateCaptcha = () => {
    this.setState({
      captcha: this.generateCaptcha(),
      userInput: '',
      isVisableUserBtn: false

    });
  };
  handleCaptchaChange = (event) => {
    this.setState({ userInput: event.target.value }
      ,()=>this.checkRequiredFields()
      );
  };

  checkRequiredFields() {
    let { email, captcha, userInput} = this.state;
    if (
      email 
      //  && captcha &&
      // userInput &&
      // captcha === userInput
      // && isCaptchaValid 
    ) {
      if(process.env.REACT_APP_CAPTCHA_ENABLE === true ){
        if (
          userInput &&
          captcha === userInput
          // && isCaptchaValid 
        ) {
          this.setState({
            isVisableUserBtn: true
          });
        }else{
          this.setState({
            isVisableUserBtn: false
          });
        }
      }
       else{
          this.setState({
            isVisableUserBtn: true
          });
      }
    } 
    else {
      this.setState({
        isVisableUserBtn: false
      });
    } 
  }
  componentWillMount () {
    this.setState({
      failed: false,
      loading: true,
      loginErrorMessage: ''
    })
    let fetchUrl = checkedDevelopmentType()
    if (localStorage.getItem('userToken')) {
      fetch(
        `/api/session/user?token=${localStorage.getItem(
          'userToken'
        )}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`,
        {
          method: 'Get'
        }
      )
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo = res.data
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
                this.props.dispatch(logInUserInfo(userInfo))
                fetch(`/api/accounts?accountId=${userInfo.accountId}`)
                  .then(response => {
                    if (response.ok) {
                      response.json().then(res1 => {
                        if(res1.status ==='success'){ 
                          let server = res1.data
                          if (server&&server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            // fetch call for Api  privileges
                            fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                              method: 'Get'
                            })
                              .then(response => {
                                if (response.ok) {
                                  response.json().then(res3 => {
                                    if(res3.status ==='success'){ 
                                      let privileges = res3.data
                                      let privilegeKeys = []
                                      privileges.map(item => {
                                        privilegeKeys.push(item.key)
                                      })
                                      localStorage.setItem(
                                        'privileges',
                                        JSON.stringify({ ...privilegeKeys })
                                      )
                                      if (privileges.length) {
                                        localStorage.setItem('userToken', '')
                                        this.props.history.push('/users')
                                      } else {
                                        this.setState({
                                          loading: false,
                                          loginErrorMessage: 'lackOfPrivileges'
                                        })
                                      }
                                    }
                                    else if(res3?.statusCode === '440'){
                                      window.location.replace('/login')
                                    }
                                    else if(res3.statusCode){
                                      var err = res3.message.split(':')
                                      err[1] =err[1].replace(')', "")
                                      this.props.dispatch(
                                        Notifications.error({
                                          message: this.props.translate(err[1]),
                                          autoDismiss: 5,
                                          location: this.props.location
                                        })
                                      )
                                    }
                                  })
                                } else {
                                  throw response
                                }
                              })
                              .catch(e => {
                                this.loginAsUsererrorMessage(e)
                              })
                          } else {
                            this.setState({
                              loading: false,
                              loginErrorMessage: 'Account Data Not Found'
                            })
                            fetch('/api/session', {
                              method: 'DELETE'
                            }).then(res => {
                              res.json().then(response=>{
                                if (response.status === 'success') {
                                  this.props.history.push('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({
                                    loading: false
                                  })
                                } 
                                else if(response.statusCode) {
                                    this.props.history.push('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({loading: false })
                                  }
                                   })
                                })
                                .catch(e => {
                                    this.props.history.push('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({ loading: false })
                              })
                          }
                        }
                        else if(res1?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                        else if(res1.statusCode){
                          this.props.history.push('/logout')
                          var err = res1?.message.split(':')
                          err[1] =err[1].replace(')', "")
                          this.props.dispatch(
                            Notifications.error({
                              message: this.props.translate(err[1]),
                              autoDismiss: 5,
                              location: this.props.location
                            })
                          )
                          this.setState({loading:false})
                        }
                      })
                    } else {
                      throw response
                    }
                  })
                  .catch(e => {
                    this.loginAsUsererrorMessage(e)
                  })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                this.errorMessage(res?.message)
                window.location.replace('/login')
              }
            })
          } else {
            this.setState({
              isSession: true
            })
            throw response
          }
        })
        .catch(e => {
          this.loginAsUsererrorMessage(e)
        })
        
    } else if (localStorage.getItem('adminToken')) {
      fetch(
        `/api/session/user?token=${localStorage.getItem(
          'adminToken'
        )}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`,
        {
          method: 'Get'
        }
      )
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo = res.data
                this.props.dispatch(logout())
                this.props.dispatch(logInUserInfo(userInfo))
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
                fetch(`/api/accounts?accountId=${userInfo.accountId}`)
                  .then(response => {
                    if (response.ok) {
                      response.json().then(res2 => {
                        if(res2.status ==='success'){ 
                          let server = res2.data
                          if (server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            // fetch call for Api  privileges
                            fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                              method: 'Get'
                            })
                              .then(response => {
                                if (response.ok) {
                                  response.json().then(res3 => {
                                    if(res3.status ==='success'){
                                      let privileges = res3.data
                                      let privilegeKeys = []
                                      privileges.map(item => {
                                        privilegeKeys.push(item.key)
                                      })
                                      localStorage.setItem(
                                        'privileges',
                                        JSON.stringify({ ...privilegeKeys })
                                      )
                                      if (privileges.length) {
                                        localStorage.setItem('adminToken', '')
                                        this.props.history.push('/users')
                                      } else {
                                        this.setState({
                                          loading: false,
                                          loginErrorMessage: 'lackOfPrivileges'
                                        })
                                      }
                                     }
                                     else if(res3?.statusCode === '440'){
                                      window.location.replace('/login')
                                    }
                                     else if(res3.statusCode){
                                      var err = res3?.message.split(':')
                                      err[1] =err[1].replace(')', "")
                                      this.props.dispatch(
                                        Notifications.error({
                                          message: this.props.translate(err[1]),
                                          autoDismiss: 5,
                                          location: this.props.location
                                        })
                                      )
                                    }
                                  })
                                } else {
                                  throw response
                                }
                              })
                              .catch(e => {
                                this.errorMessage(e)
                              })
                          } else {
                            this.setState({
                              loading: false,
                              loginErrorMessage: 'Account Data Not Found'
                            })
                            fetch('/api/session', {
                              method: 'DELETE'
                            }).then(res => {
                              res.json().then(response=>{
                                if (response.status === 'success') {
                                  this.props.history.push('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({
                                    loading: false
                                  })
                                } 
                                else if(response.statusCode) {
                                    this.props.history.push('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({loading: false })
                                  }
                                   })
                                })
                                .catch(e => {
                                    this.props.history.push('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({ loading: false })
                              })
                          }

                        }
                        else if(res2?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                        else if(res2.statusCode){
                          var err = res2?.message.split(':')
                          err[1] =err[1].replace(')', "")
                          this.props.dispatch(
                            Notifications.error({
                              message: this.props.translate(err[1]),
                              autoDismiss: 5,
                              location: this.props.location
                            })
                          )
                        }
                      })
                    } else {
                      throw response
                    }
                  })
                  .catch(e => {
                    this.errorMessage(e)
                  })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                console.log('res =', res)
                this.errorMessage(res?.message)
              }
            })
          } else {
            this.setState({
              isSession: true
            })
            throw response
          }
        })
        .catch(e => {
          console.log('e =',e)
          // this.props.dispatch(Notifications.error({
          //   message: 'somethingWentWrong',
          //   autoDismiss: 5
          // }))
        })
        // .catch(e => {
        //   if(e && e.text){
        //     e.text().then(err => {
        //       let mgs = this.props.translate(displayErrorMessages(err))
        //       this.props.dispatch(
        //         Notifications.success({
        //           message: mgs,
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //       localStorage.setItem('userToken', '')
        //       this.setState({
        //         isSession: true,
        //         loading: false
        //       })
        //     })
        //   }
        // })
    } else if (this.props.location.search) {
      let params = this.props.location.search.toString().split('&')
      if(params[3]){
        console.log('if params 3 =', params[3])
        fetch(
           `/api/session/user?token=${params[3]}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`,
           {
             method: 'Get'
           }
         )
         .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo = res.data
                localStorage.setItem('domainToken', true)
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
                this.props.dispatch(logInUserInfo(userInfo))
                fetch(`/api/accounts?accountId=${userInfo.accountId}`)
                  .then(response => {
                    if (response.ok) {
                      response.json().then(res2 => {
                        if(res2.status ==='success'){ 
                          let server = res2.data
                          if (server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            // fetch call for Api  privileges
                            fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                              method: 'Get'
                            })
                              .then(response => {
                                if (response.ok) {
                                  response.json().then(res3 => {
                                    if(res3.status ==='success'){
                                      let privileges = res3.data
                                      let privilegeKeys = []
                                      privileges.map(item => {
                                        privilegeKeys.push(item.key)
                                      })
                                      localStorage.setItem(
                                        'privileges',
                                        JSON.stringify({ ...privilegeKeys })
                                      )
                                      if (privileges.length) {
                                        this.props.history.push('/users')
                                      } else {
                                        this.setState({
                                          loading: false,
                                          loginErrorMessage: 'lackOfPrivileges'
                                        })
                                      }
                                     }
                                     else if(res3?.statusCode === '440'){
                                      window.location.replace('/login')
                                    }
                                     else if(res3.statusCode){
                                      var err = res3?.message.split(':')
                                      err[1] =err[1].replace(')', "")
                                      this.props.dispatch(
                                        Notifications.error({
                                          message: this.props.translate(err[1]),
                                          autoDismiss: 5,
                                          location: this.props.location
                                        })
                                      )
                                    }
                                  })
                                } else {
                                  throw response
                                }
                              })
                              .catch(e => {
                                this.loginAsUsererrorMessage(e)
                              })
                          } else {
                            this.setState({
                              loading: false,
                              loginErrorMessage: 'Account Data Not Found'
                            })
                            fetch('/api/session', {
                              method: 'DELETE'
                            }).then(res => {
                              res.json().then(response=>{
                                if (response.status === 'success') {
                                  this.props.history.push('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({
                                    loading: false
                                  })
                                } 
                                else if(response.statusCode) {
                                    this.props.history.push('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({loading: false })
                                  }
                                   })
                                })
                                .catch(e => {
                                    this.props.history.push('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({ loading: false })
                              })
                          }
                        }
                        else if(res2?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                        else if(res2.statusCode){
                          this.props.history.push('/login')
                          var err = res2?.message.split(':')
                          err[1] =err[1].replace(')', "")
                          this.props.dispatch(
                            Notifications.error({
                              message: this.props.translate(err[1]),
                              autoDismiss: 5,
                              location: this.props.location
                            })
                          )
                        }
                      })
                    } else {
                      throw response
                    }
                  })
                  .catch(e => {
                    this.loginAsUsererrorMessage(e)
                  })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                this.errorMessage(res?.message)
              }
            })
          } else {
            this.setState({
              isSession: true
            })
            throw response
          }
        })
        .catch(e => {
          this.loginAsUsererrorMessage(e)
        })  
      } 
      else {
        console.log('else =', params[2])
        fetch(
           `/api/session/user?token=${params[2]}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`,
           {
             method: 'Get'
           }
         )
         .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo= res.data
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
                this.props.dispatch(logInUserInfo(userInfo))
                fetch(`/api/accounts?accountId=${userInfo.accountId}`)
                  .then(response => {
                    if (response.ok) {
                      response.json().then(res2 => {
                        if(res2.status ==='success'){ 
                          let server = res2.data
                          if (server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            // fetch call for Api  privileges
                            fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                              method: 'Get'
                            })
                              .then(response => {
                                if (response.ok) {
                                  response.json().then(res3 => {
                                    if(res3.status ==='success'){
                                      let privileges = res3.data
                                      let privilegeKeys = []
                                      privileges.map(item => {
                                        privilegeKeys.push(item.key)
                                      })
                                      localStorage.setItem(
                                        'privileges',
                                        JSON.stringify({ ...privilegeKeys })
                                      )
                                      if (privileges.length) {
                                        this.props.history.push('/users')
                                      } else {
                                        this.setState({
                                          loading: false,
                                          loginErrorMessage: 'lackOfPrivileges'
                                        })
                                      }
                                     }
                                     else if(res3?.statusCode === '440'){
                                      window.location.replace('/login')
                                    }
                                     else if(res3.statusCode){
                                      var err = res3?.message.split(':')
                                      err[1] =err[1].replace(')', "")
                                      this.props.dispatch(
                                        Notifications.error({
                                          message: this.props.translate(err[1]),
                                          autoDismiss: 5,
                                          location: this.props.location
                                        })
                                      )
                                    }
                                  })
                                } else {
                                  throw response
                                }
                              })
                              .catch(e => {
                                this.loginAsUsererrorMessage(e)
                                window.location.replace('/login')
                              })
                          } else {
                            this.setState({
                              loading: false,
                              loginErrorMessage: 'Account Data Not Found'
                            })
                            fetch('/api/session', {
                              method: 'DELETE'
                            }).then(res => {
                              res.json().then(response=>{
                                if (response.status === 'success') {
                                  this.props.history.push('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({
                                    loading: false
                                  })
                                } 
                                else if(response.statusCode) {
                                    this.props.history.push('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({loading: false })
                                  }
                                   })
                                })
                                .catch(e => {
                                    this.props.history.push('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({ loading: false })
                              })
                          }
                        }
                        else if(res2?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                        else if(res2.statusCode){
                          var err = res2?.message.split(':')
                          err[1] =err[1].replace(')', "")
                          this.props.dispatch(
                            Notifications.error({
                              message: this.props.translate(err[1]),
                              autoDismiss: 5,
                              location: this.props.location
                            })
                          )
                          window.location.replace('/login')
                        }
                      })
                    } else {
                      throw response
                    }
                  })
                  .catch(e => {
                    this.loginAsUsererrorMessage(e)
                    window.location.replace('/login')
                  })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                this.errorMessage(res?.message)
                window.location.replace('/login')
              }
            })
          } else {
            this.setState({
              isSession: true
            })
            throw response
          }
        })
        .catch(e => {
          this.loginAsUsererrorMessage(e)
        })
      }
      
    } else {
      this.setState({
        isSession: true,
        loading: false,
        email: this.getCookie('userName'),
        password: this.getCookie('pass')
      })
    }
  }
  loginAsUsererrorMessage = e => {
    console.log('e =', e)
    if(e && e.text){
      e.text().then(err => {
        let mgs = this.props.translate(displayErrorMessages(err))
        this.props.dispatch(
          Notifications.success({
            message: mgs,
            autoDismiss: 5,
            location: this.props.location
          })
        )
        localStorage.setItem('userToken', '')
      })
    }
    this.props.history.push('/logout')
  }
  errorMessage = e => {
        this.setState({
          failed: true,
          password: '',
          loading: false,
          isError: true,
          loginErrorMessage: displayErrorMessages(e)
        })
  }

  handleLogin (event) {
    event.preventDefault()
    const { email, password } = this.state
    this.setState({
      loading: true
    })
    let url;
    let fetchUrl = checkedDevelopmentType()
    fetch('/api/session', {
      method: 'POST',
      body: new URLSearchParams(
        `email=${email}&password=${password}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`
      )
    })
      .then(response => {
           response.json().then(res => {
              //APIDIS@email.com
            if(res.status === 'success'){
              let userInfo = res.data
              this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
              this.props.dispatch(logInUserInfo(userInfo))
              localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
              localStorage.setItem('adminToken', '')
              fetch(`/api/accounts?accountId=${userInfo?.accountId}`)
                .then(response => {
                    response.json().then(res1 => {
                      if(res1.status ==='success'){ 
                        let server = res1.data
                        if (server) {
                          this.props.dispatch(ServerInfo(server[0]))
                          // fetch call for Api  privileges
                          if(userInfo.userType === -1){
                            url = `/api/privileges?roleId=${userInfo.roleId}&all=true`
                          }
                          else{
                            url = `/api/privileges?roleId=${userInfo.roleId}`
                          }
                          fetch(url, {
                            method: 'Get'
                          })
                            .then(response => {
                              if (response.ok) {
                                response.json().then(res2 => {
                                  if(res2.status ==='success'){ 
                                    let privileges = res2.data
                                    this.props.dispatch(getPrivileges(privileges))
                                    let privilegeKeys = []
                                    privileges.map(item => {
                                      privilegeKeys.push(item.key)
                                    })
                                    localStorage.setItem(
                                      'privileges',
                                      JSON.stringify({ ...privilegeKeys })
                                    )
                                    if (privileges.length) {
                                      this.props.history.push('/users')
                                    } else {
                                      this.setState({
                                        loading: false,
                                        loginErrorMessage: 'Account Data Not Found'
                                      })
                                      fetch('/api/session', {
                                        method: 'DELETE'
                                      }).then(res => {
                                        res.json().then(response=>{
                                          if (response.status === 'success') {
                                            this.props.history.push('/login')
                                            localStorage.setItem('privileges', '')
                                            localStorage.setItem('userInfo', '')
                                            localStorage.setItem('adminToken', '')
                                            this.props.dispatch(logout())
                                            setCookie('JSESSIONID', 0)
                                            this.setState({
                                              loading: false
                                            })
                                          } 
                                          else if(response.statusCode) {
                                              this.props.history.push('/login')
                                              localStorage.setItem('privileges', '')
                                              localStorage.setItem('userInfo', '')
                                              localStorage.setItem('adminToken', '')
                                              this.props.dispatch(logout())
                                              setCookie('JSESSIONID', 0)
                                              this.setState({loading: false })
                                            }
                                             })
                                          })
                                          .catch(e => {
                                              this.props.history.push('/login')
                                              localStorage.setItem('privileges', '')
                                              localStorage.setItem('userInfo', '')
                                              localStorage.setItem('adminToken', '')
                                              this.props.dispatch(logout())
                                              setCookie('JSESSIONID', 0)
                                              this.setState({ loading: false })
                                        })
                                    }
                                  }
                                  else if(res2?.statusCode === '440'){
                                    window.location.replace('/login')
                                  }
                                  else if(res2.statusCode){
                                    var err = res2?.message.split(':')
                                    err[1] =err[1].replace(')', "")
                                    this.props.dispatch(
                                      Notifications.error({
                                        message: this.props.translate(err[1]),
                                        autoDismiss: 5,
                                        location: this.props.location
                                      })
                                    )
                                  }
                                })
                              } else {
                                throw response
                              }
                            })
                            .catch(e => {
                              this.errorMessage(e)
                            })
                        } else {
                          this.setState({
                            loading: false,
                            loginErrorMessage: 'Account Data Not Found'
                          })
                          fetch('/api/session', {
                            method: 'DELETE'
                          }).then(res => {
                            res.json().then(response=>{
                              if (response.status === 'success') {
                                this.props.history.push('/login')
                                localStorage.setItem('privileges', '')
                                localStorage.setItem('userInfo', '')
                                localStorage.setItem('adminToken', '')
                                this.props.dispatch(logout())
                                setCookie('JSESSIONID', 0)
                                this.setState({
                                  loading: false
                                })
                              } 
                              else if(response.statusCode) {
                                  this.props.history.push('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({loading: false })
                                }
                                 })
                              })
                              .catch(e => {
                                  this.props.history.push('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({ loading: false })
                            })
                        }

                      }
                      else if(res1?.statusCode === '440'){
                        window.location.replace('/login')
                      }
                      else if(res1.statusCode){
                        var err = res1?.message.split(':')
                        err[1] =err[1].replace(')', "")
                        this.props.dispatch(
                          Notifications.error({
                            message: this.props.translate(err[1]),
                            autoDismiss: 5,
                            location: this.props.location
                          })
                        )
                      }
                    })
                  
                })
                .catch(e => {
                  this.errorMessage(e)
                })
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              this.errorMessage(res?.message)
            }
           
        })
      })
      .catch(e => {
        this.errorMessage(e)
      })
  }
  handleClick = e => {
    let { email, password } = this.state
    if (e.target.checked && email && password) {
      this.setCookie('userName', email)
      this.setCookie('pass', password)
    } else {
      this.setCookie('userName', '')
      this.setCookie('pass', '')
    }
  }
  getCookie (cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
  setCookie (name, value, exdays) {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie =
      name + '= ' + encodeURIComponent(value) + ';' + expires + ';path=/login;'
  }
  render () {
    const { classes } = this.props
    if (this.state.loading) {
      return <Loader />
    } else {
      if (this.state.isSession && !localStorage.getItem('adminToken')) {
        return (
          <form
            className='loginForm'
            onSubmit={this.handleLogin}
            autoComplete='off'
          >
            <img
              className='login-logo'
              src={this.props.whiteLabling.logo}
              alt='logo'
            />
            {this.state.failed && (
              <Typography style={{ color: themecolors['error'] }}>
                {this.props.translate(this.state.loginErrorMessage)}
              </Typography>
            )}
            <TextField
              id='email'
              label={this.props.translate('userEmail')}
              type='text'
              value={this.state.email}
              onChange={this.handleChange}
              margin='normal'
              fullWidth
              error={this.state.isError}
            />
            <div className='form-group'>
              <TextField
                id='password'
                label={this.props.translate('userPassword')}
                type='password'
                value={this.state.password}
                onChange={this.handleChange}
                margin='normal'
                fullWidth
                error={this.state.isError}
              />
            </div>

            {process.env.REACT_APP_CAPTCHA_ENABLE === true && <>

            <div style={{fontSize:12}} >
                Captcha Code:
                <div 
                  style={{
                    display:"flex",  
                    justifyContent:"space-between", 
                    alignItems:"center",
                    maxHeight:"50px"
                  }}
                >
                <p
                  style={{
                    display:"flex",  
                    justifyContent:"center", 
                    fontSize:"22px", 
                    // padding:"2px  5px",
                    fontFamily:"Henny Penny , Bradley Hand, cursive",
                    backgroundColor:"grey", 
                    borderRadius:"2px",
                    color:"dimgray", 
                    width:"85%",
                    textDecoration:"line-through",
                   userSelect: "none",

                    minHeight:"26px"
                }}
                >
                {this.state.captcha || ""}
              </p>
              <p style={{
                display:"flex",  
                justifyContent:"center", 
                padding:"2px  5px",
                backgroundColor:"#17C685", 
                borderRadius:"2px",
                width:"14%",
                alignItems:"center",
                marginLeft:"2px",
                minHeight:"26px"

            }}>
              <Tooltip title="Regenerate Captcha" >
                <ReplayIcon 
                onClick={this.handleRegenerateCaptcha}
                className="actionButton" />
              </Tooltip>
            </p>
            </div>
            </div>
            
            <div className='form-group'  style={{marginBottom:12}}>

            <TextField
              id='captcha'
              label='Enter the CAPTCHA below:'
              type='text'
              value={this.state.userInput || ''}
              onChange={this.handleCaptchaChange}
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  // borderColor: this.state.userInput === this.state.captcha ? 'green' : 'red',
                },
                classes: {
                  root: 'theme-cssOutlinedInput',
                  error: 'theme-input-error',
                  input: 'theme-input',
                  focused: 'theme-cssFocused',
                  notchedOutline: 'theme-notchedOutline',
                },
              }}
            />
            </div>
              </>}


            <div style={{ textAlign: 'center' }}>
              <Button
                id="loginSubmit"
                type='submit'
                fullWidth
                color='secondary'
                className={classNames(classes.cssRoot)}
                // disabled={!this.state.isVisableUserBtn}
               disabled={process.env.REACT_APP_CAPTCHA_ENABLE === true  ? !this.state.isVisableUserBtn : false}

              >
                {this.props.translate('loginLogin')}
              </Button>
            </div>
            <div style={{ marginRight: 'auto' }}>
              <Checkbox
                disableRipple
                onChange={event => this.handleClick(event)}
                label={this.props.translate('rememberme')}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                type="button"
                id="forgotPassword"
                style={{ marginTop: 14 }}
                className='button-white'
                onClick={e => this.props.switchView('forgot')}
              >
                {this.props.translate('forgotPassword')}
              </Button>
            </div>
          </form>
        )
      } else {
        return null
      }
    }
  }
}

// LoginForm1.propTypes = {
// 	classes: PropTypes.object.isRequired,
// };

const mapState = state => {
  themeColors = state.themeColors
  return {
    themecolors: state.themeColors
  }
}

const mapStateToProps = connect(mapState)
export const LoginForm = mapStateToProps(
  withStyles(styles)(withLocalize(LoginForm1))
)

class ForgotForm1 extends Component {
  constructor (props) {
    super(props)
    this.state = { email: '', errorMessage: '', loading: false ,
         captcha: this.generateCaptcha(),
      userInput: '',
      isCaptchaValid: false,
      isVisableUserBtn: false
    }
  }
  handleSubmit = event => {
    event.preventDefault()
    const { email } = this.state
    this.setState(
      {
        loading: true,
        errorMessage: ''
      },
      () => {
        fetch('/api/password/forget', {
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
          }),
          method: 'POST',
          body: new URLSearchParams(`email=${email}`)
        }).then(response => {
          if (response.ok) {
            response.json().then(res => {
              if (res.status === 'success') {
                this.setState({
                  loading: false,
                  errorMessage: this.props.translate('emailSentSuccessfully')
                })
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('emailSentSuccessfully'),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
               else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                  this.setState({loading:false,errorMessage:err[1]})
              }
            })
          }
          else{
            throw response
          }
        })
        .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
      }
    )
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  generateCaptcha() {
    let allCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
    'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd',
    'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
    't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    
    let generatedCaptcha = Array.from({ length: 6 }, () => {
      let randomCharacter = allCharacters[Math.floor(Math.random() * allCharacters.length)];
      return randomCharacter;
    }).join(''); // concatenate characters without spaces
  
    return generatedCaptcha;
  }
  generateRandomAlpha(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  handleRegenerateCaptcha = () => {
    this.setState({
      captcha: this.generateCaptcha(),
      userInput: '',
      isVisableUserBtn: false

    });
  };
  handleCaptchaChange = (event) => {
    this.setState({ userInput: event.target.value },()=>this.checkRequiredFields());
  };
  checkRequiredFields() {
    let { email, captcha, userInput, isCaptchaValid} = this.state;
    if (
      email 
      //  && captcha &&
      // userInput &&
      // captcha === userInput
      // && isCaptchaValid 
    ) {
      if(process.env.REACT_APP_CAPTCHA_ENABLE === true){
        if (
          userInput &&
          captcha === userInput
          // && isCaptchaValid 
        ) {
          this.setState({
            isVisableUserBtn: true
          });
        }else{
          this.setState({
            isVisableUserBtn: false
          });
        }
      }
       else{
          this.setState({
            isVisableUserBtn: true
          });
      }
    } 
    else {
      this.setState({
        isVisableUserBtn: false
      });
    } 
  }
  render () {
    let { errorMessage, loading } = this.state
    if (loading) {
      return <Loader />
    } else {
      return (
        <form
          className='loginForm'
          onSubmit={this.handleSubmit}
          autoComplete='off'
        >
          <img
            className='login-logo'
            src={this.props.whiteLabling.logo}
            alt='logo'
          />
          <Typography>
            {this.props.translate('resetPasswordMessage')}
          </Typography>
          {errorMessage && (
            <Typography
              style={{
                color:
                  errorMessage === 'Email Sent Successfully'
                    ? themecolors['success']
                    : themecolors['success']
              }}
            >
              {errorMessage}
            </Typography>
          )}
          <div className='form-group'>
            <TextField
              id='email'
              label={this.props.translate('userEmail') + ' *'}
              type='text'
              value={this.state.email || ''}
              onChange={this.handleChange}
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          {process.env.REACT_APP_CAPTCHA_ENABLE === true && <>
          <div style={{fontSize:12}} >
                Captcha Code:
                <div 
                  style={{
                    display:"flex",  
                    justifyContent:"space-between", 
                    alignItems:"center",
                    maxHeight:"50px"
                  }}
                >
                <p
                  style={{
                    display:"flex",  
                    justifyContent:"center", 
                    fontSize:"22px", 
                    // padding:"2px  5px",
                    fontFamily:"Henny Penny , Bradley Hand, cursive",
                    backgroundColor:"grey", 
                    borderRadius:"2px",
                    color:"dimgray", 
                    width:"85%",
                    textDecoration:"line-through",
                   userSelect: "none",

                    minHeight:"26px"
                }}
                >
                {this.state.captcha || ""}
              </p>
              <p style={{
                display:"flex",  
                justifyContent:"center", 
                padding:"2px  5px",
                backgroundColor:"#17C685", 
                borderRadius:"2px",
                width:"14%",
                alignItems:"center",
                marginLeft:"2px",
                minHeight:"26px"

            }}>
              <Tooltip title="Regenerate Captcha" >
                <ReplayIcon 
                onClick={this.handleRegenerateCaptcha}
                className="actionButton" />
              </Tooltip>
            </p>
            </div>
            </div>
        
          <div className='form-group'  style={{marginBottom:12}}>
              <TextField
                id='captcha'
                label='Enter the CAPTCHA below:'
                type='text'
                value={this.state.userInput || ''}
                onChange={this.handleCaptchaChange}
                margin='dense'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    // borderColor: this.state.userInput === this.state.captcha ? 'green' : 'red',
                  },
                  classes: {
                    root: 'theme-cssOutlinedInput',
                    error: 'theme-input-error',
                    input: 'theme-input',
                    focused: 'theme-cssFocused',
                    notchedOutline: 'theme-notchedOutline',
                  },
                }}
              />
          </div>
          </>}
          <p>
            <Button 
              disabled={process.env.REACT_APP_CAPTCHA_ENABLE === true  ? !this.state.isVisableUserBtn : false}
              type='submit' 
              fullWidth
              >
              {this.props.translate('submit')}
            </Button>
          </p>
          <div style={{ textAlign: 'center' }}>
            <Button
              className='button-white'
              onClick={e => this.props.switchView('login')}
            >
              {this.props.translate('loginTitle')}
            </Button>
          </div>
        </form>
      )
    }
  }
}

export const ForgotForm = mapStateToProps(withLocalize(ForgotForm1))
