import React, { Component } from 'react';
import CustomDialog from '../common/Dialog'
import {unitsList} from './unitsPortList'
import Table from '../common/tableWithBackEndPagination'
import axios from 'axios'
import TextField from '../common/TextField'
import { Grid, Tooltip } from '@material-ui/core'
import Notifications from 'react-notification-system-redux';


export default class DevicePortList extends Component {
    constructor (props) {
        super(props)
        this.state = {
            itemSearch : '',
            allList : false,
            unitsAllList: [],
            page: 1,
            pageSize: 10,
            itemSearch: '',

        }
    }
    componentWillMount () {
        this.getMoreDevices()
    }

    getMoreDevices = () => {
        const unitList = []
        let {page, pageSize, itemSearch  } = this.state
         axios({
             method: 'GET',
             url: `/api/devices/models?&page=${page}&limit=${pageSize}&search=${itemSearch}`,
             headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json'
               }
            }).then(res => {
                let response = res.data
                if(response.status==='success'){
                    if(response&&response.data&&response.data.data&&response.data.data.length){
                            response.data.data.map(item => {
                            unitList.push ({
                                name: item.name,
                                port: item.port,
                                protocol: item.protocol,
                                })
                            })
                            this.setState({
                                unitsAllList: response.data
                            })
                        }
                        else {
                            this.setState({
                                unitsAllList: []
                            })
                        }
                    }
                    else if(response?.statusCode === '440'){
                        window.location.replace('/login')
                      } 
                      else if(response?.statusCode){
                        var err = response?.message.split(':')
                        err[1] =err[1].replace(')', "")
                        this.props.dispatch(
                          Notifications.error({
                            message: this.props.translate(err[1]),
                            autoDismiss: 5,
                            location: this.props.location
                          })
                        )
                      }
                })
            }
        handleChangeRowsPerPage = value => {
            this.setState(
                {
                pageSize: value
                },
                () => this.getMoreDevices()
            )
            }
            handleChangePage = value => {
            this.setState(
                {
                page: value
                },
                () => this.getMoreDevices()
            )
            }
            SearchItem = searchValue => {
            this.setState(
                {
                page: 1,
                itemSearch: searchValue
                },
                () => this.getMoreDevices()
            )
            }
            checkFloteNumber (n) {
            return Number(n) === n && n % 1 !== 0
            }
        setPagination = items => {
            let nItem = { ...items }
            let lastPage = nItem.total / this.state.pageSize
            let IsFloate = this.checkFloteNumber(lastPage)
            delete nItem.data
            nItem.pageSize = this.state.pageSize
            nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
            return nItem
        }
              
      SearchItem = searchValue => {
        this.setState(
          {
            page: 1,
            itemSearch: searchValue
          },
          () => this.getMoreDevices()
        )
      }

  	render () {
		return (
        <CustomDialog
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.props.onCloseModal}
            isButtonVisable={false}
            draggable={true}
            CancelText={this.props.translate('sharedCancel')}
            fullWidth
            bodyPadding={0}>
            <Grid container className="breadcrumb-row" style={{background: '#00c179' }}>
              <Grid item xs={12} sm={12} style={{display:'flex', justifyContent:'center', color:'white'}} >
                   <p style={{fontSize: '18px',fontFamily: 'inherit',fontWeight: '500'}}>{this.props.translate('deviceModel')}</p>
                </Grid>
               </Grid>
            <Grid container >
                <Grid item xs={12} sm={12} >
                    <div style={{width: '100%' }} >
                        <TextField
                            fullWidth
                            label={this.props.translate('search')}
                            value={this.state.itemSearch}
                            onChange={e => this.SearchItem(e.target.value)}
                            style={{background:'#ffff'}}
                            />
                    </div>
                  </Grid>
               </Grid>

            <Table insideDialog={true}
                rows={this.state.unitsAllList?.data||[]}
                themecolors={this.props.themecolors}
                rowsPerPage={this.state.page}
                translate={this.props.translate}
                pagination={this.setPagination(this.state.unitsAllList || [])}
                handleChangeRowsPerPage={(n)=>this.handleChangeRowsPerPage(n)}
                handleChangePage={(n)=>this.handleChangePage(this.state.page+1)}
                rowDefinition={[
                    { id: 'name', numeric: false, disablePadding: false, label: this.props.translate('device') },
                    { id: 'protocol',numeric: false, disablePadding: false, label: this.props.translate('Protocol') },
                    { id: 'port',numeric: false, disablePadding: false, label: this.props.translate('Port') },
                ]} />



        </CustomDialog>
        ) 
	}
}










