import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import TextField from './../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from './../common/Checkbox'
import Grid from '@material-ui/core/Grid'
import isEqual from 'react-fast-compare'
import RefreshIcon from '@material-ui/icons/Refresh'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '../common/Button'
import { logInUserInfo } from './../../Actions/Users'
import { checkPrivileges } from '../../Helpers'
import Notifications from 'react-notification-system-redux'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class Permissions extends Component {
  constructor () {
    super()
    this.state = {
      isVisableAddBtn: false,
      deviceCount: '',
      form: {
        disabled: '',
        readonly: '',
        deviceReadonly: '',
        limitCommands: '',
        userLimit: '',
        deviceLimit: '',
        token: '',
        expirationTime: ''
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.generateToken = this.generateToken.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
  }
  componentWillMount () {
    // if (this.props.logInUser) {
    //   fetch('/api/devices/count').then(response => {
    //     if (response.ok) {
    //       response.json().then(count => {
    //         this.setState({
    //           deviceCount: count
    //         })
    //       })
    //     }
    //   })
    // }
    this.setState({
      form: { ...this.props.logInUser }
    })
  }
  formReset () {
    this.setState({
      form: { ...this.props.logInUser },
      isVisableAddBtn: false
    })
  }
  formSubmit () {
    let { logInUser } = this.props
    const { form } = this.state
    logInUser = { ...form }
    fetch(`api/users/${logInUser.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...logInUser
      })
    })
      .then(response => {
          response.json().then(res => {
            if(res.status ==='success'){   
              let user = res.data
              this.props.dispatch(logInUserInfo(user))
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('permissionsInfoIsUpdated'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            } 
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
            else {
              throw response
            }
          })
          this.setState({
            isVisableAddBtn: false
          })
        // } 
        // else {
        //   throw response
        // }
      })
      .catch(e => {
        this.props.dispatch(Notifications.error({
        message: 'somethingWentWrong',
        autoDismiss: 5
        }))
      })
  }
  handleChange = name => event => {
    if (name !== 'expirationTime') {
      const { target } = event
      let value = target.type === 'checkbox' ? target.checked : target.value
      if (target.type === 'number') {
        value = parseInt(value)
        if (value === 0) {
          value = ''
        }
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  copyTokenToClipboard = () => {
    const { form } = this.state;
    const hostUrl = this.props.ServerSetting?.attributes?.hostUrl || '';
    const token = form.token;

    const tokenUrl = `${hostUrl}/loginasuser?&${btoa(token)}`;

    // Create a temporary textarea element to copy the token URL to clipboard
    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = tokenUrl;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);

    // Show a notification indicating that the token URL has been copied to clipboard
    // this.props.enqueueSnackbar('Token URL copied to clipboard', {
    //   variant: 'success',
    //   autoHideDuration: 2000,
    // });
  };
  generateToken () {
    let symbols =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i,
      newToken = ''
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          token: newToken
        }
      },
      () => this.checkRequiredFields()
    )
  }
  checkRequiredFields = () => {
    let value = !isEqual(this.state.form, this.props.logInUser)
    this.setState({
      isVisableAddBtn: value
    })
  }

  render () {
    const { logInUser } = this.props
    return (
      <div>
        {logInUser.userType === -1 &&
          renderOwnerField(
            this.state,
            this.props,
            this.handleChange,
            this.generateToken,
            this.copyTokenToClipboard 
          )}
        {logInUser.userType !== -1 &&
          renderAdminField(
            this.state,
            this.props,
            this.handleChange,
            this.generateToken,
            this.copyTokenToClipboard ,
            this.props,
            this.state,
          )}
        <br />
        {checkPrivileges('userUpdate') && (
          <Fragment>
            <Button
              variant='contained'
              onClick={this.formReset}
              style={{ marginLeft: 8, marginRight: 8 }}
            >
              {' '}
              {this.props.translate('resetButton')}
            </Button>
            <Button
              variant='contained'
              disabled={!this.state.isVisableAddBtn}
              onClick={() => this.formSubmit()}
            >
              {' '}
              {this.props.translate('sharedSave')}
            </Button>
          </Fragment>
        )}
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(Permissions)

function renderOwnerField (state, props, handleChange, generateToken, copyTokenToClipboard) {
  const selectMe = element => {
    var doc = document,
      text = element.target,
      range,
      selection
    if (doc.body.createTextRange) {
      range = document.body.createTextRange()
      range.moveToElementText(text)
      range.select()
    } else if (window.getSelection) {
      selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(text)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='expirationTime'
            label={props.translate('userExpirationTime')}
            value={
              state.form.expirationTime
                ? new Date(`${state.form.expirationTime}`).toString()
                : null
            }
            variant='outlined'
            margin='dense'
            fullWidth
            inputProps={{
              readOnly: true,
              disabled: true
            }}
          />
        </Grid>
         <Grid item xs={12} md={3} sm={6}>
          <Checkbox
            checked={state.form.disabled}
            onChange={handleChange('disabled')}
            value='disabled'
            color='primary'
            label={props.translate('disabled')}
          />
        </Grid>
      </Grid>

      <Grid container item md={8} sm={10} xs={12}>
          <Grid item md={3} sm={4} xs={6}>
            <div style={{display: 'flex' , justifyContent: 'flex-start', alignItems: 'center'}}>
                <h4>{props.translate('UnitsLimits')} : </h4>
                <span style={{fontSize:'14px', marginLeft:10 }}>{state.form.deviceLimit} </span>
            </div>
          </Grid>

              <Grid item md={3} sm={4} xs={6}>
                <div style={{display: 'flex' , justifyContent: 'flex-start', alignItems: 'center'}}>
                  <h4>{props.translate('usersLimits')} : </h4>
                  <span style={{fontSize:'14px', marginLeft:10 }}>{state.form.userLimit}</span>
                </div>
              </Grid>
      </Grid>
            

       <Grid container item md={8} sm={10} xs={12}>
        <Grid  style={{paddingTop:'10px'}}>
          <div className='license-card clearfix'>
                <label style={{ fontSize: 10 }}>
                  {props.translate('userToken')}
                </label>
               
                <p onClick={e => selectMe(e)}>
                  <code style={{ wordBreak: 'break-all' }}>
                    {state.form.token &&
                      props.ServerSetting &&
                      props.ServerSetting.attributes &&
                      (props.ServerSetting.attributes.adminUrl +
                        '/login?&' +
                        btoa(state.form.token) ||
                        '')}
                  </code>
                </p>
                <Tooltip title={props.translate('getToken')}>
                  <Button
                   style={{marginRight:"5px"}}
                    className='pull-right button-white'
                    onClick={generateToken}
                  >
                    {<RefreshIcon />}
                  </Button>
                </Tooltip>

                <Tooltip title={props.translate('copyLink')}>
                <Button
                 style={{marginRight:"5px"}}
                  className='pull-right button-white'
                  onClick={copyTokenToClipboard}
                >
                  {<FileCopyOutlinedIcon />}
                </Button>
              </Tooltip>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  )
}

function renderAdminField (state, props, handleChange, generateToken, copyTokenToClipboard) {
  const selectMe = element => {
    var doc = document,
      text = element.target,
      range,
      selection
    if (doc.body.createTextRange) {
      range = document.body.createTextRange()
      range.moveToElementText(text)
      range.select()
    } else if (window.getSelection) {
      selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(text)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
  return (
    <Fragment>
        <Grid container spacing={2}>
        <div style={{ padding:15 }}  >
            <strong>{props.translate('device')}</strong>
                <div>
                   <p style={{fontSize:11}}> {props.translate('childLimit')}</p>
                </div>
                <div className="StatBox2">
                      <span style={{background: '#00a185'}} ><i>{props.translate('childLimit')}{'  '}</i> <b >{props.parentUnitLimits.reserveLimit} </b> </span>
                      <span style={{background: '#227fbb'}}> <i>{props.translate('childRemainigLimit')}{'  '}</i><b >  {Math.abs(props.parentUnitLimits.reserveLimit - props.parentUnitLimits.childUnitCount)} </b> </span>
                </div>
                  {/* <div className="StatBox">
                      <span style={{background: '#00a185'}}><i>{props.translate('totalOwnCreatedUnits')}{'  '}</i><b>{props.parentUnitLimits.unitCount } </b> </span>
                      <span style={{background: '#227fbb'}}><i>{props.translate('totalChildUnitsCreated')}{'  '}</i><b>{props.parentUnitLimits.childUnitCount } </b> </span>
                      <span style={{background: '#1fce6d'}}><i>{props.translate('usersUnitsTotal')}{'  '}</i><b>{props.parentUnitLimits.totalCount }  </b> </span>
                  </div> */}

                  {/* <div className="StatBox">
                      <span style={{background: '#00a185'}}><i>{props.translate('totalOwnCreatedActiveUnits')}{'  '}</i> <b>{props.parentUnitLimits.activeUnitCount } </b> </span>
                      <span style={{background: '#227fbb'}}><i>{props.translate('totalChildCreatedActiveUnits')}{'  '}</i><b>{props.parentUnitLimits.childActiveUnitCount } </b> </span>
                      <span style={{background: '#1fce6d'}}><i>{props.translate('totalIntegrated')}{'  '}</i><b>{props.parentUnitLimits.childActiveUnitCount+props.parentUnitLimits.activeUnitCount }  </b> </span>
                  </div>

                  <div className="StatBox">
                      <span style={{background: '#2b3e51'}}><i>{props.translate('usersUnitsLimit')}{'  '}</i><b>{props.parentUnitLimits.limit } </b> </span>
                      <span style={{background: '#d55400'}}><i>{props.translate('reserveUnitsLimit')}{'  '}</i><b>{props.parentUnitLimits.reserveLimit } </b> </span>
                      <span style={{background: '#1fce6d'}}><i>{props.translate('remainingUnitsLimit')}{'  '}</i><b>{props.parentUnitLimits.remaningLimit}  </b> </span>
                  </div> */}
                  <div>
                   <p style={{fontSize:11}}>{props.translate('Created')}</p>
                </div>
                <div className="StatBox2" >
                      <span style={{background: '#00a185'}}><i>{props.translate('selfCreated')}{'  '}</i> <b>{props.parentUnitLimits.unitCount} </b> </span>
                      <span style={{background: '#227fbb'}}> <i>{props.translate('childCreated')}{'  '}</i><b>{props.parentUnitLimits.childUnitCount} </b> </span>
                      <span style={{background: '#1fce6d'}}> <i>{props.translate('totalCreated')}{'  '}</i><b >  {props.parentUnitLimits.totalCount}  </b> </span>
                </div>
                <div>
                    <p style={{fontSize:11}}>{props.translate('totalLimit')}</p>
                </div>
                  <div className="StatBox2">
                      <span style={{background: '#2b3e51'}}> <i>{props.translate('totalSelfLimit')}{'  '} </i><b>{props.parentUnitLimits.limit} </b> </span>
                      <span style={{background: '#d55400'}}> <i> {props.translate('totalUnitLimit')}{'  '} </i><b>{props.parentUnitLimits.reserveLimit}</b> </span>
                      <span style={{background: '#1fce6d'}}> <i> {props.translate('totalRemaining')}{'  '} </i><b>{props.parentUnitLimits.remaningLimit}</b> </span>
                </div>
        </div>
        
        <div style={{background:'white', padding:15, borderRadius:10}}>
                <p><strong>{props.translate('users')}</strong></p>
                <div style={{marginTop:4}}>
                   <p style={{fontSize:11}}> {props.translate('childLimit')}</p>
                </div>
                <div className="StatBox2">
                  <span style={{background: '#00a185'}}><i>{props.translate('childLimit')}{'  '}</i> <b>{props.parentUserLimits.reserveLimit} </b> </span>
                  {/* <span style={{background: '#227fbb'}}> <i>{props.translate('grandChildCount')}{'  '}</i><b>{!this.state.usersLoad  ? selectedUserLimits.grandChildCount : <>...</> } </b> </span>
                  <span style={{background: '#227fbb'}}> <i>{props.translate('usersAccountTotal')}{'  '}</i><b>{!this.state.usersLoad  ? selectedUserLimits.totalCount : <>...</> } </b> </span> */}
              </div>
              <div>
                   <p style={{fontSize:11}}>{props.translate('Created')}</p>
                </div> 

                <div className="StatBox2">
                      <span style={{background: '#2b3e51'}}> <i>{props.translate('created')}{'  '}</i> <b > {props.parentUserLimits.childCount} </b> </span>
                      <span style={{background: '#d55400'}}> <i>{props.translate('grandChildCount')}{'  '}</i> <b >  {props.parentUserLimits.grandChildCount}</b> </span>
                      <span style={{background: '#1fce6d'}}> <i>{props.translate('totalCreated')}{'  '}</i> <b >  {props.parentUserLimits.totalCount}</b> </span>
                  
                </div>
                <div>
                   <p style={{fontSize:11}}>{props.translate('totalLimit')}</p>
                </div>
                <div className="StatBox2">
                      <span style={{background: '#2b3e51'}}> <i>{props.translate('limit')}{'  '}</i> <b > {props.parentUserLimits.limit } </b> </span>
                      <span style={{background: '#d55400'}}> <i>{props.translate('reserveLimit')}{'  '}</i> <b >  {props.parentUserLimits.reserveLimit+props.parentUserLimits.childCount}</b> </span>
                      <span style={{background: '#1fce6d'}}> <i>{props.translate('remaining')}{'  '}</i> <b >  {props.parentUserLimits.remaningLimit}</b> </span>
                  
                </div>
                {/* <strong>{props.translate('Users')}</strong>
              <div className="StatBox">
                      <span style={{background: '#00a185'}}><i>{props.translate('childCount')}{'  '}</i><b>{props.parentUserLimits.childCount} </b> </span>
                      <span style={{background: '#227fbb'}}><i>{props.translate('grandChildCount')}{'  '}</i><b>{props.parentUserLimits.grandChildCount} </b> </span>
                      <span style={{background: '#1fce6d'}}><i>{props.translate('usersAccountTotal')}{'  '}</i><b>{props.parentUserLimits.totalCount} </b> </span>
                  </div> */}
                
                  {/* <div className="StatBox">
                      <span style={{background: '#2b3e51'}}><i>{props.translate('usersAccountLimit')}{'  '}</i><b> {props.parentUserLimits.limit } </b> </span>
                      <span style={{background: '#d55400'}}><i>{props.translate('reserveLimit')}{'  '}</i><b>{props.parentUserLimits.reserveLimit}</b> </span>
                      <span style={{background: '#1fce6d'}}><i>{props.translate('remainingLimit')}{'  '}</i><b>{props.parentUserLimits.remaningLimit}</b> </span>
                  </div> */}
                
            </div>

          

        {/* <Grid item md={3} sm={4} xs={6}>
          <TextField
            id='userLimit'
            label={props.translate('userUserLimit')}
            placeholder=''
            value={state.form.userLimit}
            variant='outlined'
            margin='dense'
            fullWidth
            inputProps={{
              readOnly: true,
              disabled: true
            }}
          />
          <p style={{fontSize:'11px',margin: '0px', display:'flex', justifyContent:'flex-end' }}>remaining:{props.parentUserLimits.remaningLimit}</p>
        </Grid> */}
    
      </Grid>
      
      <Grid container item md={8} sm={10} xs={12}>
        <Grid  style={{paddingTop:'10px'}}>
          <div className='license-card clearfix'>
                <label style={{ fontSize: 10 }}>
                  {props.translate('userToken')}
                </label>
               
                <p onClick={e => selectMe(e)}>
                  <code style={{ wordBreak: 'break-all' }}>
                    {state.form.token &&
                      props.ServerSetting &&
                      props.ServerSetting.attributes &&
                      (props.ServerSetting.attributes.adminUrl +
                        '/login?&' +
                        btoa(state.form.token) ||
                        '')}
                  </code>
                </p>
                <Tooltip title={props.translate('getToken')}>
                  <Button
                    className='pull-right button-white'
                    onClick={generateToken}
                  >
                    {<RefreshIcon />}
                  </Button>
                </Tooltip>
                <Tooltip title={props.translate('copyLink')}>
                <Button
                 style={{marginRight:"5px"}}
                  className='pull-right button-white'
                  onClick={copyTokenToClipboard}
                >
                  {<FileCopyOutlinedIcon />}
                </Button>
              </Tooltip>
          </div>
        </Grid>
      </Grid>
   
      {/* <Grid item md={3} sm={4} xs={6}>
        {state.form.deviceLimit === -1 ? (
          <span style={{ paddingRight: 30, marginLeft: 10, paddingTop: 30 }}>
            {' '}
            {props.translate('unlimited')}
          </span>
        ) : (
          <span style={{ paddingRight: 30, marginLeft: 10, paddingTop: 30 }}>
            {' '}
            {props.translate('remaning')} {props.parentUnitLimits.remaining}
          </span>
        )}
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        {state.form.userLimit === -1 ? (
          <span style={{ paddingRight: 30, marginLeft: 10, paddingTop: 30 }}>
            {' '}
            {props.translate('unlimited')}
          </span>
        ) : (
          <span style={{ paddingRight: 30, marginLeft: 10, paddingTop: 30 }}>
            {' '}
            {props.translate('remaning')} {props.parentUserLimits.remaningLimit}
          </span>
        )}
      </Grid>
   
    */}
   </Fragment>
  )
}
