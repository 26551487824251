export const trash = (state = [], action) => {
  switch (action.type) {
    case 'GET_TRASH':
      let NewData = action.trash.data
      action.trash.data = NewData
      return action.trash

     default:
      return state
  }
}

