import React, { Component } from 'react'
import Layout from './../../wrapper'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import { ResourceList } from '../../Components/Billing/index'
import { logInUserInfo } from '../../Actions/Users'
import { checkPrivileges } from '../../Helpers'
import axios from 'axios'


let source;



class Billings extends Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 as months are zero-based
    const currentYear = currentDate.getFullYear();
  
    this.state = {
      isVisable: true,
      sessionCallTrack: true,
      selectedDate: "",
      month: currentMonth,
      year: currentYear,
      selectedAccount: '',
      loader: false,
      defaultAccountsList: [],
      itemSelected:false,
      pageSize:20,
      page:1,
      search:"",
      childData:  {},
      selectedInvoice:''

    };
  }
  componentDidMount () {
    this.props.dispatch(Notifications.removeAll())
    if(this.props.logInUser ){
      // this.fetchUsersList()
      this.getInvoices(this.props.logInUser.id)
    }
  }
  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        let response = res.data
        if (response.ok) {
          this.props.history.push('/logout')
        }else{
          throw response
        }
      }).catch(e => {})
    }
  }
  fetchUsersList = id => {
    fetch(`/api/users/list?userId=${this.props.logInUser.id}&limit=100`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(res => {
          if(res.status === 'success'){
            let data =res.data
            let result = data.data.map(item => ({
              ...item,
              label: item.label || item.name,
              value: item.id
            }))
            if (result.length === 0) {
              this.setState({
                resultMessage: 'No option found',
                defaultOption: ''
              })
            } else {
              this.setState({
                resultMessage: '',
                defaultAccountsList: result
              })
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
           else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
         
        })
      }
      else{
        throw response
      }
    }).catch(e => {})
  }

  searchList = async inputValue => {
    if (!inputValue) {
      return []
    }

    let response = await fetch(
      `/api/users/list?userId=${this.props.logInUser.id}&all=true&search=${inputValue}&limit=100`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    let json = await response.json()
    let result =
      (await json.data) && (json.data.data) &&
      json.data.data.map(item => ({
        ...item,
        label: item.label || item.name,
        value: item.id
      }))
    if (result.length === 0) {
      this.setState({
        resultMessage: 'No option found'
      })
    } else {
      this.setState({
        resultMessage: ''
      })
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: 'Search for more.'
      })
    }
    return result
  }
  handleChangeAccount = (name, value) => {
    this.setState(
      {
          [name]: value.value,
          accountLabel:value.label
      }
    )
  }
  handleChange = name => date => {
    const selectedMonth = date.getMonth() + 1; // Months are 0-indexed, so add 1
    const selectedYear = date.getFullYear();
    
      this.setState({
        selectedDate: date.toISOString(),
        month:selectedMonth,
        year:selectedYear,
      });
  }

  
  getInvoices = (userId) => {
    let { month, year, page, pageSize, search } = this.state;
    let id = this.state.itemSelected ? userId : this.props.logInUser.id;
    source = axios.CancelToken.source();
    this.setState({ loader: true }, () => {
      axios.get(`/api/invoices?userId=${id}&month=${month}&year=${year}&page=${page}&limit=${pageSize}&search=${search}`, {
        cancelToken: source.token,
      })
      .then(response => {
        if (response.status === 200) {
          let res = response.data;
          if (res.status === 'success') {
            this.setState({
              tableData: res.data || [],
              loader: false,
            });
          } else if (res?.statusCode === '440') {
            window.location.replace('/login');
          } else if (res.statusCode) {
            var err = res?.message.split(':');
            err[1] = err[1].replace(')', '');
            this.props.dispatch({
              type: 'error',
              payload: {
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location,
              },
            });
          }
        }
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          this.props.dispatch({
            type: 'error',
            payload: {
              message: 'Something went wrong',
              autoDismiss: 5,
              location: this.props.location,
            },
          });
        }
      });
    });
  };


  getItems = (item) => {
    let { page, pageSize, search } = this.state;
    source = axios.CancelToken.source();

    this.setState({ loader: true }, () => {
      axios.get(`/api/invoices/${item.id}/items?page=${page}&limit=${pageSize}&search=${search}`, {
        cancelToken: source.token,
      })
      .then(response => {
        if (response.status === 200) {
          let res = response.data;
          if (res.status === 'success') {
            this.setState({
              itemSelected: true,
              selectedInvoice: item,
              childData: res.data || [],
              loader: false,
              search: '',
            });
          } else if (res?.statusCode === '440') {
            window.location.replace('/login');
          } else if (res.statusCode) {
            var err = res?.message.split(':');
            err[1] = err[1].replace(')', '');
            this.props.dispatch({
              type: 'error',
              payload: {
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location,
              },
            });
          }
        }
      })
      .catch(error => {
        this.props.dispatch({
          type: 'error',
          payload: {
            message: 'Something went wrong',
            autoDismiss: 5,
            location: this.props.location,
          },
        });
      });
    });
  };

  // invoiceSelection = item =>{
  //   this.setState(
  //     {
  //       itemSelected:true,
  //       // childData: item.items || []
  //     },()=>this.getItems(item.id)
  //     )
  // }
  goBack = () =>{
    this.setState(
      {
        itemSelected:false,
        childData:  [],
        selectedInvoice:{},
        page:1,
        pageSize:20,
        search:""
      },
        () => {
        })
  }


  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  setPagination = () => {
    let nAcc ;
    if(this.state.itemSelected){
      nAcc = { ...this.state.childData };
    }else{
      nAcc = { ...this.state.tableData };
    }

    let lastPage = nAcc.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nAcc.data;
    nAcc.pageSize = this.state.pageSize;
    nAcc.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nAcc;
  };

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({page:1, pageSize: rowsPerPage }, () => {
      if(this.state.itemSelected){
        this.getItems(this.state.selectedInvoice);
      }else{
        this.getInvoices();
      }
    });
  };

  handleChangePage = (value) => {
    this.setState({ page: value }, () => {
      if(this.state.itemSelected){
        this.getItems(this.state.selectedInvoice);
      }else{
      this.getInvoices();
      }
    });
  };

  searchItem= (e) => {
    source.cancel();
    this.setState(
      {
        page:1,
        search: e,
      },
      () => {
        if(this.state.itemSelected){
          this.getItems(this.state.selectedInvoice);
        }else{
        this.getInvoices();
        }
      }
    );
  };
  render () {
    if (checkPrivileges('group')) {
      if (this.props.logInUser) {
        if (this.props.logInUser.userType === 6) {
          this.props.history.push('/*')
        } else {
          return (
            <Layout
            {...this.props}
            {...this.state}
          
            noSidebar={this.state.itemSelected ? true : false}
            searchList={this.searchList}
            submitForm={this.getInvoices}
            handleChangeAccount={this.handleChangeAccount}
            handleChange={this.handleChange}
            addItem={this.addItem}
            selecteItem={this.selecteItem}
            removedItem={this.removedItem}
            editItem={this.editItem}
          >
              <ResourceList
               {...this.props} 
                {...this.state}
                childData={this.state.childData}
                searchItem={this.searchItem}
                setPagination={this.setPagination}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                invoiceSelection={this.getItems}
                goBack={this.goBack}
                />
            </Layout>
          )
        }
      } else {
        if (this.state.sessionCallTrack) {
          this.setState(
            {
              sessionCallTrack: false
            },
            () => {
              fetch('/api/session', {
                headers: { Accept: 'application/json; odata=verbose' }
              }).then(response => {
                if (response.ok) {
                  response.json().then(res => {
                    if(res.status ==='success'){ 
                      let userInfo = res.data
                      this.props.setActiveLanguage(
                        userInfo?.attributes?.lang || 'en'
                      )
  
                      //API privilige call
                      fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                        method: 'Get'
                      })
                        .then(response => {
                          if (response.ok) {
                            response.json().then(res2 => {
                              if(res.status ==='success'){ 
                                let privileges = res2.data
                                let privilegeKeys = []
                                privileges.map(item => {
                                  privilegeKeys.push(item.key)
                                })
                                localStorage.setItem(
                                  'privileges',
                                  JSON.stringify({ ...privilegeKeys })
                                )
                                if (privileges.length) {
                                  localStorage.setItem('userToken', '')
                                  this.props.history.push('/billings')
                                } else {
                                  this.setState({
                                    loading: false,
                                    loginErrorMessage: 'lackOfPrivileges'
                                  })
                                }
                              }
                              else if(res?.statusCode === '440'){
                                window.location.replace('/login')
                              }
                              else if(res.statusCode){
                                var err = res?.message.split(':')
                                err[1] =err[1].replace(')', "")
                                this.props.dispatch(
                                  Notifications.error({
                                    message: this.props.translate(err[1]),
                                    autoDismiss: 5,
                                    location: this.props.location
                                  })
                                )
                              }
                            })
                          } else {
                            throw response
                          }
                        })
                        .catch(e => {
                            console.log('e =',e)
                          // this.props.dispatch(Notifications.error({
                          //   message: 'somethingWentWrong',
                          //   autoDismiss: 5
                          // }))
                        })
  
                      //Privilige call ends
  
                      this.props.dispatch(logInUserInfo(userInfo))

                    }
                    else if(res?.statusCode === '440'){
                      window.location.replace('/login')
                    }
                    else if(res.statusCode){
                      var err = res?.message.split(':')
                      err[1] =err[1].replace(')', "")
                      this.props.dispatch(
                        Notifications.error({
                          message: this.props.translate(err[1]),
                          autoDismiss: 5,
                          location: this.props.location
                        })
                      )
                      this.props.history.push('/logout')
                    }
                  })
                } else {
                  this.props.history.push('/logout')
                    throw response
                  }
                })  .catch(e => {
                  this.props.dispatch(Notifications.error({
                    message: 'somethingWentWrong',
                    autoDismiss: 5
                  }))
                })
            }
          )
        }
        return null
      }
    } else if (
      !checkPrivileges('account') &&
      !checkPrivileges('user') &&
      !checkPrivileges('device')
    ) {
      this.controleSession()
      return null
    } else {
      return defaultPages.map(page => {
        if (checkPrivileges(page)) {
          if (page === 'device') {
            this.props.history.push(`/units`)
          } else {
            this.props.history.push(`/${page}s`)
          }
        }
      })
    }
  }
}
const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  users: state.users
})

export default connect(mapStateToProps)(withLocalize(Billings))

const defaultPages = ['account', 'user', 'device']
