import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
	return {
		themecolors: state.themecolors
	}
};


const styles = theme => ({
	root: {
		fontSize: 12,
		paddingTop: 6,
		paddingBottom: 6,
	},
})

class CustomMenuItem extends Component {
	render() {
		const { classes } = this.props;
		return (
			<MenuItem {...this.props} classes={classes} />
		)
	}
}

CustomMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(CustomMenuItem));