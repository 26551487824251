import React from 'react'
import Style from 'style-it'
import Button from './../../Components/common/Button'
import CustomDialog from './../../Components/common/Dialog'
import MenuItem from './../../Components/common/MenuItem'
import {
  Grid,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  Divider
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import TextField from '../../Components/common/TextField'
import { Container, Draggable } from 'react-smooth-dnd'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { addTemplate, updateTemplate } from '../../Actions/Templates'
import Checkbox from '../../Components/common/Checkbox'
import Notifications from 'react-notification-system-redux'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip'
import { useResizeColumns } from 'react-table'

const advanceSettingsForm = {
  detail: false,
  groupBy: 'device',
}
const advanceSettingsFormFuel = {
  detail: false,
  groupBy: 'device',
  fuelSensor1:'fuel'
}
const templateFormat = (data, id) => ({
  id: id ? id : 0,
  attributes: (data && data.attributes) || {},
  description: (data && data.description) || '',
  name: (data && data.name) || '',
  parentId: (data && data.parentId) || 0
})
const tableFormat = (data, id, rpId) => ({
  id: 0,
  attributes: (data && data.attributes) || { ...advanceSettingsForm },
  rpTmplId: rpId || 0,
  // rpTmplId: (data && data.rpTmplId) || 0,
  description: (data && data.description) || '',
  name: (data && data.name) || '',
  label: (data && data.label) || '',
  position: (data && data.position) || 0,
  rpId: id ? id : 0
})
const tableFormat2 = (data, id, rpId) => ({
  id: 0,
  attributes: (data && data.attributes) || { ...advanceSettingsFormFuel },
  rpTmplId: rpId || 0,
  // rpTmplId: (data && data.rpTmplId) || 0,
  description: (data && data.description) || '',
  name: (data && data.name) || '',
  label: (data && data.label) || '',
  position: (data && data.position) || 0,
  rpId: id ? id : 0
})
const columnFormat = (data, id) => ({
  id: 0,
  rpTmplTblId: 0,
  rpFieldId: data.id ? data.id : 0,
  name: (data && data.name) || '',
  label: (data && data.label) || '',
  position: (data && data.position) || 0,
  custom: (data && data.custom) || false,
  attributes: (data && data.attributes) || {}
})

export default class ReportGenerator extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      view: 1,
      mode: 1,
      rpTmpl: templateFormat(),
      rpTmplTblWrappers: [],
      reports: [],
      templateError: false,
      allclear: false,
      rpTmplTbl: tableFormat(),
      columns: [],
      selectedColumns: [],
      editColumnModal: false,
      editColumnForm: {
        attributes :{
          width:1000
        }
      },
      saveBtnVisible:false,
      isAdvSettingEnable: false,
      visibleBtnNow:false,
      tableLength:false,
      sensorIn:false,
    }
    this.goNext = this.goNext.bind(this)
    this.validateFirstView = this.validateFirstView.bind(this)
    this.openEditColumnModal = this.openEditColumnModal.bind(this)
    this.cancelFieldModal = this.cancelFieldModal.bind(this)
    this.fieldChange = this.fieldChange.bind(this)
    this.updateField = this.updateField.bind(this)
  }

  goNext (value, edit) {
    // if(!edit){
    //   console.log('value =', value)
    //   console.log('edit =', edit)
    // // }
    if (value === 1) {
      if (!this.validateSecondView()) {
        if (edit === 2) {
          let wrapper = [...this.state.rpTmplTblWrappers]
          let rpTmplTblWrappers = []
          wrapper.map(w => {
            // if (w.rpTmplTbl.rpId === this.state.rpTmplTbl.rpId && w.editing) {
            if (w.editing) {
              delete w.editing
              rpTmplTblWrappers.push({
                rpTmplTbl: this.state.rpTmplTbl,
                rpTmplCols: this.state.selectedColumns
              })
            } else {
              rpTmplTblWrappers.push(w)
            }
          })
          this.setState({
            view: 1,
            mode: 1,
            rpTmplTblWrappers,
            selectedColumns: [],
            columns: [],
            // rpTmplTbl: tableFormat({ rpTmplId: this.state.rpTmpl.id }),
            allclear: true
          },()=>{this.checkTablesLength()})
        } else {
          let rpTmplTbl = this.state.rpTmplTbl
          if (edit === 1) {
            rpTmplTbl.rpTmplId = this.state.rpTmpl.id
          }
          this.setState({
            view: 1,
            mode: 1,
            rpTmplTblWrappers: [
              ...this.state.rpTmplTblWrappers,
              { rpTmplTbl, rpTmplCols: this.state.selectedColumns }
            ],
            selectedColumns: [],
            columns: [],
            // rpTmplTbl: tableFormat(),
            allclear: true
           }, ()=>{this.checkTablesLength()})
        }
      }
    } else if (value === 2) {
      if (!this.validateFirstView()) {
        // console.log('state =', this.state)
        // this.setState({ rpTmplTblWrappers:[], rpTmplTbl:[] },()=>{this.setState({ view: 2, rpTmplTblWrappers:[] })})
        this.setState({ view: 2 })
      }
    } else if (value === 'submit' || value === 'update') {
      let method = value === 'update' ? 'PUT' : 'POST'
      let id = value === 'update' ? '/' + this.state.rpTmpl.id : ''
      let obj = JSON.parse(JSON.stringify(this.state.rpTmplTblWrappers))
      if (obj) {
        delete obj[0]?.editing
      }
      fetch(`/api/report/templates${id}`, {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          rpTmpl: this.state.rpTmpl,
          rpTmplTblWrappers: [...obj]
        })
      })
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(template => {
                if (template.status === 'success') {
                  if (method === 'POST') {
                    this.props.dispatch(addTemplate(template.data))
                    this.setState({
                      rpTmpl: templateFormat(),
                      rpTmplTblWrappers: [],
                      allclear: false
                    })

                    this.props.dispatch(
                      Notifications.success({
                        message: this.props.translate('rptmplCreate'),
                        autoDismiss: 5,
                        location: this.props.location
                      })
                    )
                  } else {
                    this.props.dispatch(updateTemplate(template.data))
                    this.props.onUpdate()
                    this.setState({
                      rpTmpl: templateFormat(),
                      rpTmplTblWrappers: [],
                      allclear: false
                    })
                    this.props.dispatch(
                      Notifications.success({
                        message: this.props.translate('rptmplUpdate'),
                        autoDismiss: 5,
                        location: this.props.location
                      })
                    )
                  }
                }else if(template?.statusCode === '440'){
                  window.location.replace('/login')
                }
                 else if(template.statusCode){
                  var err = template?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                }
              })
              
          } else {
            throw response
          }
        })
        .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
    }
  }
  goBack = () => this.setState({view: 1})

  validateSecondView () {
    let columnError1 = false
    if (this.state.rpTmplTbl && !this.state.rpTmplTbl.rpId) {
      columnError1 = true
    }
    let columnError2 = false
    if (this.state.rpTmplTbl && !this.state.rpTmplTbl.label) {
      columnError2 = true
    }

    let columnError3 = false
    if (this.state.selectedColumns && !this.state.selectedColumns.length) {
      columnError3 = true
    }

    this.setState({ columnError1, columnError2, columnError3 })
    return columnError1 || columnError2 || columnError3
  }

  validateFirstView () {
    let templateError = false
    if (this.state.rpTmpl && !this.state.rpTmpl.name) {
      templateError = true
    }
    this.setState({ templateError })
    return templateError
  }

  templateChange = (name, event) => {
    this.setState(
      {
        rpTmpl: {
          ...this.state.rpTmpl,
          [name]: event.target.value
        }
      },
      () => this.validateFirstView()
    )
  }

  loadFields = (id, item) => {
    fetch('api/report/fields?reportId=' + id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(columns => {
          if (columns.status === 'success') {
            if (item && item.rpTmplCols && item.rpTmplCols.length) {
              let data = [...columns.data]
              let temp1 = item.rpTmplCols
              let temp2 = data
              let names = temp1.map(e => e.name)
              let cols = temp2.filter(e => !names.includes(e.name))
              let list2 = this.onSort(temp1, cols)
              let list = []
              list2.map(l => {
                let o = temp2.find(d => d.name === l.name)
                list.push(o)
              })

              this.setState(
                {
                  selectedColumns: item.rpTmplCols,
                  rpTmplTbl: item.rpTmplTbl,
                  mode: 2,
                  view: 2,
                  columns: list
                },
                () => this.validateSecondView()
              )
            } else {
              this.setState({ columns: columns.data }, () =>
                this.validateSecondView()
              )
            }
          }
          else if(columns?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(columns.statusCode){
            var err = columns?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
        })
      }else{
        throw response
      }
    }) 
    .catch(e => {
      console.log('e =',e)
      // this.props.dispatch(Notifications.error({
      //   message: 'somethingWentWrong',
      //   autoDismiss: 5
      // }))
    })
  }
  tableChange = (name, event) => {
    // if(event.target.value===4){
    //   this.setState({sensorIn:true})
    // }
    if (name === 'rpId') {
      this.setState(
        {
          selectedColumns: []
        },
        () => {
          if (this.state.reports && this.state.reports.length) {
            this.state.reports.map(r => {
              if (r.id === event.target.value) {
                if(event.target.value === 4){
                  this.setState(
                    {
                      rpTmplTbl: tableFormat2(
                        r,
                        r.id,
                        this.state.rpTmplTbl.rpTmplId
                      ),
                      columns: [],
                      selectedcolumns: '',
                      sensorIn:true
                    },
                    () => {
                      this.loadFields(r.id)
                      this.validateSecondView()
                    }
                  )
                }
                else{
                  this.setState(
                    {
                      rpTmplTbl: tableFormat(
                        r,
                        r.id,
                        this.state.rpTmplTbl.rpTmplId
                      ),
                      columns: [],
                      selectedcolumns: ''
                    },
                    () => {
                      this.loadFields(r.id)
                      this.validateSecondView()
                    }
                  )
                 }
              }
            })
          }
        }
      )
    } else {
      this.setState(
        {
          rpTmplTbl: {
            ...this.state.rpTmplTbl,
            [name]: event.target.value
          }
        },
        () => {
          this.validateSecondView()
        }
      )
    }
  }

  advanceFormChange = (event, name) => {
    if (event && event.target) {
      let { attributes } = { ...this.state.rpTmplTbl }
      let value =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
      attributes[name || event.target.id] = value
      this.setState(
        {
          rpTmplTbl: {
            ...this.state.rpTmplTbl,
            attributes: {
              ...this.state.rpTmplTbl.attributes,
              [name]: value
            }
          }
          // rpTmpl: {
          //       ...this.state.rpTmpl,
          //       attributes: {
          //         ...this.state.rpTmpl.attributes,
          //         allowFields: arr
          //       }
          //     }
        },
        () => this.validateSecondView()
      )
    }
  }
  componentWillMount () {
    fetch('api/reports?userId=' + this.props.logInUser.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(reports => {
          if (reports.status === 'success') {
            this.setState({ reports: reports.data })
          }
          else if(reports?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(reports.statusCode){
            var err = reports?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
        })
      }
      else{
        throw response
      }
    })
    .catch(e => {
      console.log('e =',e)
      // this.props.dispatch(Notifications.error({
      //   message: 'somethingWentWrong',
      //   autoDismiss: 5
      // }))
    })

    if (this.props.mode === 'update') {
      this.setDataForUpdate(this.props.item)
    }
  }
  // checkTablesLength =()=>{
  //   if(this.state?.rpTmplTblWrappers?.length>1 && !this.state?.rpTmpl?.active ){
  //     if(this.state?.rpTmplTblWrappers?.length === 1){
  //           this.setState({tableLength:false})
  //       }
  //       else{
  //         this.setState({tableLength:true})
  //       }
  //     }
  //    else{this.setState({tableLength:false})}
  // }
  checkTablesLength =()=>{
    if(this.state?.rpTmplTblWrappers?.length>1 && !this.state?.rpTmpl?.active ){
          this.setState({tableLength:true})
        }
     else{this.setState({tableLength:false})}
  }
  setDataForUpdate = item => {
    this.setState({
      rpTmpl: item.rpTmpl,
      rpTmplTblWrappers: item.rpTmplTblWrappers,
      allclear: true
    },()=>{this.checkTablesLength()})
    
  }

  insertAt = (array, index, element) => {
    array.splice(index, 0, element)
  }

  onSort = (selectedCols, arr) => {
    const result = [...arr]
    selectedCols.map(payload => {
      let addedIndex = payload.position

      this.insertAt(result, addedIndex, payload)
    })

    return result
  }

  onDrop = dragResult => {
    let arr = this.state.columns
    const { removedIndex, addedIndex, payload } = dragResult
    if (removedIndex === null && addedIndex === null) return arr

    const result = [...arr]
    let itemToAdd = payload

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0]
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd)
    }

    let selectedColumns = []
    let arr2 = [...this.state.selectedColumns]
    arr2.map(c => {
      result.map((e, i) => {
        if (c.rpFieldId === e.id) {
          let obj = { ...c, position: i }
          selectedColumns.push(obj)
        }
      })
    })
    this.setState({ columns: result, selectedColumns })
  }

  onDrop2 = dragResult => {
    let arr = this.state.rpTmplTblWrappers
    const { removedIndex, addedIndex, payload } = dragResult
    if (removedIndex === null && addedIndex === null) return arr

    const result = [...arr]
    let itemToAdd = payload

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd)
    }

    let selectedColumns = []
    result.map((e, i) => {
      let obj = { ...e, rpTmplTbl: { ...e.rpTmplTbl, position: i } }
      selectedColumns.push(obj)
    })

    this.setState({ rpTmplTblWrappers: selectedColumns })
  }

  selectTableColumn = (item, position, event) => {
    if (event && event.target) {
      if (event.target.checked === true) {
        let a = columnFormat(item)
        let selectedColumns = [...this.state.selectedColumns]
        selectedColumns.push({
          ...a,
          position,
          rpTmplTblId: this.state.rpTmplTbl.id || 0
        })
        this.setState({ selectedColumns }, () => this.validateSecondView())
      } else {
        let selectedColumns = []
        this.state.selectedColumns.map(c => {
          if (c.rpFieldId !== item.id) {
            selectedColumns.push(c)
          }
        })

        this.setState({ selectedColumns }, () => this.validateSecondView())
      }
    }
  }

  cancelFieldModal () {
    this.setState({
      editColumnModal: false,
      // editColumnForm: {}
    })
  }

  openEditColumnModal (item) {
    let selectedItem = this.state.selectedColumns.find(d => d.rpFieldId === item.id)
    this.setState({
      editColumnForm: selectedItem ? selectedItem : item,
      editColumnModal: true,
      },()=>{
        this.setState({
          editColumnForm:{
            ...this.state.editColumnForm,
            attributes:{
              width:selectedItem&&selectedItem.attributes&&selectedItem.attributes.width ? selectedItem.attributes.width : 1000
            }
          },
          }
        )
    }
    )
  }

  updateField () {
    let columns = [...this.state.columns]
    let selectedColumns = [...this.state.selectedColumns]
    columns.map(col => {
      if (col.id === this.state.editColumnForm.id) {
        col.label = this.state.editColumnForm.label
      }
    })
    selectedColumns.map(col => {
      if (col.rpFieldId === this.state.editColumnForm.rpFieldId) {
        col.label = this.state.editColumnForm.label
        col.attributes.width = this.state.editColumnForm&&this.state.editColumnForm.attributes&&this.state.editColumnForm.attributes.width
      }
    })

    this.setState({
      columns,
      selectedColumns,
      editColumnModal: false,
      editColumnForm: {}
    })
  }

  fieldChange (name, event) { 
    if(name==='width'){
        this.setState({
          editColumnForm:{
            ...this.state.editColumnForm,
            attributes: {
              ...this.state.editColumnForm.attributes,
              [name]: parseInt(event.target.value)
            }
          }
     },()=>{this.checkRequiredRptCols()})
    }
    else {
      this.setState({
        editColumnForm: {
          ...this.state.editColumnForm,
          [name]: event.target.value
        }
      })
    }
  }

  checkRequiredRptCols = () =>{
    let valid = true
    let { editColumnForm} = this.state
    if(editColumnForm&&editColumnForm.attributes&&editColumnForm.attributes.width > 999){
      this.setState({
        saveBtnVisible:true
      })
    }
    else {
      this.setState({
        saveBtnVisible:false
      })
    }
  }
  deleteTable (index) {
    if (window.confirm('Are you sure you want to delete?')) {
      let rpTmplTblWrappers = [...this.state.rpTmplTblWrappers]
      rpTmplTblWrappers.splice(index, 1)
      this.setState({ rpTmplTblWrappers },()=>{this.checkTablesLength()})
    }
  }

  editTable (item) {
    // if(item?.rpTmplTbl?.rpId === 4){
    //   this.setState(
    //     {
    //       sensorIn:true,
    //     })
    // }
    item.editing = true
    let prpObj = JSON.parse(JSON.stringify(item))
    if(prpObj && prpObj.rpTmplTbl && prpObj.rpTmplTbl.attributes){
    // if(item?.rpTmplTbl?.rpId === 4 && !item?.rpTmplTbl?.attributes?.sensorInput ){
    //   prpObj.rpTmplTbl.attributes = {
    //     ...prpObj.rpTmplTbl.attributes,
    //         sensorInput: 'fuel'
    //   }
    // }
    //   else{
        prpObj.rpTmplTbl.attributes = {
          ...prpObj.rpTmplTbl.attributes
        // }
      }
    }
    else{
      prpObj.rpTmplTbl.attributes = JSON.parse(
        JSON.stringify(advanceSettingsForm)
      )
    }
    this.loadFields(item.rpTmplTbl.rpId, prpObj)
  }

  isChecked = item => {
    let checked = false
    this.state.selectedColumns.map(col => {
      if (item.id === col.rpFieldId) {
        checked = true
      }
    })
    return checked
  }

  handleClick = () => {
    this.setState({
      isAdvSettingEnable: !this.state.isAdvSettingEnable
    })
  }

  handleChange = event => {
    const arr = []
    if (event.target.checked) {
      if (!this.state.rpTmpl.attributes.allowFields) {
        arr.push(event.target.value)
      } else if (this.state.rpTmpl.attributes.allowFields) {
        arr.push(
          ...new Set([
            ...this.state.rpTmpl.attributes.allowFields,
            event.target.value
          ])
        )
      }
    } else {
      if (this.state.rpTmpl.attributes.allowFields) {
        this.state.rpTmpl.attributes.allowFields.map(r => {
          if (r !== event.target.value) {
            arr.push(r)
          }
        })
      }
    }

    this.setState({
      rpTmpl: {
        ...this.state.rpTmpl,
        attributes: {
          ...this.state.rpTmpl.attributes,
          allowFields: arr
        }
      }
    })
  }

  render () {
    const orientation = window.innerWidth <= 1349 ? true : false
    const { view } = this.state
    const mandatoryFields = ["driver","device","group", "vehicle"]
    let currentFields = this.state.rpTmpl && this.state.rpTmpl.attributes && this.state.rpTmpl.attributes.allowFields
    return (
      <Style>
        {`
            .report-generator {
                background: #fff;
                padding: 10px 20px 20px;
                
            }
            .tab-title{
              margin:0 0 7px
            }
            .text-danger{
              color:red
            }

        `}
        <div>
          <div className='report-generator'>
            <h4 className='tab-title'>
              {view === 1
                ? this.props.translate('templateName')
                : this.props.translate('templateForm')}
            </h4>
            {view === 1 && (
              <div>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={12}>
                    <TextField
                      name='name'
                      value={this.state.rpTmpl.name || ''}
                      label={this.props.translate('templateName')}
                      error={this.state.templateError}
                      required
                      onChange={e => this.templateChange('name', e)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={9} xs={12}>
                    <TextField
                      name='description'
                      label={this.props.translate('templateDescription')}
                      value={this.state.rpTmpl.description || ''}
                      onChange={e => this.templateChange('description', e)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h4 className='tab-title'>Selection Type</h4>
                    <Checkbox
                      checked={
                        this.state.rpTmpl.attributes &&
                        this.state.rpTmpl.attributes.allowFields &&
                        this.state.rpTmpl.attributes.allowFields.includes(
                          'device'
                        )
                      }
                      value='device'
                      onChange={this.handleChange}
                      name='device'
                      label={this.props.translate('device')}
                    />
                     {/* <Checkbox
                      checked={
                        this.state.rpTmpl.attributes &&
                        this.state.rpTmpl.attributes.allowFields &&
                        this.state.rpTmpl.attributes.allowFields.includes(
                          'group'
                        )
                      }
                      value='group'
                      onChange={this.handleChange}
                      name='group'
                      label={this.props.translate('group')}
                    /> */}
                    <Checkbox
                      checked={
                        this.state.rpTmpl.attributes &&
                        this.state.rpTmpl.attributes.allowFields &&
                        this.state.rpTmpl.attributes.allowFields.includes(
                          'driver'
                        )
                      }
                      value='driver'
                      onChange={this.handleChange}
                      name='driver'
                      label={this.props.translate('driver')}
                    />
                    <Checkbox
                      checked={
                        this.state.rpTmpl.attributes &&
                        this.state.rpTmpl.attributes.allowFields &&
                        this.state.rpTmpl.attributes.allowFields.includes(
                          'vehicle'
                        )
                      }
                      value='vehicle'
                      onChange={this.handleChange}
                      name='vehicle'
                      label={this.props.translate('vehicle')}
                    />
                    {/* <Checkbox
                      checked={
                        this.state.rpTmpl.attributes &&
                        this.state.rpTmpl.attributes.allowFields &&
                        this.state.rpTmpl.attributes.allowFields.includes(
                          'eventType'
                        )
                      }
                      value='eventType'
                      onChange={this.handleChange}
                      name='eventType'
                      label={this.props.translate('reportEventType')}
                    />
                    <Checkbox
                      checked={
                        this.state.rpTmpl.attributes &&
                        this.state.rpTmpl.attributes.allowFields &&
                        this.state.rpTmpl.attributes.allowFields.includes(
                          'notification'
                        )
                      }
                      value='notification'
                      onChange={this.handleChange}
                      name='notification'
                      label={this.props.translate('sharedNotification')}
                    />
                    <Checkbox
                      checked={
                        this.state.rpTmpl.attributes &&
                        this.state.rpTmpl.attributes.allowFields &&
                        this.state.rpTmpl.attributes.allowFields.includes(
                          'geofence'
                        )
                      }
                      value='geofence'
                      onChange={this.handleChange}
                      name='geofence'
                      label={this.props.translate('sharedGeofence')}
                    />
                    <Checkbox
                      checked={
                        this.state.rpTmpl.attributes &&
                        this.state.rpTmpl.attributes.allowFields &&
                        this.state.rpTmpl.attributes.allowFields.includes(
                          'sensor'
                        )
                      }
                      value='sensor'
                      onChange={this.handleChange}
                      name='sensor'
                      label={this.props.translate('sensor')}
                    />
                    <Checkbox
                      checked={
                        this.state.rpTmpl.attributes &&
                        this.state.rpTmpl.attributes.allowFields &&
                        this.state.rpTmpl.attributes.allowFields.includes(
                          'digitalSensor'
                        )
                      }
                      value='digitalSensor'
                      onChange={this.handleChange}
                      name='digitalSensor'
                      label={this.props.translate('digitalSensor')}
                    /> */}
                  </Grid>
                </Grid>
                <p>
                  <Button 
                    // disabled={this.state.rpTmpl&&this.state.rpTmpl.attributes&&this.state.rpTmpl.attributes.allowFields ? (this.state.rpTmpl.attributes.allowFields.length ? false : true): true}
                    disabled={!this.state.visibleBtnNow}
                    onClick={e => this.goNext(2)}>
                    {this.props.translate('addTable')}
                  </Button>
                </p>
                {this.state.tableLength &&(
                  <>
                    <span style={{float:'right',color: 'white', background: 'rgb(255 0 0 / 70%)',padding: '5px 15px',borderRadius: '5px'}}>{'Template with multiple reports need approval from Service Provider'}</span>
                  </>
                )}
                {this.state.rpTmplTblWrappers &&
                this.state.rpTmplTblWrappers.length ? (
                  <h4>{this.props.translate('tablesList')}</h4>
                  ) : null}
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <List>
                      <Container onDrop={this.onDrop2} lockAxis='y'>
                        {this.state.rpTmplTblWrappers.map((item, index) => (
                          <Draggable key={item.rpTmplTbl.rpId}>
                            <MenuItem>
                              <ListItemIcon className='drag-handle'>
                                <DragHandleIcon />
                              </ListItemIcon>
                              {item.rpTmplTbl.label}
                              <ListItemSecondaryAction>
                                <ListItemIcon
                                  onClick={e => this.editTable(item)}
                                >
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemIcon
                                  onClick={e => this.deleteTable(index)}
                                >
                                  <DeleteIcon />
                                </ListItemIcon>
                              </ListItemSecondaryAction>
                            </MenuItem>
                          </Draggable>
                        ))}
                      </Container>
                    </List>
                  </Grid>
                </Grid>

                {this.props.mode === 'create' && (
                  <Button
                    disabled={!this.state.allclear}
                    onClick={e => this.goNext('submit')}
                  >
                    {this.props.translate('sharedCreate')}
                  </Button>
                )}

                {currentFields && currentFields.some(i=>mandatoryFields&&mandatoryFields.includes(i)) ? (this.setState({visibleBtnNow:true})) : (this.setState({visibleBtnNow:false}))}
                
                {this.props.mode === 'update' && (
                  <Button
                    disabled={!this.state.visibleBtnNow}
                    onClick={e => this.goNext('update')}
                  >
                    {this.props.translate('update')}
                  </Button>
                )}
              </div>
            )}
            {view === 2 && (
              <div>
                  
                <Grid container spacing={2} >
                  <Grid item xs={12}>
                    <p>
                      <strong> {this.props.translate('templateName')}:</strong>{' '}
                      {this.state.rpTmpl.name}
                    </p>
                    <strong>
                      {this.props.translate('templateDescription')}:
                    </strong>{' '}
                    {this.state.rpTmpl.description}
                  </Grid>
                </Grid>
                <Divider style={{ margin: '16px 0' }} />
                      <Button size='small' style={{float:'right'}} onClick={e => this.goBack()}>{this.props.translate('sharedBack')}</Button>
                <h4>{this.props.translate('tableForm')}</h4>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={12}>
                    <TextField
                      name='rpId'
                      label={this.props.translate('selectReport')}
                      value={this.state.rpTmplTbl.rpId}
                      error={this.state.columnError1}
                      required
                      onChange={e => this.tableChange('rpId', e)}
                      select
                      fullWidth
                    >
                      {this.state.reports.map(report => (
                        <MenuItem key={report.id} value={report.id}>
                          {this.props.translate(report.name)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <TextField
                      name='label'
                      label={this.props.translate('tableLabel')}
                      required
                      value={this.state.rpTmplTbl.label}
                      error={this.state.columnError2}
                      onChange={e => this.tableChange('label', e)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      name='description'
                      label={this.props.translate('tableDescription')}
                      value={this.state.rpTmplTbl.description}
                      onChange={e => this.tableChange('description', e)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <TextField
                      name='groupBy'
                      label={this.props.translate('groupBy')}
                      value={
                        this.state.rpTmplTbl &&
                        this.state.rpTmplTbl.attributes &&
                        this.state.rpTmplTbl.attributes.groupBy
                      }
                      required
                      onChange={e => this.advanceFormChange(e, 'groupBy')}
                      select
                      fullWidth
                    >
                      <MenuItem key={'device'} value={'device'}>
                        {this.props.translate('device')}
                      </MenuItem>
                      {(this.state.rpTmplTbl.name !== 'Stop')
                       && (this.state.rpTmplTbl.name !== 'Maintenance' ) ? (
                        <MenuItem key={'driver'} value={'driver'}>
                          {this.props.translate('driver')}
                        </MenuItem>
                      ) : null}
                      {this.state.rpTmplTbl.name !== 'Maintenance' ? (
                      <MenuItem key={'date'} value={'date'}>
                        {this.props.translate('date')}
                      </MenuItem>) : null}
                    </TextField>
                  </Grid>
                 {/* {(this.state.sensorIn ||this.state.rpTmplTbl.attributes?.sensorInput) ? (
                    <Grid item sm={3} xs={12}>
                    <TextField
                      name='sensorInput'
                      label='sensorInput'
                      value={this.state.rpTmplTbl &&
                        this.state.rpTmplTbl.attributes &&
                        this.state.rpTmplTbl.attributes.sensorInput?this.state.rpTmplTbl.attributes.sensorInput:'fuel' }
                      onChange={e => this.advanceFormChange(e, 'sensorInput')}
                      fullWidth
                    />
                  </Grid>): null} */}
                  {(this.state.rpTmplTbl.rpId === 4 || 
                    this.state.rpTmplTbl.rpId === 1 ||
                    this.state.rpTmplTbl.rpId === 14 ||
                    this.state.rpTmplTbl.rpId === 3) &&(
                    <Grid item sm={3} xs={12}>
                    <TextField
                      name='fuelSensor1'
                      label={this.props.translate('fuelSensor1')}
                      value={this.state.rpTmplTbl &&
                        this.state.rpTmplTbl.attributes &&
                        this.state.rpTmplTbl.attributes.fuelSensor1?this.state.rpTmplTbl.attributes.fuelSensor1:'fuel'}
                      onChange={e => this.advanceFormChange(e, 'fuelSensor1')}
                      fullWidth
                    />
                  </Grid>)}
                  {(this.state.rpTmplTbl.rpId===1 || this.state.rpTmplTbl.rpId===14) &&(
                    <Grid item sm={3} xs={12}>
                    <TextField
                      name='fuelSensor2'
                      label={this.props.translate('fuelSensor2')}
                      value={this.state.rpTmplTbl &&
                        this.state.rpTmplTbl.attributes &&
                        this.state.rpTmplTbl.attributes.fuelSensor2?this.state.rpTmplTbl.attributes.fuelSensor2:'fuel'}
                      onChange={e => this.advanceFormChange(e, 'fuelSensor2')}
                      fullWidth
                    />
                  </Grid>)}
                  {/* {this.state.rpTmplTbl.rpId === 1 &&(
                    <Grid item sm={3} xs={12}>
                    <TextField
                      name='sensorInput2'
                      label={this.props.translate('sensorInput2')}
                      value={this.state.rpTmplTbl &&
                        this.state.rpTmplTbl.attributes &&
                        this.state.rpTmplTbl.attributes.sensorInput2?this.state.rpTmplTbl.attributes.sensorInput2:''}
                      onChange={e => this.advanceFormChange(e, 'sensorInput2')}
                      fullWidth
                    />
                  </Grid>)} */}
                  {(this.state.rpTmplTbl.rpId === 3) &&(
                  <Grid item sm={3} xs={12}>
                  <TextField
                    name='sensorInput'
                    label={this.props.translate('sensorInput')}
                    value={this.state.rpTmplTbl &&
                      this.state.rpTmplTbl.attributes &&
                      this.state.rpTmplTbl.attributes.intervalSensor?this.state.rpTmplTbl.attributes.intervalSensor:'work'}
                    required
                    onChange={e => this.advanceFormChange(e, 'intervalSensor')}
                    select
                    fullWidth
                  >
                    <MenuItem key={'motion'} value={'motion'}>
                      {this.props.translate('motion')}
                    </MenuItem>
                    <MenuItem key={'workMode'} value={'workMode'}>
                      {this.props.translate('work')}
                    </MenuItem>
                    
                  </TextField>
                </Grid>)}
                 {this.state.rpTmplTbl && this.state.rpTmplTbl.name === 'Event' ?  (<>
                              <Checkbox
                                  checked={
                                    this.state.rpTmpl.attributes &&
                                    this.state.rpTmpl.attributes.allowFields &&
                                    this.state.rpTmpl.attributes.allowFields.includes(
                                      'eventType'
                                    )
                                  }
                                  value='eventType'
                                  onChange={this.handleChange}
                                  name='eventType'
                                  label={this.props.translate('reportEventType')}
                              />
                            <Checkbox
                                checked={
                                  this.state.rpTmpl.attributes &&
                                  this.state.rpTmpl.attributes.allowFields &&
                                  this.state.rpTmpl.attributes.allowFields.includes(
                                    'notification'
                                  )
                                }
                                value='notification'
                                onChange={this.handleChange}
                                name='notification'
                                label={this.props.translate('sharedNotification')}
                            />
                         </> )
                       : this.state.rpTmplTbl && this.state.rpTmplTbl.name === 'Geofence' ? (<>
                                <Checkbox
                                    checked={
                                      this.state.rpTmpl.attributes &&
                                      this.state.rpTmpl.attributes.allowFields &&
                                      this.state.rpTmpl.attributes.allowFields.includes(
                                        'geofence'
                                      )
                                    }
                                    value='geofence'
                                    onChange={this.handleChange}
                                    name='geofence'
                                    label={this.props.translate('sharedGeofence')}
                                />
                       </>)
                       :  this.state.rpTmplTbl && this.state.rpTmplTbl.name === 'SensorTracing' ?
                            (<> 
                                 <Checkbox
                                      checked={
                                        this.state.rpTmpl.attributes &&
                                        this.state.rpTmpl.attributes.allowFields &&
                                        this.state.rpTmpl.attributes.allowFields.includes(
                                          'sensor'
                                        )
                                      }
                                      value='sensor'
                                      onChange={this.handleChange}
                                      name='sensor'
                                      label={this.props.translate('sensor')}
                                  />
                                </>)
                        :  this.state.rpTmplTbl &&  this.state.rpTmplTbl.name === 'Digital Sensor' ?
                              (<>    <Checkbox
                                      checked={
                                        this.state.rpTmpl.attributes &&
                                        this.state.rpTmpl.attributes.allowFields &&
                                        this.state.rpTmpl.attributes.allowFields.includes(
                                          'digitalSensor'
                                        )
                                      }
                                      value='digitalSensor'
                                      onChange={this.handleChange}
                                      name='digitalSensor'
                                      label={this.props.translate('digitalSensor')}
                                  />
                            </>)
                        : null
                    } 
                  <Grid item xs={12} sm={6} md={4} lg={3} style={{marginLeft: 5}}>
                  <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Checkbox
                      id='detail'
                      onChange={e => this.advanceFormChange(e, 'detail')}
                      checked={
                       this.state.rpTmplTbl.attributes &&
                            this.state.rpTmplTbl.attributes.detail 
                      }
                      label={this.props.translate('detailReport')}
                    />
                     <Tooltip 
                        classes={{
                          popper: 'menu-popper',
                          tooltip: 'menu-popper-tooltip'
                        }}
                        title={this.props.translate('DetailsTooltip')}>
                        <HelpOutlineIcon style={{color:'#00C179', fontSize:15, marginTop:12, marginRight:15}}/>
                      </Tooltip>
                      </div>
                  </Grid>
                </Grid>
                {this.state.columns &&
                this.state.columns.length &&
                this.state.columnError3 ? (
                  <p className='text-danger'>
                    {this.props.translate(
                      'pleaseSelectAtleaseOneColumnFromAboveList'
                    )}
                  </p>
                ) : null}
                {this.state && this.state.columns && this.state.columns.length ? (<Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <List className='table-fields-list'>
                      <Container onDrop={this.onDrop} lockAxis='y'>
                        {this.state && this.state.columns && this.state.columns.length && this.state.columns.map((item, index) => {
                         if (!item) {
                          return null; // Skip rendering if item is undefined
                        } 
                          return(
                          <Draggable key={item.id}>
                            <MenuItem selected={this.isChecked(item)}>
                              <ListItemIcon className='drag-handle'>
                                <DragHandleIcon />
                              </ListItemIcon>
                              <ListItemIcon style={{ marginRight: 0 }}>
                                <Checkbox
                                  checked={this.isChecked(item) || false}
                                  onChange={e =>
                                    this.selectTableColumn(item, index, e)
                                  }
                                />
                              </ListItemIcon>
                              {item.label}{' '}
                              {item.description ? (
                                <span style={{ color: '#999', marginLeft: 10 }}>
                                  ({item.description})
                                </span>
                              ) : (
                                ''
                              )}
                              <ListItemSecondaryAction
                                onClick={e => this.openEditColumnModal(item)}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                              </ListItemSecondaryAction>
                            </MenuItem>
                          </Draggable>
                        )})}
                      </Container>
                    </List>
                  </Grid>
                </Grid>
                ) : null }
                <Button onClick={e => this.goNext(1, this.state.mode)}>
                  {this.props.translate('next')}
                </Button>
              </div>
            )}
            {this.state.editColumnModal && (
              <CustomDialog
                title={this.props.translate('modifyLabel')}
                bodyPadding={20}
                isVisableBtn
                fullWidth
                headerActions={<Button disabled={!this.state.saveBtnVisible} onClick={this.updateField}>Save</Button>}
                onClose={this.cancelFieldModal}
              >
                <TextField
                  name='label'
                  label={this.props.translate('label')}
                  required
                  value={this.state.editColumnForm&&this.state.editColumnForm.label}
                  onChange={e => this.fieldChange('label', e)}
                  fullWidth
                />
                 <TextField
                  name='label'
                  label={this.props.translate('columnWidth')}
                  required
                  value={this.state.editColumnForm&&this.state.editColumnForm.attributes&&this.state.editColumnForm.attributes.width} 
                  onChange={e => this.fieldChange('width', e)}
                  fullWidth
                  type='number'
                  error={this.state.editColumnForm&&this.state.editColumnForm.attributes&&this.state.editColumnForm.attributes.width > 999 ? false : true }
                  helperText='Enter Value greater than 999'
                />
              </CustomDialog>
            )}
          </div>
        </div>
      </Style>
    )
  }
}

const headingStyle = {
  fontSize: 14,
  padding: 0,
  margin: '10px 0 5px',
  fontWeight: 700,
  textTransform: 'capitalize',
  color: 'rgba(0, 0, 0, 0.54)',
  cursor: 'pointer'
}
