export const getTemplates = templates => ({
  type: 'GET_TEMPLATES',
  templates
})
export const updateTemplates = templates => ({
  type: 'UPDATE_TEMPLATES',
  templates
});
export const updateTemplate = template => ({
  type: 'UPDATE_TEMPLATE',
  template
});
export const addTemplate = template => ({
  type: 'ADD_TEMPLATE',
  template
});

export const removeTemplate = template => ({
  type: 'REMOVE_TEMPLATE',
  template
});
