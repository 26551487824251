export const coupon = (state = [], action) => {
  switch (action.type) {
    case 'GET_COUPON':
      let NewData = action.coupon.data
      action.coupon.data = NewData
      return action.coupon

     default:
      return state
  }
}

