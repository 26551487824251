export const sideBar = (state = null, action) => {
    switch (action.type) {
      case 'GET_SIDEBAR':
        let NewData = action.sideBar
        action.sideBar = NewData
        return action.sideBar
  
       default:
        return state
    }
  }
  
  