import { createMuiTheme, Grid, MuiThemeProvider } from '@material-ui/core'
import React, { PureComponent } from 'react'
import { Translate } from 'react-localize-redux'
import Button from '../common/Button'
import TextField from '../common/TextField'
import 'moment/moment.js'
import moment from "moment-hijri";
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import { updateAccount } from '../../Actions/Accounts'
import { ServerInfo } from '../../Actions/serverSetting'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LinkOff from '@material-ui/icons/LinkOff';
import { Api } from '../../Helpers'

function MDatePicker ({handleChange, value, name, error, onBlur}) {
    
    return <MuiPickersUtilsProvider  utils={DateFnsUtils}>
    <DatePicker
      error={error}
      onBlur={onBlur}
      margin='dense'
      label={<Translate id={name} />}
      variant='outlined'
      fullWidth
    //   minDate={new Date()}
      format="yyyy-MM-dd"
      value={
        value
          ? new Date(
              `${value}`
            ).toString()
          : null
      }
      onChange={e => handleChange(e, name)}
      TextFieldComponent={TextField}
    />
  </MuiPickersUtilsProvider>
}


export default class Wasl extends PureComponent {
    constructor(props) {
        super(props)
        const {
            identityNumber,
            commercialRecordNumber,
            commercialRecordIssueDateHijri,
            dateOfBirthHijri,
            commercialRecordIssueDate,
            phoneNumber,
            extensionNumber,
            emailAddress,
            managerName,
            managerPhoneNumber,
            managerMobileNumber
        }  = props.selectedItem?.attributes?.wasl || {}
        this.state = {
            identityNumber: identityNumber || "",
            commercialRecordNumber: commercialRecordNumber || "",
            commercialRecordIssueDateHijri: commercialRecordIssueDateHijri || "",
            dateOfBirthHijri: dateOfBirthHijri || "",
            commercialRecordIssueDate: commercialRecordIssueDate || "",
            dateOfBirth:  "",
            phoneNumber: phoneNumber || "",
            extensionNumber: extensionNumber || "",
            emailAddress: emailAddress || "",
            managerName: managerName || "",
            managerPhoneNumber: managerPhoneNumber || "",
            managerMobileNumber: managerMobileNumber || "",
            activity: "DEFAULT",
            identityNumberError: false,
            commercialRecordNumberError: false,
            commercialRecordIssueDateError: false,
            dateOfBirthError: false,
            phoneNumberError: false,
            extensionNumberError: false,
            emailAddressError: false,
            managerNameError: false,
            managerPhoneNumberError: false,
            managerMobileNumberError: false,
        }
    }
    onChange = (event, name) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        this.setState({
            [name]: value
        })
    }

    submit = (data) => {
        let url = `${Api}handleSocketRequest`
        let hook = 'registerCompany'
        if(this.props.registrationType === 'individual'){
            hook = 'registerIndividual'
        }

        axios.post(url,{
            hook: hook,
            account_id: this.props.selectedItem.id,
            data: JSON.stringify(data.wasl)
        })
        .then(response => {
            if(response.status === 200 && response.data && response.data.success) {
                if(response.data.result && response.data.result.referenceKey) {
                    let obj = {
                        ...this.props.selectedItem,
                      attributes: {
                          ...this.props.selectedItem.attributes,
                          ...data,
                          waslCompanyRefKey: response.data.result.referenceKey
                      }
                    }
                    
                    fetch(`/api/accounts/${this.props.selectedItem.id}`, {
                        method: 'PUT',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...obj
                        })
                    })
                    .then(response => {
                        if (response.ok) {
                          response.json().then(res => {
                            let account = res.data
                            if(res.status === 'success'){
                              this.props.UpdateSelectedItem(account)
                              this.props.dispatch(updateAccount(account))
                              this.props.dispatch(
                                Notifications.success({
                                  message: this.props.translate('accountUpdated'),
                                  autoDismiss: 5,
                                  location: this.props.location
                                })
                              )
                              this.setState({
                                form: { ...account }
                              })
                              if(this.props.selectedItem?.id === 1){
                                this.props.dispatch(ServerInfo(account))
                            }
                            }
                            else if(res?.statusCode === '440'){
                              window.location.replace('/login')
                            }
                            else if(res.statusCode){
                              var err = res?.message.split(':')
                              err[1] =err[1].replace(')', "")
                              this.props.dispatch(
                                Notifications.error({
                                  message: this.props.translate(err[1]),
                                  autoDismiss: 5,
                                  location: this.props.location
                                })
                              )
                            }
                          })
                        } 
                      })
                    
                    .catch(e => {
                        this.props.dispatch(
                            Notifications.error({
                                message: 'someThing went wrong',
                                autoDismiss: 5,
                                location: this.props.location
                            })
                        )
                    })

                }
            }
        }).catch(error => {
            console.log(error)
            let res = error?.response?.data || {}
            this.props.dispatch(
                Notifications.error({
                    message: error?.response?.data?.message ||  'Bad Request',
                    autoDismiss: 5,
                    location: this.props.location
                })
            )
            let check = res&&res.data&&res.data.result
                if(check&& check.referenceKey && check.isValid){
                    let obj = {
                        ...this.props.selectedItem,
                      attributes: {
                          ...this.props.selectedItem.attributes,
                          ...data,
                          waslCompanyRefKey: res.data.result.referenceKey
                      }
                    }
                    fetch(`/api/accounts/${this.props.selectedItem.id}`, {
                        method: 'PUT',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...obj
                        })
                    })
                    .then(response => {
                        if (response.ok) {
                          response.json().then(res => {
                            let account = res.data
                            if(res.status === 'success'){
                              this.props.UpdateSelectedItem(account)
                              this.props.dispatch(updateAccount(account))
                              this.setState({
                                form: { ...account }
                              })
                              if(this.props.selectedItem?.id === 1){
                                this.props.dispatch(ServerInfo(account))
                            }
                            }
                            else if(res?.statusCode === '440'){
                              window.location.replace('/login')
                            }
                          })
                        } 
                      })
                    .catch(e => {console.log('e=',e)})
                }
        })

      }
      

    handleChange = (value, name) => {
        if(name === 'dateOfBirth'){
            this.setState({
                [name]: value,
                dateOfBirthHijri: moment(value).format('iYYYY-iMM-iDD'),
                dateOfBirthError: false
            })
        }else{
            this.setState({
                [name]: value,
                commercialRecordIssueDateHijri: moment(value).format('iYYYY-iMM-iDD'),
                commercialRecordIssueDateError: false
            })
        }    
    }
    
    checkValid = (a,b) => {
        this.setState({
            [a.target.id+'Error']: !a.target.value
        })
    }
    submitIndividual = ()=>{
        const {
            identityNumber,
            dateOfBirthHijri,
            dateOfBirth,
            phoneNumber,
            extensionNumber,
            emailAddress,
            activity,
        } = this.state
        if(identityNumber && dateOfBirthHijri && phoneNumber && emailAddress && extensionNumber) {
            this.submit({
                wasl: {
                    identityNumber,
                    dateOfBirthHijri,
                    phoneNumber,
                    emailAddress,
                    phoneNumber,
                    extensionNumber: extensionNumber || "",
                    activity
                }
                    })
                }
                else {
                    this.setState({
                        identityNumberError: !identityNumber,
                        dateOfBirthError: !dateOfBirth,
                        phoneNumberError: !phoneNumber,
                        emailAddressError: !emailAddress,
                    })
                }

    }
    submitCompany = () => {
        if(this.props.selectedItem && this.props.selectedItem.attributes && this.props.selectedItem.attributes.waslApiKey) {
            const {
                identityNumber,
                commercialRecordNumber,
                commercialRecordIssueDate,
                commercialRecordIssueDateHijri,
                phoneNumber,
                extensionNumber,
                emailAddress,
                activity,
                managerName,
                managerPhoneNumber,
                managerMobileNumber
            } = this.state
    
            if(identityNumber && commercialRecordNumber && commercialRecordIssueDateHijri && phoneNumber && emailAddress && managerName && managerPhoneNumber && managerMobileNumber) {
                this.submit({
                    wasl: {
                        identityNumber,
                        commercialRecordNumber,
                        commercialRecordIssueDate,
                        commercialRecordIssueDateHijri,
                        phoneNumber,
                        extensionNumber: extensionNumber || "",
                        emailAddress,
                        activity,
                        managerName,
                        managerPhoneNumber,
                        managerMobileNumber
                    }
                })
            }
            else {
                this.setState({
                    identityNumberError: !identityNumber,
                    commercialRecordNumberError: !commercialRecordNumber,
                    commercialRecordIssueDateError: !commercialRecordIssueDate,
                    phoneNumberError: !phoneNumber,
                    emailAddressError: !emailAddress,
                    managerNameError: !managerName,
                    managerPhoneNumberError: !managerPhoneNumber,
                    managerMobileNumberError: !managerMobileNumber,
                })
            }
        } else {
            this.props.dispatch(
                Notifications.error({
                    message: <Translate id='wasl api key is missing' />,
                    autoDismiss: 5,
                    location: this.props.location
                })
            )
        }
    }
    unLinkCompany =()=>{
            let accId= this.props.selectedItem&&this.props.selectedItem.id
            let waslCompanyRefKey = this.props.selectedItem&&this.props.selectedItem.attributes&&this.props.selectedItem.attributes.waslCompanyRefKey
            axios.post(`${Api}/handleSocketRequest`,{
                hook: 'deleteCompany',
                account_id: accId,
                hook_prefix: waslCompanyRefKey,
                data: {activity: 'DEFAULT'}
            })
            .then(response=>{
                if(response.status === 200 && response.data && response.data.success) {
                let obj = {
                    ...this.props.selectedItem,
                  attributes: {
                      ...this.props.selectedItem.attributes,
                      waslCompanyRefKey: '',
                      waslApiKey:false,
                      waslEnabled:false,
                      wasl:{}
                  }
                }
                fetch(`/api/accounts/${this.props.selectedItem.id}`, {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...obj
                    })
                })
                .then(response => {
                    if (response.ok) {
                      response.json().then(res => {
                        let account = res.data
                        if(res.status === 'success'){
                          this.props.UpdateSelectedItem(account)
                          this.props.dispatch(updateAccount(account))
                          this.setState({
                            form: { ...account }
                          })
                          if(this.props.selectedItem?.id === 1){
                            this.props.dispatch(ServerInfo(account))
                        }
                        }
                        else if(res?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                      })
                    } 
                  })
                .catch(e => {console.log('e=',e)})
                }
            })
            .catch(error =>{console.log('error ', error)})
    }
    render() {
        const { identityNumberError,
            commercialRecordNumberError,
            commercialRecordIssueDateError,
            dateOfBirthError,
            phoneNumberError,
            emailAddressError,
            managerNameError,
            managerPhoneNumberError,
            managerMobileNumberError
            } = this.state
        return <div>
            <h4 className="mt-0">Update/Create Company for Wasl</h4>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField onBlur={this.checkValid} fullWidth  error={identityNumberError} id="identityNumber" label={<Translate id="identityNumber" />} value={this.state.identityNumber} onChange={e => this.onChange(e, 'identityNumber')} />
                </Grid>
                {this.props.registrationType === 'company'?
                    (<Grid item xs={12} sm={6} md={3}>
                        <TextField fullWidth onBlur={this.checkValid} id="commercialRecordNumber" error={commercialRecordNumberError} label={<Translate id="commercialRecordNumber" />} value={this.state.commercialRecordNumber} onChange={e => this.onChange(e, 'commercialRecordNumber')} />
                    </Grid>)
                :null}
                 {this.props.registrationType === 'individual'?
                    (<Grid item xs={12} sm={6} md={3}>
                      <MDatePicker handleChange={this.handleChange} value={this.state.dateOfBirth} error={dateOfBirthError} name="dateOfBirth"  />
                        {this.state.dateOfBirthHijri && dateOfBirthError ? <small>Hijri Date: <strong>{this.state.dateOfBirthHijri}</strong></small> : null}
                     </Grid>)
                :null}

                {this.props.registrationType === 'company'?
                    (<Grid item xs={12} sm={6} md={3}>
                      <MDatePicker handleChange={this.handleChange} value={this.state.commercialRecordIssueDate} error={commercialRecordIssueDateError} name="commercialRecordIssueDate"  />
                        {this.state.commercialRecordIssueDateHijri && <small>Hijri Date: <strong>{this.state.commercialRecordIssueDateHijri}</strong></small>}
                     </Grid>)
                :null}
                <Grid item xs={12} sm={6} md={3}>
                    <TextField fullWidth id="phoneNumber" onBlur={this.checkValid} error={phoneNumberError} label={<Translate id="phoneNumber" />} value={this.state.phoneNumber} onChange={e => this.onChange(e, 'phoneNumber')} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField fullWidth id="extensionNumber" label={<Translate id="extensionNumber" />} value={this.state.extensionNumber} onChange={e => this.onChange(e, 'extensionNumber')} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField fullWidth id="emailAddress" onBlur={this.checkValid} error={emailAddressError} label={<Translate id="emailAddress" />} value={this.state.emailAddress} onChange={e => this.onChange(e, 'emailAddress')} />
                </Grid>
                {this.props.registrationType === 'company'?
                    (<Grid item xs={12} sm={6} md={3}>
                    <TextField fullWidth id="managerName" onBlur={this.checkValid} error={managerNameError} label={<Translate id="managerName" />} value={this.state.managerName} onChange={e => this.onChange(e, 'managerName')} />
                    </Grid>)
                :null}
                {this.props.registrationType === 'company'?
                    (<Grid item xs={12} sm={6} md={3}>
                    <TextField fullWidth id="managerPhoneNumber" onBlur={this.checkValid} error={managerPhoneNumberError} label={<Translate id="managerPhoneNumber" />} value={this.state.managerPhoneNumber} onChange={e => this.onChange(e, 'managerPhoneNumber')} />
                    </Grid>)
                :null}
                {this.props.registrationType === 'company'?
                (<Grid item xs={12} sm={6} md={3}>
                    <TextField fullWidth id="managerMobileNumber" onBlur={this.checkValid} error={managerMobileNumberError} label={<Translate id="managerMobileNumber" />} value={this.state.managerMobileNumber} onChange={e => this.onChange(e, 'managerMobileNumber')} />
                    </Grid>)
                :null}
                <Grid item xs={12}>
                    {this.props.selectedItem && this.props.selectedItem.attributes && this.props.selectedItem.attributes.waslCompanyRefKey ? <div style={{display: 'inline-flex', alignItems: 'center'}}><VerifiedUserIcon style={{marginRight: 5}} /> integrated with wasl </div> :  <Button onClick={this.props.registrationType==='company'?this.submitCompany:this.submitIndividual}>Save</Button>}
                </Grid>
                <Grid item xs={12}>
                    {this.props.selectedItem && this.props.selectedItem.attributes && this.props.selectedItem.attributes.waslCompanyRefKey ? <div style={{display: 'inline-flex', alignItems: 'center'}} onClick={this.unLinkCompany}><LinkOff style={{marginRight: 5}} /> Unlink Company </div> : null}
                </Grid>
            </Grid>
        </div>
    }
}