import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import Layout from './../../wrapper'
import { logInUserInfo } from '../../Actions/Users'
import { checkPrivileges } from '../../Helpers'
import { ViewAccountDetail } from '../../Components/Accounts/accountDetail/viewAccountDetail'

class AccountSettings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sessionCallTrack: true
    }
  }
  componentWillMount () {
    this.props.dispatch(Notifications.removeAll())

  }
  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        let response = res.data
        if (response.ok) {
          this.props.history.push('/logout')
        }else{
          throw response
        }
      }).catch(e => {})
    }
  }

  render () {
    if (checkPrivileges('account')) {
        if (this.props.logInUser.userType === -1) {
          return (
             <Layout {...this.props}>
                <ViewAccountDetail
                    selectItemName={this.props.logInUser && this.props.logInUser.name}
                    selectItemId={this.props.logInUser && this.props.logInUser.accountId}
                    getMoreAccounts={this.getMoreAccounts}
                    AccountSettings
                    onClose={this.onCloseC_Modal}
                    roles={this.props.roles}
                />
            </Layout>
          )
             } else {
                this.props.history.push('/users')
                    return null
                }
  
    } 
  }
}
const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  roles: state.roles,
})

export default connect(mapStateToProps)(withLocalize(AccountSettings))
