
export const privileges = (state = [], action) => {
  switch (action.type) {

	case 'GET_RRIVILEGES':
  
		return action.privileges

	default:
		return state
}
}



