import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'

import {
  updateGroups,
} from './../../Actions/Groups'
import Table from './../../Components/common/tableWithBackEndPagination'
import {
  checkPrivileges,
  PaginationConfig
} from '../../Helpers'
import { Grid, } from '@material-ui/core'
import TextField from '../common/TextField'
import Loader from '../../Layout/Loader'
import Button from '../common/Button'

const groupDataFormat = (data, extAttriubtes, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...extAttriubtes },
  name: data.name.trim(),
})
let source
class groupModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...PaginationConfig,
      selectedUsers: '',
      selectedGroup: '',
      addOption: false,
      editOption: false,
      defaultGroupList: true,
      g_attriubtes: '',
      directOption: false,
      isVisableGroupBtn: false,
      isAttChanged: false,
      selectedOption: false,
      usesId: '',
      groupsArray: [],
      itemAttributes: {},
      attributeChangesMessage: '',
      groupsProcess: false,
      onDeleteConfirmation: false,
      selectedGroupUnitList: '',
      unAssignUnitList: [],
      // all fields of form
      form: {
        name: '',
        groupId: ''
      }, 
    }
  }
  
 

  render () {
    const { logInUser } = this.props
    let { selectedGroupUnitList, unAssignUnitList } = this.state
    return (
      <div>


        {this.state.defaultGroupList && (<>
          <Grid container className="breadcrumb-row">
            <Grid item xs={12} sm={2}>
              <h3 style={{margin: 0}}>{this.props.translate('billing')}</h3>
            </Grid>
            <Grid item xs={12} sm={4} style={{display:"flex", }}>
            <h3 style={{margin: 0}}>{this.props.translate('Year')} : {this.props.year}</h3>
            <h3 style={{marginLeft: 30}}>{this.props.translate('Month')} : {this.props.month}</h3>

            </Grid>

            <Grid item xs={12} sm={6} style={{justifyContent: 'flex-end'}}>
              <div style={{width: "35%", marginRight: 20}}>
                <TextField
                  fullWidth
                  label={this.props.translate('search')}
                  onChange={e => this.props.searchItem(e.target.value)}
                  />
              </div>
              {this.props.itemSelected && (
               <Button
                disabled={false}
                  aria-label='Create'
                  size='small'
                  color='inherit'
                  onClick={() => this.props.goBack()}
                  id='createButton'
                  >{this.props.translate('back')}</Button>
                  )}
            </Grid>
          </Grid>
            {this.props.loader ? <Loader/> :
              <Table
                insideDialog={true}
                rows={
                  this.props.itemSelected ?  this.props.childData&& this.props.childData?.data || [] :
                  this.props.tableData && this.props.tableData.data
                    || []
                }
                pagination={this.props.setPagination()}
                handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                handleChangePage={this.props.handleChangePage}


                // SearchItem={this.SearchItem}
                onClick={this.props.itemSelected ? "":this.props.invoiceSelection}
                // onDelete={this.removeEnable}
                // isEditable={true}
                // searchable
                themecolors={this.props.themecolors}
                // hasAccessOfUpdate={checkPrivileges('groupUpdate')}
                // hasAccessOfDelete={checkPrivileges('groupDelete')}
                // title={this.props.translate('groups')}
                createButton={checkPrivileges('groupCreate')}
                btnName={this.props.translate('create')}
                // onCreate={() => this.addGroup()}
                // setSerialNo
                rowDefinition={
                  this.props.itemSelected ?
                   [ 
                      {
                        id: 'entityLabel',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('entityLabel')
                      },  
                      {
                        id: 'entityUniqueNumber',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('entityUniqueNumber')
                      },  
                      {
                        id: 'entityStatus',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('entityStatus')
                      },  
                      {
                        id: 'entityCreated',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('entityCreated')
                      },   
                      // {
                      //   id: 'invoiceId',
                      //   numeric: false,
                      //   disablePadding: false,
                      //   label: this.props.translate('invoiceId')
                      // }
                    ]
                    :
                  [
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('name')
                  },
                  {
                    id: 'totalUnits',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('totalUnites')
                  },
                  {
                    id: 'activeUnits',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('activeUnits')
                  },
                  {
                    id: 'archiveUnits',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('archiveUnits')
                  },{
                    id: 'userStatus',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('userStatus')
                  },{
                    id: 'invoiceDate',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('invoiceDate')
                  },{
                    id: 'created',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('created')
                  },
                ]
              
              }
                />
            }
          </>
        )}

     
      </div>
    )
  }
}

const mapState = state => ({
  groups: state.groups,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
  devices: state.devices
})
const mapStateToProps = connect(mapState)
export const ResourceList = mapStateToProps(withLocalize(groupModal))
