import React, { Component } from 'react'
import './Style.scss'
import { LoginForm, ForgotForm } from './Component'
import axios from 'axios'
export default class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      view: 'login',
      loginPageImage : ""
    }
  }
  switchView = view => {
    this.setState({ view })
  }

  componentDidMount(){
    this.getImage()
  }
  getImage = async (id) => {
    try {
      const response = await axios.get(`/api/media/public/${this.props.whiteLabling.logInPageImage}`, {
        responseType: 'arraybuffer', // Ensure the response is treated as an array buffer
      });
      const filename = id;
      const parts = filename.split('.');
      const extension = parts[parts.length - 1];
      
      
      if (response.status === 200) {
        // Convert the array buffer to base64

        const base64Image = Buffer.from(response.data, 'binary').toString('base64');
        let loginImage = `data:image/${extension};base64,${base64Image}`
        // return `data:image/${extension};base64,${base64Image}`;
        this.setState({loginPageImage:loginImage})
      } else {
        console.error('Failed to fetch image:', response.status, response.statusText);
        return '';
      }
    } catch (error) {
      console.error('Error in getImage:', error);
      return '';
    }
  };
  render () {
    const { view } = this.state
    return (
      <div
        className='loginWrapper'
        loginPageImage
        style={{
          backgroundImage: `url(${this.props.whiteLabling.logInPageImage})`
          // backgroundImage: `url(${this.state.loginPageImage})`
        }}
      >
        <div className='loginWrapperInner'>
          {view === 'login' ? (
            <LoginForm {...this.props} switchView={this.switchView} />
          ) : null}
          {view === 'forgot' ? (
            <ForgotForm {...this.props} switchView={this.switchView} />
          ) : null}
        </div>
        <div
          className='white-label-bg'
          style={{ backgroundImage: 'url(/assets/images/login_wallpaper.png)' }}
        ></div>
      </div>
    )
  }
}
