import React, { Component } from 'react'
import axios from 'axios'
import { getDevices } from '../../Actions/Devices/index'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import PublishIcon from '@material-ui/icons/Publish'
import { Tooltip, Icon } from '@material-ui/core'
import MenuItem from '../common/MenuItem'
import Notifications from 'react-notification-system-redux'
import CustomDialog from './Dialog'
import Button from './Button'
import Radio from './Radio'

import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
class ImportExcel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSubmit: false
    }
  }

  // componentWillMount() {}
  // componentDidMount() {}
  // componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(prevProps, prevState) {}
  // componentWillUnmount() {}
  onClose = () => {
    this.props.onClose()
  }
  formSubmit = async (e, fileName) => {
    let api
    api = '/api/devices/import'
    const file = await this.convertBase64(e)
    const base64 = file.split('base64,')
    axios({
      method: 'POST',
      url: api,
      data: {
        name: 'anyName',
        contentType:
          '@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        oldUrl: '',
        insertionMode: this.state.insertionMode,
        data: base64[1]
      }
    })
      .then(response => {
        if (fileName === 'devices') {
          this.props.dispatch(getDevices(this.props.logInUser))
        }
        if (response.data.status === 'success') {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('Devices imported Successfully'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        }
        else if(response?.data?.statusCode === '440'){
          window.location.replace('/login')
        } 
        else if(response?.data?.statusCode){
          var err = response?.data?.message.split(':')
          err[1] =err[1].replace(')', "")
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate(err[1]),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        }
        this.onClose()
      })
      .catch(error => {
        console.log('er =', error)
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate('Error'),
            autoDismiss: 5,
            location: this.props.location
          })
        )
      })
  }
  convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  onCloseModal = () => {
    this.setState({
      enableImportPopup: false
    })
    this.onClose()
  }
  onOpenModal = () => {
    this.setState({
      enableImportPopup: true
    })
  }
  uploadFile = e => {

    let format =  e.target.files[0] &&  e.target.files[0].name && e.target.files[0].name.split('.').pop()
      if(format === 'xlsx' || format === 'xls'){
        this.setState({
          uploadedFile: e.target.files && e.target.files[0],
          selectedFile:
            e.target.files && e.target.files[0] && e.target.files[0].name,
          isSubmit: true
        })
      }
      else{
        this.setState({
          isSubmit: false
        }, ()=>{
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('Wrong format please upload excel file only!'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        })
      }
  }
  selectInsertionMode = e => {
    this.setState({
      insertionMode: e.target.value
    })
  }
  render () {
    const { fileName } = this.props
    return (
      <div>
        <CustomDialog
          title='Import Excel'
          themecolors={this.props.themecolors}
          visable={true}
          onClose={this.onCloseModal}
          bodyPadding={10}
          hideDragIcon
          fullWidth
          headerActions={
            <Button
              onClick={() =>
                this.formSubmit(this.state.uploadedFile, this.props.fileName)
              }
              disabled={!this.state.isSubmit}
            >
              {this.props.translate('Submit')}
            </Button>
          }
          isVisableBtn
          noFullScreen
        >
          <label
            style={{
              padding: 10,
              border: `1px dashed`,
              borderRadius: 6,
              marginBottom: 20,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <div style={{ marginRight: 'auto' }}>
              {this.state.selectedFile || 'Upload Excel'}
              <input type='file' onChange={e => this.uploadFile(e)} hidden />
            </div>
            <Button component='span'>{this.props.translate('browseFile')}</Button>
          </label>
          <RadioGroup
            aria-label='selectedDate'
            name='selectedDate'
            value={this.state.insertionMode}
            onChange={e => this.selectInsertionMode(e)}
            row
          >
            <FormControlLabel
              classes={{
                label: 'form-contol-label'
              }}
              value={'1'}
              control={<Radio size='small' />}
              label={this.props.translate('addNewData')}
              labelPlacement='end'
            />
            {fileName !== 'geofences' && (
              <FormControlLabel
                classes={{
                  label: 'form-contol-label'
                }}
                value={'2'}
                control={<Radio size='small' />}
                label={this.props.translate('addNewAndUpdate')}
                labelPlacement='end'
              />
            )}
          </RadioGroup>
        </CustomDialog>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withLocalize(ImportExcel))
