import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'date-fns'
import Button from '../../Components/common/Button'
import Grid from '@material-ui/core/Grid'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import { getStatistics } from './../../Actions/Reports'
import Table from '../../Components/common/Table'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styled from 'styled-components'
import DateRangePicker from './../common/DateRangePicker'
import moment from 'moment'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'

class showReports extends Component {
  constructor () {
    super()
    this.state = {
      defaultGroupList: false,
      isVisableBtn: false,
      form: {
        from: new Date(),
        to: new Date()
      },
      selectedDate: ''
    }
    this.showStatisticsRecords = this.showStatisticsRecords.bind(this)
    this.showDates = this.showDates.bind(this)
  }

  showDates (e, date) {
    if (date.startDate._d && date.endDate._d) {
      this.setState({
        isVisableBtn: true
      })
    } else {
      this.setState({
        isVisableBtn: false
      })
    }
    var formatedDate = {
      from: moment(date.startDate._d).toISOString(),
      to: moment(date.endDate._d).toISOString()
    }
    this.setState({
      selectedDate:
        moment(formatedDate.from).format('YYYY-MM-DD HH:mm') +
        ' - ' +
        moment(formatedDate.to).format('YYYY-MM-DD HH:mm'),
      form: { ...this.state.form, ...formatedDate }
    })
  }

  showStatisticsRecords () {
    const { form } = this.state
    fetch(`api/statistics?from=${form.from}&to=${form.to}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          response.json().then(statistics => {
            if(statistics.status ==='success'){
              this.props.dispatch(getStatistics(statistics.data))
            }
            else if(statistics?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(statistics?.statusCode){
              var err = statistics?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          })
        } else {
          throw response
        }
      })
      // .catch(e => {
      //   if(e && e.text){
      //   e.text().then(err => {
      //     confirmAlert({
      //       message: err,
      //       buttons: [
      //         {
      //           label: 'OK',
      //           onClick: () => {}
      //         }
      //       ]
      //     })
      //   })
      // }
      // })
      .catch(e => {
        console.log('e =', e)
        // this.props.dispatch(Notifications.error({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      })
  }

  render () {
    let { reports } = this.props

    return (
      <div>
        <Grid
          container
          spacing={4}
          alignItems='center'
          style={{ marginBottom: 8 }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <DateRangePicker
              selectedDate={this.state.selectedDate}
              label={
                this.props.translate('reportFrom') +
                ' ' +
                this.props.translate('reportTo')
              }
              onEvent={this.showDates}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Button
              variant='contained'
              disabled={!this.state.isVisableBtn}
              onClick={() => this.showStatisticsRecords()}
            >
              {' '}
              Show
            </Button>
          </Grid>
        </Grid>

        {reports.length > 0 && (
          <Table
            rows={reports}
            rowsPerPage={10}
            themecolors={this.props.themecolors}
            title='Statistics'
            rowDefinition={[
              {
                id: 'captureTime',
                numeric: false,
                disablePadding: false,
                label: 'Capture Time'
              },
              {
                id: 'activeUsers',
                numeric: false,
                disablePadding: false,
                label: 'Active Users'
              },
              {
                id: 'activeDevices',
                numeric: false,
                disablePadding: false,
                label: 'Active Devices'
              },
              {
                id: 'messagesReceived',
                numeric: false,
                disablePadding: false,
                label: 'Messages Received'
              },
              {
                id: 'requests',
                numeric: false,
                disablePadding: false,
                label: 'Requests'
              },
              {
                id: 'messagesStored',
                numeric: false,
                disablePadding: false,
                label: 'Messages Stored'
              },
              {
                id: 'mailSent',
                numeric: false,
                disablePadding: false,
                label: 'Mail Sent'
              },
              {
                id: 'smsSent',
                numeric: false,
                disablePadding: false,
                label: 'SMS Sent'
              },
              {
                id: 'geocoderRequests',
                numeric: false,
                disablePadding: false,
                label: 'Geocoder Requests'
              },
              {
                id: 'geolocationRequests',
                numeric: false,
                disablePadding: false,
                label: 'Geolocation Requests'
              }
            ]}
          />
        )}
      </div>
    )
  }
}

const mapState = state => ({
  reports: state.reports,
  themecolors: state.themecolors
})
const mapStateToProps = connect(mapState)
export const ShowReports = mapStateToProps(withLocalize(showReports))
