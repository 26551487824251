
export const roles = (state = [], action) => {
  switch (action.type) {

	case 'GET_ROLES':
		return action?.roles || []

	case 'ADD_ROLE':
		return state.concat(action.role)

	case 'UPDATE_ROLE':
		let _roles=state.filter(rol => rol.id !== action.role.id)
		return _roles.concat(action.role);

	case 'REMOVE_ROLE':
		let _rolesList=state.filter(rol => rol.id !== action.role.id)
		return _rolesList;	
	
	case 'SORT_ROLES':
		let roles1 = [];
		if (action.roles.sort === 'DESC') {
			roles1 = state.slice().sort(function (b, a) {
				var nameA = a.name.toLowerCase(),
					nameB = b.name.toLowerCase()
				if (nameA < nameB)
					return -1
				if (nameA > nameB)
					return 1
				return 0
			});
		} else {
			roles1 = state.slice().sort(function (a, b) {
				var nameA = a.name.toLowerCase(),
					nameB = b.name.toLowerCase()
				if (nameA < nameB)
					return -1
				if (nameA > nameB)
					return 1
				return 0
			});

		}

		return roles1
    
	case 'LOGOUT_USER':
		return []
	default:
		return state
}
}

export const searchRole = (state = "", action) => {
switch (action.type) {
	case 'SEARCH_ROLE':
		return action.payload;
	default:
		return state
}
}

