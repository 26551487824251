
export const ServerSetting = (state = '', action) => {
  switch (action.type) {

 	case 'SERVER_INFO':
		return action.server
    case 'LOGOUT_USER':
      return {}
    default:
      return state
  }
}