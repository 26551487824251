import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Style from "style-it";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
class Breadcrumb extends Component {
    constructor(props) {
        super(props);

    }
    handleClick = () => {
        if(this.props.onClose){
            this.props.onClose()
        }
        else{
            return null
        }
    }

    render() {
        return (
            <Style>{`
            .breadcurmb-row {
                padding: 8px 0
            }
            .backlink {
                float: right
            }
            `}
            <div className="breadcurmb-row">
                <div className="backlink">{this.props.backLink}</div>
                <Breadcrumbs separator={<KeyboardArrowRightIcon />} aria-label="breadcrumb">
                    <Link color="inherit" onClick={this.handleClick}>
                        {this.props.itemName ? (this.props.itemName + ' ' + this.props.moduleName) : (this.props.moduleName + ' ')} 
                    </Link>
                    <Typography color="textPrimary">{this.props.tabName}</Typography>
                </Breadcrumbs>
                <div className="clearfix"></div>
            </div>
            </Style>
        );
    }
}

export default Breadcrumb;