import React, { Component, Fragment } from 'react'
import './index.scss'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import { UsersShortList } from './../../Components/Users/shortList'
import { RoleShortList } from './../../Components/RoleManagement/shortList'
import { sortDevices, searchDevices } from './../../Actions/Devices'
import { sortUsers, searchUser } from './../../Actions/Users'
import { sortRoles, searchRole } from './../../Actions/Roles'
import MaterialIcon from 'material-icons-react'
import Icon from '@material-ui/core/Icon'
import Button from './../../Components/common/Button'
import TextField from './../../Components/common/TextField'
import { library } from '@fortawesome/fontawesome-svg-core'
import TemplateGeneratorSidebar from './Components/TemplateGenerator'
import { checkPrivileges, isOwnerOrDealer } from '../../Helpers'
import {
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle
} from '@fortawesome/free-solid-svg-icons'
import BillingFilter from '../../Components/Billing/BillingFilter'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
library.add([
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle
])

class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      collapse: false
    }
    this.collapseSidebar = this.collapseSidebar.bind(this)
    this.collapseSidebarWithButton = this.collapseSidebarWithButton.bind(this)
  }

  collapseSidebar () {
    let w = window.innerWidth
    if (w <= 959) {
      let v = this.state.collapse
      this.setState({ collapse: !v }, () => {
        if (!v) {
          this.props.sidebarClose('sidebar-collapse')
        } else {
          this.props.sidebarClose('')
        }
      })
    }
  }
  collapseSidebarWithButton () {
    let v = this.state.collapse
    this.setState({ collapse: !v }, () => {
      if (!v) {
        this.props.sidebarClose('sidebar-collapse')
      } else {
        this.props.sidebarClose('')
      }
    })
  }
  componentWillUnmount () {
    this.props.dispatch(searchUser({ user: '' }))
  }

  render () {
    if (this.props.noSidebar) {
      return null
    } else {
      const collpaseIconStyle = {
        left: '100%',
        position: 'absolute',
        padding: '10px 3px 7px',
        top: '50%',
        marginLeft: 0,
        background: this.props.themecolors['500'],
        color: this.props.themecolors['themeInverse'],
        borderRadius: '0 5px 5px 0',
        boxShadow: '0 0 15px rgba(0,0,0,0.2)'
      }
      return (
        <div 
        className={
          this.props.SidebarToggle ?  'fms-sidebar-wrapper2'  : 'fms-sidebar-wrapper'
        }
        >
          {!this.state.collapse && (
            <a
              href='javascript:void(0)'
              style={{ ...collpaseIconStyle }}
              onClick={e => this.collapseSidebarWithButton()}
            >
              {/* <Icon className='visible-responsive' style={{ fontSize: 30 }}> */}
                {/* keyboard_arrow_right */}
                                {/* <ArrowForwardIosIcon /> */}
              {/* </Icon> */}
              {/* <Icon className='hidden-responsive' style={{ fontSize: 30 }}> */}
                {/* keyboard_arrow_left */}
                                 <ArrowBackIosIcon/>
              {/* </Icon> */}
            </a>
          )}
          {this.state.collapse && (
            <a
              href='javascript:void(0)'
              style={{ ...collpaseIconStyle }}
              onClick={e => this.collapseSidebarWithButton()}
            >
              {/* <Icon className='hidden-responsive' style={{ fontSize: 30 }}> */}
                {/* keyboard_arrow_right  */}
                <ArrowForwardIosIcon/>
              {/* </Icon> */}
              {/* <Icon className='visible-responsive' style={{ fontSize: 30 }}> */}
                {/* keyboard_arrow_left */}
                 {/* <ArrowBackIosIcon  /> */}
              {/* </Icon> */}
            </a>
          )}
          <SidebarInner
            collapseSidebar={this.collapseSidebar}
            {...this.props}
          />
        </div>
      )
    }
  }
}
class SidebarInner extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userLimit: true
    }

    this.searchItem = this.searchItem.bind(this)
    this.sortByName = this.sortByName.bind(this)
  }

  sortByName () {
    if (this.props.location.pathname.indexOf('/roleManagement') !== -1) {
      this.props.dispatch(sortRoles({ sort: this.state.sort || 'DESC' }))
      this.setState({
        sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
      })
    } else if (
      this.props.logInUser &&
      isOwnerOrDealer(this.props.logInUser.userType)
    ) {
      this.props.dispatch(sortUsers({ sort: this.state.sort || 'DESC' }))
      this.setState({
        sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
      })
    } else {
      if (this.props.location.pathname === '/users') {
        this.props.dispatch(sortUsers({ sort: this.state.sort || 'DESC' }))
        this.setState({
          sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
        })
      } else if (this.props.location.pathname === '/trackes') {
        this.props.dispatch(sortDevices({ sort: this.state.sort || 'DESC' }))
        this.setState({
          sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
        })
      }
    }
  }

  searchItem (event) {
    if (this.props.location.pathname.indexOf('/roleManagement') !== -1) {
      this.props.dispatch(searchRole({ role: event.target.value }))
    } else if (
      this.props.logInUser &&
      isOwnerOrDealer(this.props.logInUser.userType)
    ) {
      this.props.dispatch(searchUser({ user: event.target.value }))
    } else {
      if (this.props.location.pathname === '/trackes') {
        this.props.dispatch(searchDevices({ device: event.target.value }))
      } else if (this.props.location.pathname === '/users') {
        this.props.dispatch(searchUser({ user: event.target.value }))
      }
    }
  }

  addDevices () {
    this.setState({
      isPopupVisable: !this.state.isPopupVisable
    })
  }

  checkUserLimit = () => {
    if (
      this.props.logInUser &&
      this.state.userLimit &&
      this.props.logInUser.userLimit !== -1
    ) {
      if (
        this.props.users &&
        this.props.users.length === this.props.logInUser.userLimit
      ) {
        this.setState({
          userLimit: false
        })
      }
    } else if (
      this.props.users &&
      this.props.users.length < this.props.logInUser.userLimit
    ) {
      this.setState({
        userLimit: true
      })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state) // equals() is your implementation
  }

  render () {
    if (this.props.location.pathname.indexOf('/units') !== -1) {
      return renderSideBarForOwner(
        'My units',
        this.state,
        this.props,
        this.searchItem,
        this.sortByName,
        'units',
        'deviceCreate',
        this.props.deviceLimit
      )
    }
    if (this.props.location.pathname === '/templateGenerator') {
      return (
        <TemplateGeneratorSidebar
          {...this.props}
          collapseSidebar={this.props.collapseSidebar}
        />
      )
    } else if (this.props.location.pathname.indexOf('/users') !== -1) {
      return (
        <aside className='fms-sidebar'>
          <div className='fms-sidebar-monitoring'>
            {checkPrivileges('user') && (
              <Fragment>
                <div className='section-head clearfix'>
                  <TextField
                    label='Search User'
                    type='search'
                    placeholder='exp: ID, Name'
                    value={this.state.search}
                    onChange={this.searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </div>
                <div className='section-head clearfix'>
                  <ul className='filter-row clearfix pull-right'>
                    <li>
                      <a
                        href='javascript:void(0)'
                        onClick={this.sortByName}
                        title='Sort by name'
                      >
                        <MaterialIcon
                          size='18'
                          icon='sort_by_alpha'
                          color='currentColor'
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            )}
            <div className='sidebar-devices-list'>
              <UsersShortList
                {...this.state}
                match={this.props.match}
                selecteItem={this.props.selecteItem}
              />
            </div>
          </div>
        </aside>
      )
    } else if (this.props.location.pathname.indexOf('/groups') !== -1) {
      return renderSideBarForOwner(
        'My groups',
        this.state,
        this.props,
        this.searchItem,
        this.sortByName,
        'groups',
        'groupCreate',
        true
      )
    } else if (this.props.location.pathname.indexOf('/roleManagement') !== -1) {
      return (
        <aside className='fms-sidebar'>
          <div className='fms-sidebar-monitoring'>
            {checkPrivileges('role') && (
              <Fragment>
                 <div
                   style={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center'
                          }}
                     className='headerDiv'      
                    >                                
                <div className='searchBar'>
                  <TextField
                    label={this.props.translate('searchRole')}
                    type='search'
                    placeholder='exp: ID, Name'
                    value={this.state.search}
                    onChange={this.searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </div>
                  {/* <ul className='createBtn'> */}
                    {checkPrivileges('roleCreate') && (
                      // <li className='filter-nav'>
                        <Button
                          size='small'
                          onClick={() => this.props.addItem()}
                        >
                          {' '}
                          {this.props.translate('create')}
                        </Button>
                      // {/* </li> */}
                    )}
                  {/* </ul> */}
                  </div>
                  <div className='section-head clearfix'>
                  <ul className='filter-row clearfix pull-right' >
                    <li>
                      <a
                        href='javascript:void(0)'
                        onClick={this.sortByName}
                        title='Sort by name'
                      >
                        <MaterialIcon
                          size='18'
                          icon='sort_by_alpha'
                          color='currentColor'
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            )}
            <div className='sidebar-devices-list'>
              <RoleShortList
                {...this.state}
                match={this.props.match}
                selecteItem={this.props.selecteItem}
                removedItem={this.props.removedItem}
                editItem={this.props.editItem}
                pathName={'roleManagement'}
              />
            </div>
          </div>
        </aside>
      )
    } 
    else if (this.props.location.pathname.indexOf('/billings') !== -1) {
      return (
        <>
          <BillingFilter
           {...this.props}
           accounts={this.props.accounts}
           getMissingItems={this.props.getMissingItems}
           // downloadCreditDebit={this.props.downloadCreditDebit}
           // creditDebitFilter={this.props.creditDebitFilter}
           translate={this.props.translate}
           // loader={this.props.loader}
          />
        </>
      )
    } 
    
    else {
      return ''
    }
  }
}
const mapState = state => ({
  devices: state.devices,
  logInUser: state.logInUsers,
  themecolors: state.themecolors
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(Sidebar)

function renderSideBarForOwner (
  renderIdem,
  state,
  props,
  searchItem,
  sortByName,
  pathName,
  hasAccess,
  limit
) {
  return (
    <aside className='fms-sidebar'>
      <div className='fms-sidebar-monitoring'>
        {checkPrivileges('user') && (
          <Fragment>
            <div className='section-head clearfix'>
              <TextField
                label='Search User'
                type='search'
                placeholder='exp: ID, Name'
                value={state.search}
                onChange={searchItem}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </div>
            <div className='section-head clearfix'>
              <ul className='filter-row pull-left'>
                <li style={{ marginLeft: 10 }}>
                  <Button
                    size='small'
                    onClick={() => {
                      props.showMyUsers()
                      props.history.push(`/${pathName}`)
                    }}
                  >
                    {props.translate(renderIdem)}
                  </Button>
                </li>
              </ul>
              <ul className='filter-row clearfix pull-right'>
                <li>
                  {' '}
                  <a
                    href='javascript:void(0)'
                    className='sidebar-collapse-button'
                    onClick={() => {
                      props.backToPrvUsers()
                    }}
                    title='back to prv stage'
                  >
                    <img src='/assets/images/common/arrow.svg' alt='' />
                  </a>
                </li>
                <li>
                  <a
                    href='javascript:void(0)'
                    onClick={sortByName}
                    title='Sort by name'
                  >
                    <MaterialIcon
                      size='18'
                      icon='sort_by_alpha'
                      color='currentColor'
                    />
                  </a>
                </li>
              </ul>
            </div>
          </Fragment>
        )}
        <div className='sidebar-devices-list'>
          <UsersShortList
            {...state}
            match={props.match}
            selecteItem={props.selecteItem}
            pathName={pathName}
            translate={props.translate}
            showNextChild={props.showNextChild}
            removedItem={props.removedItem}
            editItem={props.editItem}
            assignUnits={props.assignUnits}
            assignGroup={props.assignGroup}
          />
        </div>
      </div>
    </aside>
  )
}
// function renderSideBarForDealer(renderIdem, state, props, searchItem, sortByName, pathName, hasAccess) {
//   return (
//     <aside className="fms-sidebar">
//       <div className="fms-sidebar-monitoring">
//         {checkPrivileges('user') &&
//           <Fragment>
//             <div className="section-head clearfix">
//               <TextField
//                 label="Search User"
//                 type="search"
//                 placeholder="exp: ID, Name"
//                 value={state.search}
//                 onChange={searchItem}
//                 variant="outlined"
//                 margin='dense'
//                 fullWidth
//               />
//             </div>
//             <div className="section-head clearfix">
//               <ul className="filter-row pull-left">
//                 {checkPrivileges(hasAccess) &&
//                   <li className="filter-nav">
//                     <Button size="small"
//                       onClick={() => props.addItem()}> {props.translate("Create")}
//                     </Button>
//                   </li>}
//                 <li style={{ marginLeft: 10 }}>
//                   <Button size="small"
//                     onClick={() => { props.showMyUsers(); props.history.push(`/${pathName}`) }}>
//                     {props.translate(renderIdem)}
//                   </Button>
//                 </li>
//               </ul>
//               <ul className="filter-row clearfix pull-right">
//                 <li>
//                   <a href="javascript:void(0)" onClick={sortByName} title="Sort by name"><MaterialIcon size="18" icon='sort_by_alpha' color="currentColor" /></a>
//                 </li>
//               </ul>
//             </div>
//           </Fragment>}
//         <div className="sidebar-devices-list" >
//           <UsersShortList {...state} match={props.match}
//             selecteItem={props.selecteItem}
//             pathName={pathName}
//           />
//         </div>
//       </div>
//     </aside>
//   )

// }
