import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import Notifications from 'react-notification-system-redux'
import Table from '../../../common/TableMultiCheckbox'
import TextField from '../../../common/TextField'
import Button from '../../../common/Button'
import Autocomplete from '../../../common/Autocomplete'
import Paper from '@material-ui/core/Paper'
import Loader from '../../../../Layout/Loader'
import { updateDevice } from '../../../../Actions/Devices'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
const deviceDataFormat = (data, attributes, id) => ({
  id: id ? id : null,
  attributes: attributes ? attributes : {},
  name: data.name.trim(),
  uniqueId: (data.uniqueId.toString()).trim(),
  status: data.status,
  disabled: data.disabled,
  lastUpdate: data.lastUpdate,
  positionId: data.positionId,
  groupId: data.groupId,
  phone: data.phone,
  model: data.model,
  contact: data.contact,
  category: data.category,
  expirationTime:data.expirationTime?data.expirationTime:'',
  geofenceIds: data.geofenceIds,
  parentId: data.parentId || 0
})
class SendToAws extends Component {
  constructor (props) {
    super( props)
    this.state = {
      isRecived: false,
      selecteditem: '',
      selectedDevice: {
        ...this.props.selectedDevice,
        attributes: {
          ...this.props.selectedDevice.attributes
        }
      },
      isVisableDeviceBtn: false,
      duplicateIdentifier: false,
      notVisableBackbtn: true,
      form: {
        ...this.props.selectedDevice,
        attributes: {
          ...this.props.selectedDevice.attributes,
        }
      },
      loader: false,
      disableSubmit : true
    }
  }

  handleChange = name => event => {
    const { target } = event;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        attributes: {
          ...prevState.form.attributes,
          [name]: value
        }
      }
    }),()=>{
      this.checkReqFields()
    });
  };
  
  checkReqFields = () =>{
    let { forwardToAWS, awsAssetType ,awsAssetOwnerId , awsCarrierId } = this.state.form.attributes
    if(forwardToAWS){
      if( awsAssetType  && awsAssetOwnerId  &&  awsCarrierId ){
        this.setState({disableSubmit: false })
      }else{
        this.setState({disableSubmit: true })
      }
    }else{
        this.setState({disableSubmit: false })
    }

  }

  submitEditdevice = ()=> {
    this.setState({ loader: true }, () => {
      let { name, uniqueId, attributes, device, model } = this.state.form
      if (attributes && attributes['speedLimit']) {
        attributes['speedLimit'] = attributes['speedLimit'] / 1.852
      }
      attributes['minimalTripDistance'] =
        attributes['minimalTripDistance'] * 1000
      if (name.trim().length > 0 && (uniqueId.toString()).trim().length > 0) {
        const { selectedDevice, form } = this.state
        const obj = deviceDataFormat(form, attributes, selectedDevice.id)

        if (device && model) {
            obj.attributes = {
              ...obj.attributes,
              port: parseInt(device),
              protocol: typeof model === 'object' ? model.protocol : model
            }
          }
          obj.model = typeof model === 'object' ? model.protocol : model
          const final = {
            ...obj,
            attributes: {
              ...obj.attributes,
              speedLimit:
                form.attributes && form.attributes.speedLimit
                  ? form.attributes.speedLimit * 0.539957
                  : ''
            }
          }

          // console.log("final===", final);
        fetch(`/api/devices/${selectedDevice.id}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...final
          })
        })
          .then(response => {
              response.json().then(res => {
                if(res.status ==='success'){
                  let device = res.data
                  let parent = {
                    parentId: this.props.logInUser.id,
                    parentName: this.props.logInUser.name
                  }
                  device = { ...device, ...parent }
                  this.props.dispatch(updateDevice(device))
                  this.props.UpdateSelectedItem(device)
                  this.props.dispatch(
                    Notifications.success({
                      message: this.props.translate('trackersIsUpdated'),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                  this.setState({
                    isVisableDeviceBtn: false,
                    loader: false,
                    form: {
                      ...device
                    }
                  })
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                }
                else {
                  throw response
                }
              })
            
          })
          .catch(e => {
            this.props.dispatch(Notifications.error({
              message: 'somethingWentWrong',
              autoDismiss: 5
            }))
         
          })
      } else {
        this.setState({
          isVisableDeviceBtn: false,
          loader: false
        })
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate('emptyField'),
            autoDismiss: 5,
            location: this.props.location
          })
        )
      }
    })
  }

  render () {
    const { classes } = this.props
    const { form, isRecived } = this.state

    return (
      <div style={{ textAlign: 'center' }}>
       
          <Paper className={classes.paper + ' clearfix'}>
            <Grid container spacing={2}>
              <Grid
                item
                md={3}
                sm={6}
                xs={12}
                component='label'
                container
                alignItems='center'
                spacing={1}
              >
                <Grid item>{this.props.translate('off')}</Grid>
                <Grid item>
                  <Switch
                    value='attentance'
                    checked={form.attributes.forwardToAWS}
                    onChange={this.handleChange('forwardToAWS')}
                  />
                </Grid>
                <Grid item>{this.props.translate('on')}</Grid>
              </Grid>
         
            </Grid>
     { form.attributes.forwardToAWS && (
            <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='awsAssetType'
                    label={this.props.translate('awsAssetType')}
                    type='text'
                    placeholder=''
                    value={form.attributes.awsAssetType}
                    onChange={this.handleChange('awsAssetType')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='awsAssetOwnerId'
                    label={this.props.translate('awsAssetOwnerId')}
                    type='text'
                    placeholder=''
                    value={form.attributes.awsAssetOwnerId}
                    onChange={this.handleChange('awsAssetOwnerId')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='awsCarrierId'
                    label={this.props.translate('awsCarrierId')}
                    type='text'
                    placeholder=''
                    value={form.attributes.awsCarrierId}
                    onChange={this.handleChange('awsCarrierId')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                  />
                </Grid>
                </Grid>
            )} 

                <div
                  className='clearfix'
                  style={{
                    borderTop: '2px outset',
                    paddingTop: 16,
                    marginTop: 20
                  }}
                />
                <Button
                  variant='contained'
                  disabled={this.state.disableSubmit}
                  className={classes.button}
                  style={{
                    float: 'right'
                  }}
                  onClick={() => this.submitEditdevice()}
                >
                  {' '}
                  {this.props.translate('update')}
                </Button>
          </Paper>
        
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(SendToAws)
