import React, { Component, Fragment } from 'react'
import SimpleModalWrapped from './../common/Modal'
import TextField from './../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { unitsList } from './unitsPortList'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import SingleSelect from '../common/SingleSelect'
import { withLocalize } from 'react-localize-redux'
import { timeZonesTypes } from './../Attributes/timezone'
import { parentUnitsLimit, getDevices } from '../../Actions/Devices'
import { checkPrivileges } from '../../Helpers'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import axios from 'axios'
import SelectUser from './unitDetail/Components/changesOwnerShipe'
import Notifications from 'react-notification-system-redux'
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
const category = [
  'default',
  'arrow',
  'car',
  'pickup',
  'doublecabinpickup',
  'threetonpickup',
  'van',
  
  'suv',
  'offroad',
  'minibus',
  'smallbus',
  'bus',
  'train',
  'tram',
  
  'trolleybus',
  'truck',
  'forklift',
  'tractor',
  'dumper',
  'roadroller',
  'excavator',

  'boomloader',
  'crane',
  'dumptruck',
  'tanker',
  'flatbedtruck',
  'container',
  'mixer',

  'bulldozer',
  'skidloader',
  'plane',
  'helicopter',
  'boat',
  'ship',
  'bicycle',
  
  'motorcycle',
  'scooter',
  'person',
  // 'shovel',
  'biggenerator',
  'generator',
  'animal'
]
let source
class addDevice extends Component {
  constructor () {
    super()
    this.state = {
      usersData: '',
      parentId: '',
      resultMessage: '',
      defaultOption: [],
      value: []
    }
    this.searchFromZoneList = this.searchFromZoneList.bind(this)
    this.searchFromList = this.searchFromList.bind(this)
    // this.checkRequiredFields = this.checkRequiredFields.bind(this)

  }
  componentWillMount () {
    fetch(`/api/users/list?userId=${this.props.logInUser.id}&limit=100`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(res => {
          if(res.status === 'success'){
            let data =res.data
            let result = data.data.map(item => ({
              ...item,
              label: item.label || item.name,
              value: item.id
            }))
            if (result.length === 0) {
              this.setState({
                resultMessage: 'No option found',
                defaultOption: ''
              })
            } else {
              this.setState({
                resultMessage: '',
                defaultOption: result
              })
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
           else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
         
        })
      }
      else{
        throw response
      }
    }).catch(e => {})
  }
  // checkRequiredFields () {
  //   if (this.state.value) {
  //     this.setState({
  //       isVisableAddBtn: true
  //     })
  //   } else {
  //     this.setState({
  //       isVisableAddBtn: false
  //     })
  //   }
  // }
  onChange = (name, value) => {
    this.setState({ value: value })
  }
  searchList = async inputValue => {
    if (!inputValue) {
      return []
    }

    let response = await fetch(
      `/api/users/list?userId=${this.props.logInUser.id}&all=true&search=${inputValue}&limit=100`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    let json = await response.json()
    let result =
      (await json.data) && (json.data.data) &&
      json.data.data.map(item => ({
        ...item,
        label: item.label || item.name,
        value: item.id
      }))
    if (result.length === 0) {
      this.setState({
        resultMessage: 'No option found'
      })
    } else {
      this.setState({
        resultMessage: ''
      })
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: 'Search for more.'
      })
    }
    return result
  }
  async searchFromList (inputValue) {
    if (!inputValue) {
      return []
    }

    const result = []

       return await axios({
         method: 'GET',
         url: `/api/devices/models?search=${inputValue}`,
         headers: {
           Accept: 'application/json',
           'Content-Type': 'application/json'
           }

        }).then(res => {
          let response = res.data
          if(response.status==='success'){
            if(response&&response.data.data&&response.data.data){
              return response.data.data.map(item => {
                return {
                  id: item.name,
                  name: item.name,
                  key: item.name,
                  port: item.port,
                  protocol: item.protocol,
                  label: item.name,
                  value: item.name
                }
              })
            }
          }
          else if(response?.statusCode === '440'){
            window.location.replace('/login')
          } 
          else if(response?.statusCode){
            var err = response?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
    })
  }

  async searchFromZoneList (inputValue) {
    if (!inputValue) {
      return []
    }

    let result = timeZonesTypes.filter(i =>
      i.key.toLowerCase().includes(inputValue.toLowerCase())
    )
    return await result.map(item => {
      return { id: item.key, key: item.key, label: item.key, value: item.key }
    })
  }

  render () {
    let { value, defaultOption } = this.state
    const { classes, group } = this.props
    const defaultOptions = []
    unitsList.map((item, i) => {
      if (i <= 9) {
        defaultOptions.push({
          id: item.device,
          name: item.device,
          key: item.device,
          port: item.port,
          protocol: item.protocol,
          label: item.device,
          value: item.port
        })
      }
      return null
    })
    const defaultZones = []
    timeZonesTypes.map((item, i) => {
      if (i <= 9) {
        defaultZones.push({
          id: item.key,
          key: item.key,
          label: item.key,
          value: item.key
        })
      }
    })
  
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title='Required'
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate('sharedBack')}
          buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableDeviceBtn}
          notVisableBackbtn={this.props.notVisableBackbtn}
          showPortList={this.props.showPortList}
          showPort={this.props.showPort}
          content={
            <Fragment>
              <Grid container spacing={4}>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='name'
                    label={this.props.translate('sharedName')}
                    type='text'
                    placeholder=''
                    value={this.props.form.name}
                    onChange={this.props.handleChange('name')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                  />
                </Grid>
                {this.props.creatingUnit && (
                  <Grid item md={3} sm={6} xs={12}>
                    <SingleSelect
                      array={[]}
                      async
                      selectName='parentId'
                      defaultOptions={defaultOption}
                      loadOptions={this.searchList}
                      label={this.props.translate('selectParentUsers')}
                      value={
                        this.props.form.parentId
                          ? {
                              key: this.props.form.parentId,
                              id: this.props.form.parentId,
                              label: this.props.labelSelected
                            }
                          : {
                              key: 'Select Parent Acccount',
                              id: 'Select Parent Acccount',
                              label: 'Select Parent Acccount'
                            }
                      }
                      handleChange={this.props.handleChangeParent}
                      canAssign={true}
                      placeholder="parentUser"
                         />
                    </Grid>
                )}
                {((this.props.editOption) && (this.props.logInUser.attributes&&this.props.logInUser.attributes.viewModel)) ? 
                     checkPrivileges('deviceModel') ? ( 
                      <Grid item md={3} sm={6} xs={12} style={{position: 'relative', zIndex: 9}}>
                        <SingleSelect
                          array={[]}
                          async
                          selectName='model'
                          isClearable
                          defaultOptions={defaultOptions}
                          loadOptions={this.searchFromList}
                          label={this.props.translate('deviceModel')}
                          value={
                            typeof this.props.form.model === 'object'
                              ? this.props.form.model
                              : this.props.form.model
                              ? {
                                  id: this.props.form.model,
                                  key: this.props.form.model,
                                  label: this.props.form.model,
                                  value: this.props.form.model
                                }
                              : {
                                  key: 'Select Model',
                                  id: 'Select Model',
                                  label: 'Select Model'
                                }
                          }
                          handleChange={this.props.port_handleChange}
                          canAssign
                          editUnitCase={this.props.editOption}
                        />
                      </Grid>
                      ) : null
                : (this.props.logInUser.userType ===-1 || this.props.addOption) ?
                 (<Grid item md={3} sm={6} xs={12} style={{position: 'relative', zIndex: 9}}>
                      <SingleSelect
                        array={[]}
                        async
                        selectName='model'
                        isClearable
                        defaultOptions={defaultOptions}
                        loadOptions={this.searchFromList}
                        label={this.props.translate('deviceModel')}
                        value={
                          this.props.form.model
                            ? {
                                key: this.props.form.model,
                                id: this.props.form.model,
                                label: this.props.form.model
                              }
                            : {
                                key: 'Select Model',
                                id: 'Select Model',
                                label: 'Select Model'
                              }
                        }
                        handleChange={this.props.port_handleChange}
                        canAssign
                        editUnitCase={this.props.editOption}

                      />
                  </Grid>):null
                 } 
                {(this.props.editOption && this.props.logInUser.attributes&&this.props.logInUser.attributes.viewIMEI) ? 
                   (<Grid item md={3} sm={6} xs={12}>
                  {this.props.form.model !== 'FX Tracker' ? (
                    <TextField
                      id='uniqueId'
                      error={this.props.duplicateIdentifier}
                      label={this.props.translate('deviceIdentifier')}
                      InputProps={ this.props.editOption ? {readOnly: !checkPrivileges('deviceImei')}: ''  }
                      // disabled={
                      //   this.props.editOption &&
                      //   this.props.logInUser &&
                      //   this.props.logInUser.userType !== -1
                      // }
                      type='text'
                      placeholder=''
                      value={this.props.form.uniqueId}
                      onChange={this.props.handleChange('uniqueId')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      required
                    />
                  ) : (
                    <Tooltip title={this.props.translate('autoGeneratedId')}>
                      <TextField
                        id='uniqueId'
                        label={this.props.translate('deviceIdentifier')}
                        type='text'
                        placeholder=''
                        value={this.props.form.uniqueId}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        required
                      />
                    </Tooltip>
                  )}
                </Grid>)
                 :(this.props.logInUser.userType ===-1 || this.props.addOption) ? 
                 (<Grid item md={3} sm={6} xs={12}>
                  {this.props.form.model !== 'FX Tracker' ? (
                    <TextField
                      id='uniqueId'
                      error={this.props.duplicateIdentifier}
                      label={this.props.translate('deviceIdentifier')}
                      InputProps={ this.props.editOption ? {readOnly: !checkPrivileges('deviceImei')}: ''  }
                      // disabled={
                      //   this.props.editOption &&
                      //   this.props.logInUser &&
                      //   this.props.logInUser.userType !== -1
                      // }
                      type='text'
                      placeholder=''
                      value={this.props.form.uniqueId}
                      onChange={this.props.handleChange('uniqueId')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      required
                    />
                  ) : (
                    <Tooltip title={this.props.translate('autoGeneratedId')}>
                      <TextField
                        id='uniqueId'
                        label={this.props.translate('deviceIdentifier')}
                        type='text'
                        placeholder=''
                        value={this.props.form.uniqueId}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        required
                      />
                    </Tooltip>
                  )}
                </Grid>)
                 :null}

                {this.props.addOption && (
                  <Grid item sm={3} xs={12}>
                    <p
                      style={{
                        float: 'right',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        fontSize: '16',
                        background: '#ebebeb',
                        color: 'black',
                        padding: '12px 33px',
                        marginTop: 0,
                        marginLeft: '25'
                      }}
                    >
                      <span>
                        {this.props.translate(
                          'toConfigureYourDeviceManuallyUseConnectionParameters'
                        )}
                      </span>
                      <span style={{ display: 'block' }}>
                        <label
                          style={{ width: '40%', display: 'inline-block' }}
                        >
                          {this.props.translate('serverIp')}:
                        </label>
                        <span>{this.props.serverIp}</span>
                      </span>
                      <span style={{ display: 'block' }}>
                        <label
                          style={{ width: '40%', display: 'inline-block' }}
                        >
                          {this.props.translate('Port')}:
                        </label>
                        <span>{this.props.form.device}</span>
                      </span>
                    </p>
                  </Grid>
                )}
              </Grid>
              <h4>{this.props.translate('sharedExtra')}</h4>
              <Grid container spacing={4}>
                {/* <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='groupId'
                    select
                    label={this.props.translate('groupDialog')}
                    value={this.props.form.groupId}
                    onChange={this.props.handleChange('groupId')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin='dense'
                    fullWidth
                  >
                    <MenuItem value={0}>
                      <em>{this.props.translate('none')}</em>
                    </MenuItem>
                    {group &&
                      group.data &&
                      group.data.map(option => (
                        <MenuItem key={option.name} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid> */}
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='phone'
                    label={this.props.translate('sharedPhone')}
                    type='text'
                    placeholder=''
                    value={this.props.form.phone}
                    onChange={this.props.handleChange('phone')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                {/* {(this.props.addOption || (this.props.logInUser.userType !== 4 && this.props.logInUser.attributes.hideModal !== true)) &&
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id='model'
                      label={this.props.translate('model')}
                      type='text'
                      placeholder=''
                      value={this.props.form.model}
                      onChange={this.props.handleChange('model')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>} */}
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='contact'
                    label={this.props.translate('deviceContact')}
                    placeholder=''
                    value={this.props.form.contact}
                    onChange={this.props.handleChange('contact')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='category'
                    select
                    label={this.props.translate('deviceCategory')}
                    value={this.props.form.category}
                    onChange={this.props.handleChange('category')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin='dense'
                    fullWidth
                  >
                    <MenuItem value={0}>
                      <em>{this.props.translate('none')}</em>
                    </MenuItem>
                    {this.props.categories?.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item md={3} sm={6} xs={12}>
                  <Checkbox
                    checked={this.props.form.disabled}
                    onChange={this.props.handleChange('disabled')}
                    value='disabled'
                    color='primary'
                    label={this.props.translate('sharedDisabled')}
                  />
                </Grid> */}
              </Grid>
              <h4 style={{ margin: '10px 0' }}>
                {this.props.translate('sharedAttributes')}
              </h4>
              <Grid container spacing={4}>
                <Grid item sm={3} xs={12}>
                  <TextField
                    id='attributeDevicePassword'
                    label={this.props.translate('attributeDevicePassword')}
                    placeholder=''
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.devicePassword) ||
                      ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('devicePassword', e)
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={3} xs={12} style={{position: 'relative', zIndex: 0}}>
                  <SingleSelect
                    array={[]}
                    async
                    selectName='decoder.timezone'
                    isClearable={false}
                    defaultOptions={defaultZones}
                    loadOptions={this.searchFromZoneList}
                    label={this.props.translate('sharedTimezone')}
                    value={
                      this.props.form.attributes &&
                      this.props.form.attributes['decoder.timezone']
                        ? {
                            key:
                              this.props.form.attributes['decoder.timezone'] ||
                              '',
                            id:
                              this.props.form.attributes['decoder.timezone'] ||
                              '',
                            label:
                              this.props.form.attributes['decoder.timezone'] ||
                              ''
                          }
                        : {
                          label:"Timezone"
                        }
                    }
                    handleChange={this.props.timezone_handleChange}
                    canAssign
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    id='attributeSpeedLimit'
                    label={
                      this.props.translate('attributeSpeedLimit') + '(Km/h)'
                    }
                    type='number'
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.speedLimit) ||
                      ''
                    }
                    onChange={e => this.props.changeAttribute2('speedLimit', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      margin='dense'
                      label={this.props.translate('installDate')}
                      variant='outlined'
                      fullWidth
                      value={
                        this.props.form.attributes?.installDate
                          ? new Date(
                              `${this.props.form.attributes.installDate}`
                            ).toString()
                          : null
                      }
                      onChange={e => this.props.handleChangeLicenseExpDate('installDate', e)}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      margin='dense'
                      label={this.props.translate('expireDate')}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props.form?.expirationTime
                          ? new Date(
                              `${this.props.form?.expirationTime}`
                            ).toString()
                          : null
                      }
                      onChange={e => this.props.handleChangeLicenseExpDate('expirationTime', e)}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                    </MuiPickersUtilsProvider>
                  </Grid>
                {/* {!this.props.addOption &&
                  this.props.logInUser.userType !== 4 &&
                  this.props.logInUser.attributes.hideModal !== true && (
                    <Grid item sm={3} xs={12}>
                      <p
                        style={{
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                          fontSize: '16',
                          background: '#ebebeb',
                          color: 'black',
                          padding: '12px 10px',
                          marginTop: 0
                        }}
                      >
                        <span style={{ display: 'block' }}>
                          <label
                            style={{ width: '40%', display: 'inline-block' }}
                          >
                            {this.props.translate('Port')}:
                          </label>
                          <span>
                            {this.props.form.device
                              ? this.props.form.device
                              : this.props.form.attributes
                              ? this.props.form.attributes.port
                              : 'null'}
                          </span>
                        </span>
                        <span style={{ display: 'block' }}>
                          <label
                            style={{ width: '40%', display: 'inline-block' }}
                          >
                            {this.props.translate('sharedName')}:
                          </label>
                          <span>{this.props.form.model || 'null'}</span>
                        </span>
                      </p>
                    </Grid>
                  )} */}
              </Grid>
              <br />
            </Fragment>
          }
        />
      </Fragment>
    )
  }
}

const AddDevice = withStyles(styles)
export default AddDevice(withLocalize(addDevice))
