/* eslint-disable react/prop-types, react/jsx-handler-names */

import React, { Fragment } from 'react'
import Select from 'react-select'
// import AsyncSelect from 'react-select/lib/Async'
import AsyncSelect from 'react-select/async'
const customStyles = {
  container: provided => ({
    ...provided,
    marginTop: 8,
    marginBottom: 4,
    zIndex: 9
  }),
  valueContainer: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  control: provided => ({
    ...provided,
    minHeight: 28,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    marginTop: 4,
    marginBottom: 4,
  }),
}
class SingleSelect extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (value) {
    let name = this.props.selectName || 'single'
    // console.log("name======", name, value);
    if(name === 'parentId' ){
      this.setState({
        [name]:value.id
      }, ()=>{
        if(this.props.handleChange){
          this.props.handleChange(name, value)
        }
      })
    }
    else {
         if (value) {
      if (value.id && this.props.canAssign) {
        this.setState(
          {
            [name]: value
          },
          () => {
           
            if (this.props.returnSelected) {
              this.props.returnSelected(value, 'POST')
            }
            if (this.props.handleChange && this.props.editUnitCase) {
              this.props.handleChange(name, value.name)
            }
            else{
              this.props.handleChange(name, value)

            }
          }
        )
      } else {
        if (name === 'timezone' || name === 'decoder.timezone' || name ==='user') {
          this.setState(
            {
              [name]: value.id
            },
            () => {
              if (this.props.handleChange) {
                this.props.handleChange(name, value)
              }
            }
          )
        }
      }
    } else {
      this.props.handleChange(name, '')
    }
    }
   
  }
  UNSAFE_componentWillReceiveProps (n) {
    this.setState({ [n.selectName]: n.value })
  }

  render () {
    const { classes, theme, array } = this.props
    // console.log("sugg====",array);
    const suggessions = array.map(v => {
      return { ...v, id: v.key, label: v.name || v.key ||v.label }
    })

    
    if (
      this.state[this.props.selectName] === undefined &&
      this.props.value !== undefined
    ) {
      this.setState({ [this.props.selectName]: this.props.value })
    }

    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl
    } = this.props
    return (
      <Fragment>
        {!this.props.async && (
          <Select
          {...this.props}
          styles={customStyles}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          placeholder={null}
          isRtl={isRtl}
          value={this.state[this.props.selectName] || ''}
          //components={components}
          // components={{ Control }}
          isSearchable={isSearchable}
          options={suggessions}
          onChange={e => this.handleChange(e)}
          placeholer={this.props.label || 'Select'}
        />
        )}
        {this.props.async && (
          <AsyncSelect
          {...this.props}
          styles={customStyles}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          // placeholder={null}
          isRtl={isRtl}
          value={this.state[this.props.selectName] || ''}
          //components={components}
          // components={{ Control }}
          isSearchable={isSearchable}
          onChange={this.handleChange}
          options={suggessions}
          placeholder={this.props.label || 'Select'}
          />
        )}
      </Fragment>
    )
  }
}

export default SingleSelect
