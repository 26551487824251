/* eslint-disable react/prop-types, react/jsx-handler-names */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from './TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from './MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: 'flex'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: theme.spacing(1)
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(1) * 2
  }
})

function NoOptionsMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option (props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue (props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer (props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue (props) {
  return null /*(
    <Chip style={{display: 'none'}}
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )*/
}

function Menu (props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  SingleValue,
  ValueContainer
}

class IntegrationReactSelect extends Component {
  state = {
    single: null,
    multi: this.props.multi || null
  }

  handleChange = name => value => {
    this.setState(
      {
        [name]: value
      },
      () => this.props.onChange(this.state.multi)
    )
  }

  handleChange = name => value => {
    if (value.length && this.props.canAssign) {
      this.setState(
        {
          [name]: value
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.multi)
          }
          if (this.props.returnSelected) {
            this.props.returnSelected(value, 'POST')
          }
        }
      )
    }
  }

  handleDelete (data) {
    let newChips = []
    if (this.state.multi.length && this.props.canRemove) {
      this.state.multi.map(chip => {
        if (data.value !== chip.value) {
          newChips.push(chip)
        } else {
          return null
        }
        return null
      })

      //this.props.removeProps.onClick();

      this.setState({ multi: newChips }, () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.multi)
        }
        if (this.props.returnSelected) {
          this.props.returnSelected([data], 'DELETE')
        }
      })
    }
  }

  render () {
    const { classes, theme, array } = this.props
    const suggestions =
      array &&
      array.map(suggestion => ({
        value: suggestion.id,
        label: suggestion.label || suggestion.name
      }))

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    }

    let chips

    if (this.state.multi && this.state.multi.length) {
      chips = this.state.multi.map(chip => (
        <Chip
          key={chip.value}
          label={chip.label}
          variant='outlined'
          onDelete={e => this.handleDelete(chip)}
          style={{ marginRight: 5, marginBottom: 5 }}
        />
      ))
    }

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            {...this.props}
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label: this.props.label || 'Select Multiple',
              InputLabelProps: {
                shrink: true
              }
            }}
            options={suggestions}
            components={components}
            value={this.state.multi}
            onChange={this.handleChange('multi')}
            placeholder=''
          />
          {chips && chips.length && <div style={{ padding: '10px 0' }}>{chips}</div>}
        </NoSsr>
      </div>
    )
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect)
