import { prepareUsers } from '../../Helpers'

export const users = (state = [], action) => {
  switch (action.type) {
    case 'GET_USERS':
      let NewData = prepareUsers(action.users.data)
      action.users.data = NewData
      return action.users

    case 'ADD_USER':
      let prvState = state
      prvState.data.push(action.user)
      return prvState

    case 'UPDATE_USER':
      let oldState = state
      console.log(action.user)
      oldState = state.filter(gr => gr.id !== action.user.id)
      oldState.push(action.user)
      return oldState

    case 'REMOVE_USER':
      let _usersList = state
      let nwData = _usersList.data.filter(use => use.id !== action.user.id)
      _usersList.data = nwData
      return _usersList
    case 'SORT_USERS':
      let users1 = []
      if (action.users.sort === 'DESC') {
        users1 = state.slice().sort(function (b, a) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      } else {
        users1 = state.slice().sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      }

      return users1
    case 'LOGOUT_USER':
      return []
    default:
      return state
  }
}
export const parentUserLimits = (state = 0, action) => {
  switch (action.type) {
    case 'USER_LIMIT':
      return action.limit
    case 'LOGOUT_USER':
      return ''
    default:
      return state
  }
}

export const searchUser = (state = '', action) => {
  switch (action.type) {
    case 'SEARCH_USER':
      return action.payload
    default:
      return state
  }
}

export const unitBaseUser = (state = '', action) => {
  switch (action.type) {
    case 'UNIT_BASE_USER':
      return action.user
    case 'LOGOUT_USER':
      return ''
    default:
      return state
  }
}
