import React, { Component } from 'react'
import { components } from 'react-select'
// import AsyncSelect from 'react-select/lib/Async'
import AsyncSelect from 'react-select/async'

import TextField from './TextField'
import SearchField from './SearchField'
import { withStyles } from '@material-ui/core/styles'

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      {props.selectProps.resultMessage}
    </components.NoOptionsMessage>
  )
}

const styles = theme => ({
  input: {
    display: 'flex'
  }
})

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  if (props.selectProps.fill) {
    return (
      <SearchField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    )
  } else {
    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    )
  }
}

class AsyncMulti extends Component {
  state = { inputValue: '' }
  handleInputChange = (newValue, option) => {
    this.setState({ inputValue: newValue })
    this.props.handleChange(this.props.selectName, newValue, option, this.props.extras)
    return newValue
  }
  render () {
    const customStyles = {
      option: base => ({
        ...base,
        padding: '10px 14px'
      }),
      noOptionsMessage: base => ({
        ...base,
        padding: '10px 14px'
      }),
      multiValue: base => ({
        ...base,
        margin: 2
      })
    }

    return (
      <AsyncSelect
      {...this.props}
      styles={customStyles}
      isMulti={this.props.isMulti !== undefined ? this.props.isMulti : true}
      resultMessage={this.props.resultMessage || "Type here for search"}
      className={"async-select"}
      getOptionValue={(option) => option.name || option.label || option.invoiceNumber}
      getOptionLabel={(option) => option.name || option.label ||  option.invoiceNumber}
      // getOptionValue={(option) => this.props.invoiceNumber ? option.invoiceNumber || "" : option.name || option.label || option.invoiceNumber }
      // getOptionLabel={(option) => this.props.invoiceNumber ? option.invoiceNumber || "" : option.name || option.label || option.invoiceNumber }
      components={{ NoOptionsMessage, Control }}
      onChange={this.handleInputChange}
      isClearable 
      placeholder={this.props.placeholder || "type ..."}
      />
    )
  }
}

export default withStyles(styles, { withTheme: true })(AsyncMulti)
