export const deviceAttributesTypes =
[{
    key: 'web.reportColor',
    name: 'attributeWebReportColor',
    valueType: 'color',
    htmlTag: 'color'
}, {
    key: 'devicePassword',
    name: 'attributeDevicePassword',
    valueType: 'string',
    htmlTag: 'input'
}, {
    key: 'processing.copyAttributes',
    name: 'attributeProcessingCopyAttributes',
    valueType: 'string',
    htmlTag: 'input'
}, {
    key: 'decoder.timezone',
    name: 'sharedTimezone',
    valueType: 'string',
    dataType: 'timezone',
    htmlTag: 'select'
}, {
    key: 'speedLimit',
    name: 'attributeSpeedLimit',
    valueType: 'number',
    dataType: 'speed',
    htmlTag: 'input'
}, {
    key: 'report.ignoreOdometer',
    name: 'attributeReportIgnoreOdometer',
    valueType: 'boolean',
    htmlTag: 'input'
}]

export const driverAttributesTypes = [{
    key: 'driverAttributes',
    valueType: 'text',
    htmlTag: 'input'
}]

export const userAttributesTypes = [
{
    key: 'web.liveRouteLength',
    name: 'attributeWebLiveRouteLength',
    valueType: 'number',
    allowDecimals: false,
    htmlTag: 'input'
}, {
    key: 'web.selectZoom',
    name: 'attributeWebSelectZoom',
    valueType: 'number',
    allowDecimals: false,
    // minValue: 'Style.mapDefaultZoom',
    // maxValue: 'Style.mapMaxZoom',
    htmlTag: 'input'
}, {
    key: 'web.maxZoom',
    name: 'attributeWebMaxZoom',
    valueType: 'number',
    allowDecimals: false,
    //  minValue: 'Style.mapDefaultZoom',
    //   maxValue: 'Style.mapMaxZoom',
    htmlTag: 'input'
}, {
    key: 'distanceUnit',
    name: 'settingsDistanceUnit',
    valueType: 'string',
    dataType: 'distanceUnit',
    htmlTag: 'select'
}, {
    key: 'speedUnit',
    name: 'settingsSpeedUnit',
    valueType: 'string',
    dataType: 'speedUnit',
    htmlTag: 'select'
}, {
    key: 'volumeUnit',
    name: 'settingsVolumeUnit',
    valueType: 'string',
    dataType: 'volumeUnit',
    htmlTag: 'select'
}, {
    key: 'timezone',
    name: 'sharedTimezone',
    valueType: 'string',
    dataType: 'timezone',
    htmlTag: 'select'
}]

export const groupAttributesTypes = [
{
    key: 'processing.copyAttributes',
    name: 'attributeProcessingCopyAttributes',
    valueType: 'string',
    htmlTag: 'input'
}, {
    key: 'decoder.timezone',
    name: 'sharedTimezone',
    valueType: 'string',
    dataType: 'timezone',
    htmlTag: 'select'
}, {

    key: 'speedLimit',
    name: 'attributeSpeedLimit',
    valueType: 'number',
    dataType: 'speed',
    htmlTag: 'input'
}, {

    key: 'report.ignoreOdometer',
    name: 'attributeReportIgnoreOdometer',
    valueType: 'boolean',
    htmlTag: 'input'
}]

export const speedUnit = [{
    key: 'kn',
    name: 'sharedKn',
    factor: 1
}, {
    key: 'kmh',
    name: 'sharedKmh',
    factor: 1.852
}, {
    key: 'mph',
    name: 'sharedMph',
    factor: 1.15078
}]

export const distanceUnit = [{
    key: 'km',
    name: 'sharedKm',
    factor: 0.001
}, {
    key: 'mi',
    name: 'sharedMi',
    factor: 0.000621371
}, {
    key: 'nmi',
    name: 'sharedNmi',
    factor: 0.000539957
}]
export const volumeUnit = [{
    key: 'ltr',
    name: 'sharedLiterAbbreviation',
    fullName: 'sharedLiter',
    factor: 1
}, {
    key: 'impGal',
    name: 'sharedGallonAbbreviation',
    fullName: 'sharedImpGallon',
    factor: 4.546
}, {
    key: 'usGal',
    name: 'sharedGallonAbbreviation',
    fullName: 'sharedUsGallon',
    factor: 3.785
}]
export const simpleAttributesTypes = [{
    key: 'simpleAttributes',
    valueType: 'text',
    htmlTag: 'input'
}]