
export const logInUsers = (state = '', action) => {
  switch (action.type) {

	case 'LOGIN_USER_INFO':
    localStorage.setItem("userInfo", JSON.stringify({...action.user}));
		let u = {...action.user, attributes: action?.user?.attributes ? action.user.attributes : {}};
      return u
    case 'LOGOUT_USER':
      localStorage.removeItem('userInfo');
      return {}
    default:
      return state
  }
}