import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'

let themecolors
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors
  return {
    themecolors: state.themecolors
  }
}
const styles = theme => ({
  root: {
    color: themecolors[600],
    '&$checked': {
      color: themecolors[500]
    }
  },
  checked: {}
})

class CheckboxLabels extends Component {
  constructor (props) {
    super(props)
    this.state = {
      checked: props.checked
    }
  }
  handleChange = name => event => {
    this.setState({ checked: event.target.checked })
    if (this.props.onChange) {
      this.props.onChange(event, name)
    }
  }

  render () {
    const { classes } = this.props
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.checked}
            onChange={this.handleChange(this.props.id)}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
            {...this.props}
          />
        }
        label={this.props.label || ''}
      />
    )
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CheckboxLabels))
