import React from 'react'
import { connect } from 'react-redux'
import { isAuthenticated } from './index'
import { getUsers, parentUsersLimit } from '../Actions/Users'
import { getRoles } from '../Actions/Roles'
import { ServerInfo } from '../Actions/serverSetting'
import { getDevices, parentUnitsLimit } from '../Actions/Devices'
import { updateGroups } from '../Actions/Groups'
import { getAccounts } from '../Actions/Accounts'
import { checkPrivileges, PaginationConfig } from './index'
import { checkedDevelopmentType } from '../Helpers/index'
import Notifications from 'react-notification-system-redux'

class CommonSection extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      calenderFetched: false,
      commandFetched: false,
      isMount: true
    }
  }
  displayError = e => {
    if(e && e.text){
      // e.text().then(err => {
      //   console.log(err)
      // })
      console.log(e)
    }
  }
  componentWillMount () {
    if (this.state.isMount) {
      if (isAuthenticated(this.props)) {
        //server setting call for default setting
        if (!this.props.ServerSetting && this.props.logInUser) {
          fetch(
            `/api/accounts?accountId=${this.props.logInUser.accountId}`
          ).then(response => {
            if (response.ok) {
              response.json().then(res => {
                if(res.status ==='success'){ 
                  let server = res.data
                  let domElements = document.getElementById('favicon')
                  if (
                    server &&
                    server[0].attributes &&
                    server[0].attributes.favIcon &&
                    domElements
                  ) {
                    domElements.setAttribute(
                      'href',
                      server[0].attributes.favIcon + `?v=${new Date().getTime()}`
                    )
                  }
                  this.props.dispatch(ServerInfo(server[0]))
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                }
              })
            }else{
              throw response
            }
          })
          .catch(e => {
            this.props.dispatch(Notifications.error({
              message: 'somethingWentWrong',
              autoDismiss: 5
            }))
          })
        }
        // inital fetch call for Accounts
        if (
          checkPrivileges('account') &&
          !(this.props.accounts.data && this.props.accounts.data.length)
        ) {
          if (this.props.logInUser.userType === -1) {
            fetch(
              `/api/accounts/get?userId=${this.props.logInUser.id}&all=true&page=${PaginationConfig.page}&limit=${PaginationConfig.pageSize}`
            ).then(response => {
              if (response.ok) {
                response.json().then(res => {
                  if(res.status ==='success'){ 
                    let accounts = res.data
                    this.props.dispatch(getAccounts(accounts))
                  }
                  else if(res?.statusCode === '440'){
                    window.location.replace('/login')
                  }
                  else if(res.statusCode){
                    var err = res?.message.split(':')
                    err[1] =err[1].replace(')', "")
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(err[1]),
                        autoDismiss: 5,
                        location: this.props.location
                      })
                    )
                  }
                })
              }
            })
          } else {
            fetch(
              `/api/accounts/get?userId=${this.props.logInUser.id}&all=true&page=${PaginationConfig.page}&limit=${PaginationConfig.pageSize}`
            ).then(response => {
              if (response.ok) {
                response.json().then(res => {
                  if(res.status ==='success'){ 
                    let accounts = res.data
                    this.props.dispatch(getAccounts(accounts))
                  }
                  else if(res?.statusCode === '440'){
                    window.location.replace('/login')
                  }
                  else if(res.statusCode){
                    var err = res?.message.split(':')
                    err[1] =err[1].replace(')', "")
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(err[1]),
                        autoDismiss: 5,
                        location: this.props.location
                      })
                    )
                  }
                })
              }else{
                throw response
              }
            })  
            .catch(e => {
              this.props.dispatch(Notifications.error({
                message: 'somethingWentWrong',
                autoDismiss: 5
              }))
            })
          }
        }

        // inital fetch call for units
        if (
          checkPrivileges('device') &&
          !(this.props.devices.data && this.props.devices.data.length)
        ) {
          if (this.props.logInUser.userType === -1) {
            fetch(
              `/api/devices/get?userId=${this.props.logInUser.id}&all=true&page=${PaginationConfig.page}&limit=${PaginationConfig.pageSize}`
            ).then(response => {
              if (response.ok) {
                response.json().then(res => {
                  if(res.status ==='success'){  
                    let devices =res.data
                    this.props.dispatch(getDevices(devices))
                  }
                  else if(res?.statusCode === '440'){
                    window.location.replace('/login')
                  }
                  else if(res.statusCode){
                    var err = res?.message.split(':')
                    err[1] =err[1].replace(')', "")
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(err[1]),
                        autoDismiss: 5,
                        location: this.props.location
                      })
                    )
                  }
                })
              }else{
                throw response
              }
            }) 
            .catch(e => {
              this.props.dispatch(Notifications.error({
                message: 'somethingWentWrong',
                autoDismiss: 5
              }))
            })
          } else {
            fetch(
              `/api/devices/get?userId=${this.props.logInUser.id}&all=true&page=${PaginationConfig.page}&limit=${PaginationConfig.pageSize}`
            ).then(response => {
              if (response.ok) {
                response.json().then(res => {
                  if(res.status ==='success'){ 
                    let devices =res.data
                    this.props.dispatch(getDevices(devices))
                  }
                  else if(res?.statusCode === '440'){
                    window.location.replace('/login')
                  }
                  else if(res.statusCode){
                    var err = res?.message.split(':')
                    err[1] =err[1].replace(')', "")
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(err[1]),
                        autoDismiss: 5,
                        location: this.props.location
                      })
                    )
                  }
                })
              }else{
                throw response
              }
            })
            .catch(e => {
              this.props.dispatch(Notifications.error({
                message: 'somethingWentWrong',
                autoDismiss: 5
              }))
            })
          }
        }
        // inital fetch call for group
        if (
          checkPrivileges('group') &&
          !(this.props.group.data && this.props.group.data.length)
        ) {
          fetch(`/api/groups/get?userId=${this.props.logInUser.id}`, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }).then(response => {
            if (response.ok) {
              response.json().then(res => {
                if(res.status ==='success'){
                  let groups = res.data
                  this.props.dispatch(updateGroups(groups))
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                }
              })
            } else{
              throw response
            }
          })
          .catch(e => {
             console.log('e =',e)
            // this.props.dispatch(Notifications.error({
            //   message: 'somethingWentWrong',
            //   autoDismiss: 5
            // }))
          })
        }
        // inital fetch call for roles
        if (checkPrivileges('role') && !this.props.roles.length) {
          if (this.props.logInUser.userType === -1) {
            
            fetch(`/api/roles?userId=${this.props.logInUser.id}&all=true`).then(response => {
              if (response.ok) {
                response.json().then(res => {
                  if(res.status ==='success'){   
                    let role = res.data
                    this.props.dispatch(getRoles(role))
                  }
                  else if(res?.statusCode === '440'){
                    window.location.replace('/login')
                  }
                  else if(res.statusCode){
                    var err = res?.message.split(':')
                    err[1] =err[1].replace(')', "")
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(err[1]),
                        autoDismiss: 5,
                        location: this.props.location
                      })
                    )
                  }
                })
              }else{
                throw response
              }
            }) 
            .catch(e => {
              this.props.dispatch(Notifications.error({
                message: 'somethingWentWrong',
                autoDismiss: 5
              }))
            })
          } else {
            fetch(`/api/roles?userId=${this.props.logInUser.id}&all=true`).then(
              response => {
                if (response.ok) {
                  response.json().then(res => {
                    if(res.status ==='success'){
                      let role =res.data
                      this.props.dispatch(getRoles(role))
                    }
                    else if(res?.statusCode === '440'){
                      window.location.replace('/login')
                    }
                    else if(res.statusCode){
                      var err = res?.message.split(':')
                      err[1] =err[1].replace(')', "")
                      this.props.dispatch(
                        Notifications.error({
                          message: this.props.translate(err[1]),
                          autoDismiss: 5,
                          location: this.props.location
                        })
                      )
                    }
                  })
                }
                else{
                  throw response
                }
              }).catch(e => {})
          }
        }
        // inital fetch call for User
        // if (
        //   checkPrivileges('user') &&
        //   !(this.props.users.data && this.props.users.data.length)
        // ) {
        //   if (this.props.logInUser.userType === 5) {
        //     fetch(
        //       `/api/users/get?userId=${this.props.logInUser.id}&page=${PaginationConfig.page}&limit=${PaginationConfig.pageSize}`,
        //       {
        //         method: 'GET',
        //         headers: {
        //           Accept: 'application/json',
        //           'Content-Type': 'application/json'
        //         }
        //       }
        //     )
        //       .then(response => {
        //         if (response.ok) {
        //           response.json().then(users => {
        //             this.props.dispatch(getUsers(users))
        //           })
        //         } else {
        //           throw response
        //         }
        //       })
        //       .catch(e => {
        //         this.displayError(e)
        //       })
        //   } else {
        //     fetch(
        //       `/api/users/get?userId=${this.props.logInUser.id}&all=true&page=${PaginationConfig.page}&limit=${PaginationConfig.pageSize}`,
        //       {
        //         method: 'GET',
        //         headers: {
        //           Accept: 'application/json',
        //           'Content-Type': 'application/json'
        //         }
        //       }
        //     )
        //       .then(response => {
        //         if (response.ok) {
        //           response.json().then(users => {
        //             this.props.dispatch(getUsers(users))
        //           })
        //         } else {
        //           throw response
        //         }
        //       })
        //       .catch(e => {
        //         this.displayError(e)
        //       })
        //   }
        // }
        //units limits
        if (
          this.props.location.pathname.indexOf('/units') !== -1 ||
          this.props.location.pathname.indexOf('/users') !== -1 ||
          this.props.location.pathname.indexOf('/accountManagements') !== -1 ||
          this.props.location.pathname.indexOf('/accounts') !== -1
        ) {
          if (this.props.logInUser.id) {
            if (!this.props.parentUnitLimits) {
              fetch(
                `/api/devices/count?userId=${this.props.logInUser.id}`
              ).then(response => {
                if (response.ok) {
                  response.json().then(res => {
                    if(res.status ==='success'){ 
                      let unitsLimits = res.data
                      this.props.dispatch(parentUnitsLimit(unitsLimits))
                    }
                    else if(res?.statusCode === '440'){
                      window.location.replace('/login')
                    }
                    else if(res.statusCode){
                      var err = res?.message.split(':')
                      err[1] =err[1].replace(')', "")
                      this.props.dispatch(
                        Notifications.error({
                          message: this.props.translate(err[1]),
                          autoDismiss: 5,
                          location: this.props.location
                        })
                      )
                    }
                  })
                }else{
                  throw response
                }
              }).catch(e => {})
            }
            if (!this.props.parentUserLimits) {
              //users limits
              fetch(`/api/users/count?userId=${this.props.logInUser.id}`).then(
                response => {
                  if (response.ok) {
                    response.json().then(res => {
                      if(res.status ==='success'){  
                      let usersLimits =res.data
                      this.props.dispatch(parentUsersLimit(usersLimits))
                      }
                      else if(res?.statusCode === '440'){
                        window.location.replace('/login')
                      }
                      else if(res.statusCode){
                        var err = res?.message.split(':')
                        err[1] =err[1].replace(')', "")
                        this.props.dispatch(
                          Notifications.error({
                            message: this.props.translate(err[1]),
                            autoDismiss: 5,
                            location: this.props.location
                          })
                        )
                      }
                    })
                  }
                  else{
                    throw response
                  }
                })  .catch(e => {
                  this.props.dispatch(Notifications.error({
                    message: 'somethingWentWrong',
                    autoDismiss: 5
                  }))
                })
            }
          }
        }
      }
    }
  }
  componentWillUnmount () {
    this.setState({
      isMount: false
    })
  }
  render () {
    return null
  }
}

const mapStateToProps = state => ({
  roles: state.roles,
  devices: state.devices,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  group: state.groups,
  users: state.users,
  accounts: state.accounts,
  parentUserLimits: state.parentUserLimits,
  parentUnitLimits: state.parentUnitLimits
})

export default connect(mapStateToProps)(CommonSection)
