import { prepareUnits } from '../../Helpers'
export const devices = (state = [], action) => {
  switch (action.type) {
    case 'GET_DEVICES':
      let NewData = prepareUnits(action.devices.data)
      action.devices.data = NewData
      return action.devices
    case 'ADD_DEVICE':
      let myNewDevice = state
      myNewDevice.data.push(action.devices)
      return myNewDevice
    case 'REMOVE_DEVICE':
      let newdevices = state
      let nv = newdevices.data.filter(dec => dec.id !== action.device)
      newdevices.data = nv
      return newdevices

    case 'UPDATE_DEVICE':
      let oldState = state
      oldState.data = state.data.filter(gr => gr.id !== action.device.id)
      oldState.data.push(action.device)
      return oldState

    case 'TOGGLE_ALL_DEVICES':
      var dev = state.map(obj => {
        return {
          ...obj,
          visible: action.payload.checked
        }
      })

      return dev

    case 'TOGGLE_GROUP_DEVICES':
      var devices = state.map(obj => {
        if (obj.groupId === action.payload.id) {
          return {
            ...obj,
            visible: action.payload.checked
          }
        } else {
          return { ...obj, visible: obj.visible || false }
        }
      })

      return devices

    case 'SORT_DEVICES':
      var devices1 = []
      if (action.payload.sort === 'DESC') {
        devices1 = state.slice().sort(function (b, a) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      } else {
        devices1 = state.slice().sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      }

      return devices1
    case 'LOGOUT_USER':
      return ''

    default:
      return state
  }
}

export const searchRecently = (state = '', action) => {
  switch (action.type) {
    case 'SEARCH_DEVICES':
      return action.payload.device
    default:
      return state
  }
}

export const deviceId = (state = 0, action) => {
  switch (action.type) {
    case 'ADD_DEVICE_ID':
      return action.payload.id
    default:
      return state
  }
}
export const parentUnitLimits = (state = '', action) => {
  switch (action.type) {
    case 'DEVICE_LIMIT':
      return action?.limit || null
    case 'LOGOUT_USER':
      return ''
    default:
      return state
  }
}

export const trackId = (state = 0, action) => {
  switch (action.type) {
    case 'SET_TRACK_ID':
    case 'RESET_BOUNDS':
      return action.payload || 0

    default:
      return state
  }
}

//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////

export const positions = (state = [], action) => {
  switch (action.type) {
    case 'ADD_DEVICES':
      var positions = state.map(pos => {
        var p = pos
        action.devices.map(d => {
          if (d.id === pos.deviceId) {
            p['name'] = d.name
          }
          return ''
        })

        return p
      })

      return positions

    case 'ADD_POSITIONS':
      if (state.length === 0 && action.positions.length > 1) {
        return state.concat(action.positions)
      } else {
        return state
      }

    case 'UPDATE_POSITIONS':
      if (state.length === 0 && action.positions.length > 1) {
        return state.concat(action.positions)
      } else {
        var p = []
        if (action.positions.length > 1) {
          action.positions.map(pos => {
            state.map(position => {
              if (position.deviceId === pos.deviceId) {
                p.push(pos)
              }
              return ''
            })
            return ''
          })
        } else {
          var pos = action.positions[0]
          state.map(position => {
            if (position.deviceId === pos.deviceId) {
              p.push(pos)
            } else {
              p.push(position)
            }
            return ''
          })
        }
      }
      return p

    default:
      return state
  }
}
