import React, { Component } from 'react'
export default class NotFoundPage extends Component {
  render () {
    return (
      <>
        <h1>Root Not Found</h1>
      </>
    )
  }
}
