export const groups = (state = [], action) => {
  switch (action.type) {
    case 'UPDATE_GROUPS':
      return action.groups

    case 'ADD_GROUP':
      let prvState = state
      prvState.data.push(action.group)
      return prvState

    case 'UPDATE_GROUP':
      let oldState = state
      oldState.data = state.data.filter(gr => gr.id !== action.group.id)
      oldState.data.push(action.group)
      return oldState

    case 'REMOVE_GROUP':
      let groups = state.data.filter(gr => gr.id !== action.group.id)
      
      return {
       data: groups 
      }
    case 'LOGOUT_USER':
      return []

    default:
      return state
  }
}
